import { styled, Box } from '@mui/material';

import { Cell } from '../styled';

export const UserCell = styled(Cell)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '16px 24px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  }),
);

export const UserAvatar = styled('img')(({ theme }) =>
  theme.unstable_sx({
    aspectRatio: '0.9',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '36px',
    height: '40px',
    flexShrink: 0,
    padding: '1px',
    backgroundColor: '#d4d4d4',
    scale: '1.1',
    clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)', // Hexagon shape
    '& img': {
      width: '100%',
      height: '100%',
      clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)', // Hexagon shape
    },
  }),
);

export const UserAvatarWithText = styled(Box)<any>(({ theme }) =>
  theme.unstable_sx({
    width: '36px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: 'black',
    clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)', // Hexagon shape
    textTransform: 'uppercase',
  }),
);

export const UserName = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontWeight: 400,
    flex: 1,
  }),
);

export const DropdownMenu = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    height: '272px',
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: ' 0 2px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    overflowY: 'auto',
  }),
);

export const DropdownItem = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    padding: '8px 16px',
    fontSize: '18px',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  }),
);
