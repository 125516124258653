import { createAsyncThunk } from '@reduxjs/toolkit';
import { EUser } from 'store/constants';

import instance from 'store/middlewares/api';

interface IFetchUsersList {
  count: number;
  page: number;
  search: string;
}

const fetchUsersList = createAsyncThunk<IFetchUsersList, any>(
  EUser.fetchUsersList,
  async ({ filter, payload }) => {
    const { data } = await instance.get(`/admin/users?limit=100`, {
      params: { ...filter, ...payload },
    });

    return data;
  },
);

export default fetchUsersList;
