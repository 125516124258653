import { styled, Box } from '@mui/material';

const DropListButtonCheckboxMain = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'relative',
  }),
);

const DropListButton = styled('button')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    height: '36px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    border: 'none',
    backgroundColor: '#fff',
    color: '#1f1f1f',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    cursor: 'pointer',
  }),
);

export { DropListButtonCheckboxMain, DropListButton };
