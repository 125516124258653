import React, { FunctionComponent } from 'react';
import { Skeleton } from '@mui/material';

import { Cell, Row } from '../styled';

interface TableLoadingProps {
  rows?: number;
  columns?: number;
  firstColFlex?: number;
}

export const TableLoading: FunctionComponent<TableLoadingProps> = ({
  rows = 3,
  columns = 4,
  firstColFlex = 1,
}) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <Row key={rowIndex}>
          {Array.from({ length: columns }).map((_, colIndex) => (
            <Cell key={colIndex} sx={colIndex === 0 ? { flex: firstColFlex } : undefined}>
              <Skeleton variant="rectangular" width={'100%'} height={30} />
            </Cell>
          ))}
        </Row>
      ))}
    </>
  );
};
