import React, { FunctionComponent } from 'react';

import { SegmentToggle } from 'components/SegmentToggle';
import { DropListButtonCheckbox } from 'components/DropListButtonCheckbox';

import { ClearButton, DealsListFilterChild, DealsListFilterParent } from './styled';

export enum EDealsListFilterType {
  SEGMENT = 'segment',
  SELECT = 'select',
}

type FilterItem = {
  label: string;
  type: EDealsListFilterType;
  items: {
    label: string;
    value: string;
    checked: boolean;
    onClick: () => void;
  }[];
};
interface DealsListFilterProps {
  filterItems: FilterItem[];
}

const DealsListFilter: FunctionComponent<DealsListFilterProps> = ({ filterItems }) => {
  const showClearFilter = filterItems.some(filterItem =>
    filterItem.items.some(item => !item.checked),
  );

  const onClearFilter = () => {
    filterItems.forEach(filterItem => {
      filterItem.items.forEach(item => {
        if (!item.checked) {
          item.onClick();
        }
      });
    });
  };

  return (
    <DealsListFilterParent>
      <DealsListFilterChild>
        {filterItems.map((filterItem, index) => {
          switch (filterItem.type) {
            case EDealsListFilterType.SEGMENT:
              return (
                <SegmentToggle
                  key={index}
                  segments={filterItem.items.map(item => item.label)}
                  onSegmentChange={(label: string) =>
                    filterItem.items.find(i => i.label === label)?.onClick()
                  }
                />
              );
            case EDealsListFilterType.SELECT:
              return (
                <DropListButtonCheckbox
                  key={index}
                  buttonLabel={filterItem.label}
                  dropListItems={filterItem.items.map(item => ({
                    label: item.label,
                    checked: item.checked,
                    onClick: item.onClick,
                  }))}
                />
              );
            default:
              return null;
          }
        })}
        {showClearFilter && <ClearButton onClick={onClearFilter}>Clear Filter</ClearButton>}
      </DealsListFilterChild>
    </DealsListFilterParent>
  );
};

export default DealsListFilter;
