import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const NotesIcon: FunctionComponent<ISvgProps> = ({ className }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 15V13.3333H12.5V15H2.5ZM2.5 10.8333V9.16667H17.5V10.8333H2.5ZM2.5 6.66667V5H17.5V6.66667H2.5Z"
        fill="#F0B500"
      />
    </svg>
  );
};
