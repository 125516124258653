import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { HexagonStep } from './HexagonStep';
import { alpha } from '@mui/material';

interface IStyledStep extends BoxProps {
  currentStep?: boolean;
}

interface IStyledInactive extends BoxProps {
  inactive: boolean;
}

const StyledRoot = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: {
      xs: 'row',
      md: 'column',
    },
    height: 64,
  }),
);

const StyledWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'relative',
    fontSize: 0,
    // zIndex: 2,
    '&:after': {
      content: '""',
      position: 'absolute',
      border: {
        sm: 'none',
        md: '1px solid #fff',
      },
      height: 30,
      top: '100%',
      left: '49%',
    },
    '&:last-child': {
      content: '""',
      position: 'absolute',
      border: '1px solid red',
      height: 30,
      top: '100%',
      left: '49%',
    },
  }),
);

const StyledHexagon = styled(HexagonStep)(({ theme }) =>
  theme.unstable_sx({
    height: {
      xs: 37,
      md: 52,
    },
    position: 'relative',
    width: {
      xs: 32,
      md: 45,
    },
    // zIndex: 1,
  }),
);

const StyledItem = styled('div')<IStyledStep>(({ currentStep, theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    mb: {
      xs: 0,
      md: '29px',
    },
    flexGrow: (currentStep && 1) || 0,
    '& :after': {
      border: {
        xs: 'none',
        md: '1px solid #fff',
      },
    },
    '&:last-child :after': {
      border: 'none',
    },
  }),
);

const StyledHexagonNumber = styled('div')<IStyledInactive>(({ inactive, theme }) =>
  theme.unstable_sx({
    alignItems: 'center',
    bottom: 0,
    color: (inactive && alpha(theme.palette.common.white, 0.6)) || theme.palette.text.primary,
    display: 'flex',
    fontSize: {
      xs: '1.125rem',
      md: '1.625rem',
    },
    fontWeight: 400,
    justifyContent: 'center',
    left: 0,
    lineHeight: '24px',
    position: 'absolute',
    right: 0,
    top: 1,
    // zIndex: 2,
  }),
);

const StyledStepName = styled('div')<IStyledStep & IStyledInactive>(
  ({ currentStep, inactive, theme }) =>
    theme.unstable_sx({
      color: (inactive && alpha(theme.palette.common.white, 0.6)) || theme.palette.common.white,
      mr: 1.5,
      display: {
        xs: (!currentStep && 'none') || 'block',
        md: 'block',
      },
    }),
);

export { StyledItem, StyledHexagon, StyledHexagonNumber, StyledRoot, StyledStepName, StyledWrap };
