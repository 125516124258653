import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EOrder } from '../../constants';
import { EDraftOrder } from 'store/constants/draft-order';

interface IfetchDealDetailsByIdProps {
  id: number;
}

const fetchDealDetailsById = createAsyncThunk<any, IfetchDealDetailsByIdProps>(
  EDraftOrder.fetchDealDetailsById,
  async ({ id }) => {
    const { data } = await instance.get(`draft-orders/${id}`);

    return data;
  },
);

export default fetchDealDetailsById;
