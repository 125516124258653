import React, { FunctionComponent, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { AgencyLocationIcon } from 'styles/icons/AgencyLocationIcon';

import { rateFormat } from 'utils/textFormatter';
import { sortingBy } from 'utils/transformers';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import useCalculatePrice from 'hooks/useCalculatePrice';

import { IWorker } from 'store/types/consultant';
import { IModule } from 'store/types/categoryProducts';

import { expertiseLevelIdSelector, productPricesSelector } from 'store/selectors/getCommonSelector';

import CameraIcon from 'styles/icons/CameraIcon';
import StarFilledIcon3 from 'styles/icons/StarFilledIcon3';

import {
  StyledAvatarWrap,
  StyledCardActionArea,
  StyledClamp,
  StyledCompanyIconsTags,
  StyledCompanyLogo,
  StyledMatchingValue,
  StyledMoreTags,
  StyledOGIcon,
  StyledPaper,
  StyledSocialWrap,
  StyledStarChip,
  StyledTagsWrap,
  StyledVerifiedIcon,
  StyledAgencyContainer,
  StyledAgencyName,
  StyledCostPerWeek,
} from './styled';

import useVisibleElements from 'hooks/useVisibleElements';

import Avatar from 'components/Avatar';
import CustomTooltip from 'components/CustomTooltip';
import fetchProductPrices from 'store/query/common/fetchProductPrices';
import Tooltip from '@mui/material/Tooltip';
import useConsultantProductExperience from 'hooks/useConsultantProductExperience';
import CommunitySearchMatchInfo from 'views/TalentSearch/CommunitySearchConsultantInfo';
import useRemoteConfig, { FeatureFlagName } from 'hooks/useRemoteConfig';

interface IConsultantPreview {
  isBlur?: boolean;
  isSearchView?: boolean;
  isSelectedExpert?: boolean;
  workerMatching?: number;
  onOpenPreview?: () => void;
  onSelectPreview?: (worker: any) => void;
  order: any;
  hashCode?: string;
  isPaid?: boolean;
  primaryModule?: number | null;
  modules?: IModule[];
  worker: IWorker;
  isCommunitySearch?: boolean;
  onAnonymousMode?: boolean;
}

const ConsultantPreview: FunctionComponent<IConsultantPreview> = ({
  isBlur,
  isSearchView,
  isSelectedExpert,
  onOpenPreview,
  onSelectPreview,
  order,
  workerMatching,
  worker,
  isCommunitySearch,
  onAnonymousMode,
}) => {
  const dispatch = useAppDispatch();
  const { weeklyPrice } = useCalculatePrice(order);

  const seniority = useAppSelector(expertiseLevelIdSelector(order?.levelId || -1));
  const { consultant, ...user } = worker;

  const { hiddenChildElements, parentContainerRef, visibleChildElements } = useVisibleElements();

  const productPrices = useAppSelector(productPricesSelector(order?.expertise?.product?.id));
  const SHOW_SPECIALIST_RATING = useRemoteConfig(FeatureFlagName.SHOW_SPECIALIST_RATING);

  useEffect(() => {
    if (!productPrices && order?.expertise?.product?.id) {
      dispatch(fetchProductPrices(order?.expertise?.product?.id));
    }
  }, [dispatch, order?.expertise?.product?.id, productPrices]);

  const { headLine, description } = useConsultantProductExperience(consultant, isSearchView);

  return (
    <StyledPaper sx={{ mb: 2, borderWidth: { xs: 0, md: 1 } }}>
      <StyledCardActionArea onClick={onOpenPreview}>
        <StyledAvatarWrap>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              size="medium"
              image={consultant?.avatarUrl}
              firstName={user?.firstName || user?.initials || ''}
              lastName={user?.lastName}
              isBlur={!isCommunitySearch ? isBlur : onAnonymousMode ? isBlur : undefined}
            />
            <Typography
              variant={!isCommunitySearch ? 'h6' : 'body1'}
              sx={{ my: 1, textAlign: 'center', fontWeight: 500 }}
              display={{ xs: 'none', md: 'block' }}
            >
              {onAnonymousMode
                ? user?.initials
                : (user?.firstName && [user?.firstName, user?.lastName].join(' ')) ||
                  user?.initials}
            </Typography>
            <StyledSocialWrap mt={{ xs: 1.5, md: 0 }}>
              {consultant?.isApproved && <StyledVerifiedIcon />}
              <StyledOGIcon />
            </StyledSocialWrap>
          </Box>
          {consultant?.rate && SHOW_SPECIALIST_RATING && (
            <StyledStarChip
              icon={<StarFilledIcon3 sx={{ fontSize: '1rem' }} />}
              label={`${rateFormat(consultant?.rate?.totalRate)}/5`}
              variant="filled"
            />
          )}
        </StyledAvatarWrap>
        <Divider flexItem sx={{ m: 0 }} orientation="vertical" />
        <StyledCompanyIconsTags>
          <Box height={{ md: 98 }}>
            <Grid container>
              <Grid item xs={12} lg={consultant?.agency ? 8 : 12}>
                <Box
                  display={{
                    md: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography gutterBottom variant="h6" sx={{ my: 1 }}>
                    {!isCommunitySearch
                      ? user?.initials
                      : onAnonymousMode
                        ? user?.initials
                        : (user?.firstName && [user?.firstName, user?.lastName].join(' ')) ||
                          user?.initials}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                  variant="subtitle2"
                  gutterBottom
                >
                  {headLine}
                </Typography>
                <StyledClamp sx={{ mb: 2 }} variant="body2">
                  {description}
                </StyledClamp>
              </Grid>
              {consultant?.agency && (
                <Grid item xs={12} lg={4}>
                  <StyledAgencyContainer
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="100%"
                  >
                    <AgencyLocationIcon />
                    <StyledAgencyName variant="body2" ml={1}>
                      {consultant?.agency.name}
                    </StyledAgencyName>
                  </StyledAgencyContainer>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box position="relative">
            <StyledCompanyLogo>
              {consultant?.companiesLogos?.map(({ logo }, index) => (
                <Box key={`logo-${index}`} mr={1} mb={1}>
                  <Avatar
                    size="xs-small"
                    icon={<CameraIcon />}
                    image={logo || ''}
                    firstName={''}
                    round
                  />
                </Box>
              ))}
            </StyledCompanyLogo>
            {!!consultant?.tags?.length && (
              <StyledTagsWrap
                ref={parentContainerRef}
                sx={{ opacity: 0, position: 'absolute', width: '100%' }}
              >
                {sortingBy(consultant?.tags, 'UserTags.showIndex').map((item: any) => (
                  <Chip
                    sx={{ mr: 0.5, mb: 0.5 }}
                    key={item.id}
                    label={item.name}
                    className="child-element"
                    variant="outlined"
                  />
                ))}
              </StyledTagsWrap>
            )}
            {!!consultant?.tags?.length && (
              <StyledTagsWrap>
                {sortingBy(consultant?.tags, 'UserTags.showIndex')
                  .splice(0, visibleChildElements.length)
                  .map((item: any) => (
                    <Chip
                      sx={{ mr: 0.5, mb: 0.5 }}
                      key={item.id}
                      label={item.name}
                      variant="outlined"
                    />
                  ))}
                {!!hiddenChildElements.length && (
                  <StyledMoreTags variant="body2">
                    {`+ ${hiddenChildElements.length} More`}
                  </StyledMoreTags>
                )}
              </StyledTagsWrap>
            )}
          </Box>
        </StyledCompanyIconsTags>
      </StyledCardActionArea>
      <Divider flexItem sx={{ mx: 2.5, my: 0, display: { md: 'none' } }} orientation="horizontal" />
      <Divider
        flexItem
        sx={{ mx: 0, my: 2.5, display: { xs: 'none', md: 'block' } }}
        orientation="vertical"
      />
      {!isCommunitySearch && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            px={2.5}
            py={{ xs: 1.5, md: 2.5 }}
            sx={{ textAlign: 'center', width: { xs: '100%', md: 200 } }}
          >
            <Box
              display="flex"
              flexDirection={{ md: 'column' }}
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%' }}
            >
              {!!workerMatching && (
                <>
                  <Box
                    sx={{
                      textAlign: 'center',
                      display: { xs: 'none', md: 'flex' },
                      flexDirection: { xs: 'row' },
                    }}
                  >
                    <StyledMatchingValue>{workerMatching}%</StyledMatchingValue>
                    <CustomTooltip
                      placement="bottom"
                      title="The matching score ranks the specialists' seniority, availability and expertise against the order needs"
                    />
                  </Box>
                  <Box
                    sx={{
                      textAlign: 'center',
                      display: { xs: 'flex', md: 'none' },
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Box mr={1} sx={{ fontSize: 22 }}>
                      {workerMatching}%
                    </Box>
                    <Box
                      sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      match
                      <CustomTooltip
                        placement="bottom"
                        title="The matching score ranks the specialists' seniority, availability and expertise against the order needs"
                      />
                    </Box>
                  </Box>
                </>
              )}
              <Box width={{ md: 130 }}>
                <StyledCostPerWeek>
                  <b>{seniority?.name} </b>
                  <Box sx={{ display: { xs: 'none', sm: 'inline' } }}>at </Box>
                  <b>
                    <Tooltip title="USD">
                      <span>$</span>
                    </Tooltip>
                    {weeklyPrice.toLocaleString('en-US')}/wk
                  </b>
                </StyledCostPerWeek>
              </Box>
              {isSearchView && (
                <Button
                  data-test="select-button-consultant-card-md"
                  color={(!isSelectedExpert && 'secondary') || 'primary'}
                  onClick={() => onSelectPreview && onSelectPreview({ ...user, consultant })}
                  sx={{ display: { xs: 'none', md: 'inline-block' }, mt: 2 }}
                  variant="contained"
                >
                  {isSelectedExpert ? 'Selected' : 'Select'}
                </Button>
              )}
            </Box>
          </Box>
          {isSearchView && (
            <Button
              data-test="select-button-consultant-card-xs"
              color={(!isSelectedExpert && 'primary') || 'secondary'}
              fullWidth
              onClick={() => onSelectPreview && onSelectPreview({ ...user, consultant })}
              sx={{ display: { md: 'none' } }}
              variant="contained"
            >
              {isSelectedExpert ? 'Selected' : 'Select'}
            </Button>
          )}
        </>
      )}
      {isCommunitySearch && (
        <div onClick={onOpenPreview}>
          <CommunitySearchMatchInfo
            consultant={consultant}
            worker={worker}
            onAnonymousMode={onAnonymousMode}
          />
          {/* <Button
              data-test="select-button-consultant-card-xs"
              color={'primary'}
              fullWidth
              onClick={() => onSelectPreview && onSelectPreview({ ...user, consultant })}
              sx={{ display: { md: 'none' } }}
              variant="contained"
            >
              {isSelectedExpert ? 'Selected' : 'Select'}
            </Button> */}
        </div>
      )}
    </StyledPaper>
  );
};

export default ConsultantPreview;
