import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { matchSorter } from 'match-sorter';

import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// import ProductAutocomplete from 'components/CustomFields/CustomAutocomplete/ProductAutocomplete';
import { StyledMasonry } from 'components/CustomFields/CustomAutocomplete/styled';
import { IProduct } from 'store/types/categoryProducts';
import { onTypeaheadItemChange } from 'utils/orderCreationHelpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  categoryProductsWithoutDeprecatedSelector,
  modulesByProductSelector,
  productPricesSelector,
  workTypesByProductSelector,
} from 'store/selectors/getCommonSelector';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';

import useSafeAreaView from 'components/SafeAreaView';
import { EProductFilters } from 'models/consts';
import RequestProductCustomerModal from 'components/Modal/RequestProductModal/RequestCustomer';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import fetchProductPrices from 'store/query/common/fetchProductPrices';
import { TAXES } from 'store/constants';
import { getDraftOrderDetailsSelector } from 'store/selectors/getDraftOrdersSelector';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';
import ClearIcon from '@mui/icons-material/Clear';
import ProductAutocomplete from 'components/CustomFields/CustomAutocomplete/ProductAutocomplete';

const ExpertiseSection = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));

  const { safeAreaPadding } = useSafeAreaView();

  const [isRequestModalVisible, setRequestModalVisible] = useState(false);
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isProductReqSuccess, setIsProductReqSuccess] = useState<boolean>(false);
  const [joyrideZIndex, setJoyrideZIndex] = useState(10000);

  const {
    control,
    setValue,
    resetField,
    watch,
    reset,
    formState: { errors },
  } = useFormContext();

  const { product, customerRate } = watch();

  const defaultProductRef = useRef(null);

  const categoriesWithProductsWithoutDeprecatedList = useAppSelector(
    categoryProductsWithoutDeprecatedSelector,
  );

  const modulesByProductList = useAppSelector(modulesByProductSelector(product?.id));
  const worksByProductList = useAppSelector(workTypesByProductSelector(product?.id));
  const productPrices = useAppSelector(productPricesSelector(product?.id));
  const { items, loading, loaded } = useAppSelector(getDraftOrderDetailsSelector);

  useEffect(() => {
    if (items && items.expertise) {
      setValue('product', items?.expertise?.product);
    } else {
      setValue('product', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!categoriesWithProductsWithoutDeprecatedList?.length) {
      dispatch(fetchProductsWithModulesWorks());
    }
  }, [categoriesWithProductsWithoutDeprecatedList?.length, dispatch]);

  useEffect(() => {
    if (product) {
      dispatch(fetchProductPrices(product?.id));
    }
  }, [product, dispatch]);

  const updateRates = (priceData: any) => {
    const specialistPrice = priceData?.payoutCents / 100;
    if (!items?.specialistRate) {
      setValue('specialistRate', specialistPrice);
      setValue('isSpecialistCustomRateSelected', false);
    }
    if (!items?.metadata?.customRate) {
      setValue('customerRate', priceData.stripePriceCents);
    }
  };

  useEffect(() => {
    if (!product || !productPrices) return;

    const defaultPriceData = productPrices.find(
      price => price.levelId === (items?.levels?.id ?? 2),
    );

    if (!defaultPriceData) return;

    if (product === defaultProductRef.current && items?.expertise === null) {
      updateRates(defaultPriceData);
      defaultProductRef.current = product;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPrices]);

  useEffect(() => {
    if (!product || !productPrices) return;

    const defaultPriceData = productPrices.find(
      price => price.levelId === (items?.levels?.id ?? 2),
    );

    if (!defaultPriceData) return;

    if (isInitialLoad) {
      defaultProductRef.current = product;
      updateRates(defaultPriceData);
      setIsInitialLoad(false);
    } else if (product !== defaultProductRef.current) {
      updateRates(defaultPriceData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, productPrices, isInitialLoad, items, setValue]);

  const productFilters = (options: any, param: String, productType?: EProductFilters) => {
    const inputLower = (param || '').toLowerCase();

    const optionList =
      productType === EProductFilters.ALL
        ? options
        : options.filter((item: any) =>
            productType === EProductFilters.ROLE ? item?.isGeneralRoles : !item?.isGeneralRoles,
          );

    if (inputLower) {
      const filteredOptions = matchSorter(optionList as IProduct[], inputLower, {
        keys: ['name', 'categoryName', 'company.name', 'aliases'],
      });
      return (filteredOptions || []) as any[];
    }
    return optionList;
  };

  const handleComponentOpen = () => {
    if (mobile) {
      setJoyrideZIndex(0);
    }
  };

  const handleComponentClose = () => {
    if (mobile) {
      setJoyrideZIndex(10000);
    }
  };

  return (
    <Box paddingLeft={'24px'} paddingRight={'24px'} paddingBottom={'24px'}>
      <Box>
        <Typography fontSize={'18px'} fontWeight={500}>
          Product or General Role
        </Typography>
        <Controller
          control={control}
          name="product"
          render={({ field }) => (
            <ProductAutocomplete
              autocompleteProps={{
                groupBy: option => option.categoryName,
                ListboxComponent: forwardRef((props: any, ref: any) => (
                  <StyledMasonry ref={ref} columns={1} component="ul" spacing={2} {...props} />
                )),
                multiple: undefined,
              }}
              clerable
              inputProps={{
                ...(tablet && { readOnly: true }),
              }}
              customFilter={(options, inputValue, type) =>
                productFilters(options, inputValue, type)
              }
              field={field}
              handleChange={(event, fieldName) => {
                if (!event) {
                  setValue('product', undefined);
                  !!product.id &&
                    reset({
                      modules: [],
                      product: undefined,
                      works: [],
                    });
                } else {
                  onTypeaheadItemChange(event, fieldName, setValue);
                  resetField('modules', { defaultValue: [] });
                  resetField('works', { defaultValue: [] });
                }
              }}
              options={categoriesWithProductsWithoutDeprecatedList || []}
              placeholder="Select a Product or General Role"
              mobilesx={{
                marginTop: safeAreaPadding.top,
              }}
              onRequestProduct={() => {
                setRequestModalVisible(true);
              }}
              successProductRequest={isProductReqSuccess}
              setIsProductReqSuccess={setIsProductReqSuccess}
            />
          )}
        />
      </Box>
      {product?.id && (
        <Box
          display={'flex'}
          flexDirection={mobile ? 'column' : 'row'}
          justifyContent={'space-between'}
          mt={'24px'}
        >
          <Box width={mobile ? 'auto' : '402.5px'}>
            <Typography fontSize={'18px'} fontWeight={500}>
              Type of Work
            </Typography>
            <Controller
              control={control}
              name="works"
              render={({ field }) => (
                <CustomAutocomplete
                  autocompleteProps={{
                    multiple: true,
                  }}
                  inputProps={{
                    readOnly: true,
                    error: !!errors.works,
                    helperText: (errors.works as any)?.message || 'Incorrect data',
                    sx: {
                      '& .MuiInputBase-inputAdornedStart': {
                        minWidth: '0px !important',
                        padding: 0,
                      },
                    },
                  }}
                  field={field}
                  isMainTag
                  options={worksByProductList}
                  handleChange={(event, fieldName) =>
                    onTypeaheadItemChange(event, fieldName, setValue)
                  }
                  placeholder="Select Types of Work"
                  // primaryItem={primaryWorkType}
                  onPrimaryItemChange={id => setValue('primaryWorkType', id)}
                  mobilesx={{
                    marginTop: 0,
                  }}
                  sx={{
                    '& .MuiAutocomplete-root': {
                      border: `1px solid ${theme.palette.grey[600]} !important`,
                    },
                  }}
                />
              )}
              rules={{ required: (product?.isBasic && 'This field is required') || undefined }}
            />
          </Box>
          <Box width={mobile ? 'auto' : '402.5px'}>
            <Typography fontSize={'18px'} fontWeight={500}>
              Modules
            </Typography>
            <Controller
              control={control}
              name="modules"
              render={({ field }) => (
                <CustomAutocomplete
                  autocompleteProps={{
                    multiple: true,
                  }}
                  inputProps={{
                    readOnly: true,
                    error: !!errors.modules,
                    helperText: (errors.modules as any)?.message || 'Incorrect data',
                    sx: {
                      '& .MuiInputBase-inputAdornedStart': {
                        minWidth: '0px !important',
                        padding: 0,
                      },
                    },
                  }}
                  field={field}
                  handleChange={(event, fieldName) =>
                    onTypeaheadItemChange(event, fieldName, setValue)
                  }
                  isMainTag
                  options={modulesByProductList}
                  placeholder={`Select ${(product?.isBasic && 'Modules') || 'Skills'}`}
                  // primaryItem={primaryModule}
                  onPrimaryItemChange={id => setValue('primaryModule', id)}
                  mobilesx={{
                    marginTop: 0,
                  }}
                />
              )}
            />
          </Box>
        </Box>
      )}

      <RequestProductCustomerModal
        visible={isRequestModalVisible}
        close={() => setRequestModalVisible(false)}
        afterSubmit={() => {
          setConfirmationModalVisible(true);
          setIsProductReqSuccess(true);
          ToastService.success(
            ToastMessage({
              title: 'Request submitted successfully!',
              body: 'Thanks for reaching out. Our support team has received your request.',
            }),
          );
        }}
      />
      <ConfirmationModal
        visible={isConfirmationModalVisible}
        close={() => setConfirmationModalVisible(false)}
        title="Your product request has been sent to our team."
        subtitle="A copy of your request has been sent to your email."
        primaryButtonText="Request Another Product"
        onPrimaryButtonClick={() => {
          setConfirmationModalVisible(false);
          setRequestModalVisible(true);
        }}
      />
    </Box>
  );
};

export default ExpertiseSection;
