import { Components } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';

const MuiDialogActions: Components['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme }: any) =>
      theme.unstable_sx({
        px: {
          xs: 2,
          md: 11,
        },
        pb: 5,
        '&>:not(:first-of-type) ': {
          marginLeft: 3,
        },
        [`& .${buttonClasses.root}:only-child`]: {
          maxWidth: 265,
          mx: 'auto',
        },
      }),
  },
};

export default MuiDialogActions;
