import React, { FC, forwardRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { IExtendCategoryWithProducts, IProduct } from 'store/types/categoryProducts';
import { IConsultantProduct } from 'store/types/consultant';

import { StyledInputLabel, StyledFormGroup } from 'components/CustomFields/styled';

import { StyledBoxImpersonate } from 'components/Header/styled';
import RequestProductModal from 'components/Modal/RequestProductModal';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { StyledMasonry } from '../../../CustomFields/CustomAutocomplete/styled';
import ProductAutocomplete from 'components/CustomFields/CustomAutocomplete/ProductAutocomplete';
import { EProductFilters } from 'models/consts';
import { matchSorter } from 'match-sorter';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';

interface IProductSelect {
  control: Control;
  flatList: IExtendCategoryWithProducts[];
  handleChange: (product: IProduct) => void;
  name: string;
  selectedOptions: IConsultantProduct[];
  showAddProduct?: boolean;
  bypassIsValid?: boolean;
  setBypassIsValid?: (value: boolean) => void;
  setJoyrideZIndex?: React.Dispatch<React.SetStateAction<number>>;
  hideLabel?: boolean;
  disabled?: boolean;
  mobilesx?: object;
}

const ProductSelect: FC<IProductSelect> = ({
  control,
  flatList,
  handleChange,
  name,
  selectedOptions,
  showAddProduct,
  bypassIsValid,
  setBypassIsValid,
  setJoyrideZIndex,
  hideLabel,
  disabled,
  mobilesx,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isProductReqSuccess, setIsProductReqSuccess] = useState<boolean>(false);

  const handleComponentOpen = () => {
    if (mobile) {
      setJoyrideZIndex?.(0);
    }
  };

  const handleComponentClose = () => {
    if (mobile) {
      setJoyrideZIndex?.(10000);
    }
  };

  const [isRequestModalVisible, setRequestModalVisible] = useState(false);
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const productFilters = (options: any, param: String, productType?: EProductFilters) => {
    const inputLower = (param || '').toLowerCase();

    const optionList =
      productType === EProductFilters.ALL
        ? options
        : options.filter((item: any) =>
            productType === EProductFilters.ROLE ? item?.isGeneralRoles : !item?.isGeneralRoles,
          );

    if (inputLower) {
      const filteredOptions = matchSorter(optionList as IProduct[], inputLower, {
        keys: ['name', 'categoryName', 'company.name', 'aliases'],
      });
      return (filteredOptions || []) as any[];
    }
    return optionList;
  };

  return (
    <>
      <StyledFormGroup container spacing={1}>
        {!hideLabel && (
          <Grid xs={12} md={3}>
            <StyledInputLabel>Product</StyledInputLabel>
          </Grid>
        )}
        <Grid xs={12} md={!hideLabel ? 9 : 12}>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <ProductAutocomplete
                disabled={disabled}
                mobilesx={mobilesx}
                autocompleteProps={{
                  groupBy: option => option.categoryName,
                  ListboxComponent: forwardRef((props: any, ref: any) => (
                    <StyledMasonry
                      columns={(mobile && 1) || 2}
                      component="ul"
                      spacing={2}
                      {...props}
                    />
                  )),
                  multiple: undefined,
                  getOptionDisabled: (option: IProduct) =>
                    selectedOptions.some(item => item.productId === option.id) || !option.isActive,
                }}
                customFilter={(options, inputValue, type) =>
                  productFilters(options, inputValue, type)
                }
                field={field}
                handleChange={handleChange}
                onOpen={handleComponentOpen}
                onClose={handleComponentClose}
                options={flatList || []}
                placeholder="Select a Product"
                onRequestProduct={() => {
                  setRequestModalVisible(true);
                }}
                successProductRequest={isProductReqSuccess}
                setIsProductReqSuccess={setIsProductReqSuccess}
                CustomBannerView={
                  showAddProduct
                    ? (props: any) => (
                        <StyledBoxImpersonate sx={{ justifyContent: 'flex-start' }}>
                          <Typography variant="body1">
                            <Link
                              onMouseDown={() => {
                                props.handleRequestProduct();
                              }}
                              sx={{
                                color: 'text.primary',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: 500,
                                pl: 2,
                              }}
                            >
                              Can’t find your product? Request it here.
                            </Link>
                          </Typography>
                        </StyledBoxImpersonate>
                      )
                    : undefined
                }
              />
            )}
            rules={{ required: 'This field is required' }}
          />
        </Grid>

        {bypassIsValid && (
          <Box alignContent={'center'} sx={{ width: '100%', mt: { xs: 4.5, md: 14.5 } }}>
            <Typography fontSize="18px" fontWeight="700" align="center">
              Thank you for requesting a new product!
            </Typography>

            <Typography
              fontSize={mobile ? '16px' : '18px'}
              fontWeight="400"
              align="center"
              sx={{ pt: 2 }}
            >
              A copy of your request has been sent to your email.
              <br />
              Our team will review and connect with you shortly.
            </Typography>

            <Typography
              fontSize={mobile ? '16px' : '18px'}
              fontWeight="400"
              align="center"
              sx={{ pt: 2 }}
            >
              You may skip to the next step or continue adding more products.
            </Typography>
          </Box>
        )}
      </StyledFormGroup>

      <RequestProductModal
        visible={isRequestModalVisible}
        close={() => setRequestModalVisible(false)}
        afterSubmit={() => {
          setConfirmationModalVisible(true);
          setBypassIsValid && setBypassIsValid(true);
          setIsProductReqSuccess(true);
          ToastService.success(
            ToastMessage({
              title: 'Request submitted successfully!',
              body: 'Thanks for reaching out. Our support team has received your request.',
            }),
          );
        }}
      />

      <ConfirmationModal
        visible={isConfirmationModalVisible}
        close={() => setConfirmationModalVisible(false)}
        title="Your product request has been sent to our team."
        subtitle="A copy of your request has been sent to your email."
        primaryButtonText="Request Another Product"
        onPrimaryButtonClick={() => {
          setConfirmationModalVisible(false);
          setRequestModalVisible(true);
        }}
      />
    </>
  );
};

export default ProductSelect;
