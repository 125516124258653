import React, { useState, useRef } from 'react';
import { Capacitor } from '@capacitor/core';

import { Box, Button, Typography, Snackbar, Alert, Chip, Divider, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { DirectorySearchResult } from 'store/types/directorySearch';
import CameraIcon from 'styles/icons/CameraIcon';
import { sortingBy } from 'utils/transformers';
import {
  StyledCompanyIconsTags,
  StyledCompanyLogo,
  StyledTagsWrap,
  StyledMoreTags,
  StyledClamp,
} from '../styled';
import Avatar from 'components/Avatar';
import useVisibleElements from 'hooks/useVisibleElements';
import HighlightedView from 'components/DirectorySearch/HighlightedView';
import useConsultantProductExperienceForDirectorySearch from '../DirectorySearchHook/useConsultantProductExperienceForDirectorySearch';

interface IDirectorySearchItem {
  directorySearchResult: DirectorySearchResult;
  onAnonymousMode?: boolean;
}

const DirectorySearchDescriptionView: React.FC<IDirectorySearchItem> = ({
  directorySearchResult,
  onAnonymousMode,
}) => {
  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isMobileNative = Capacitor.isNativePlatform();

  const tagsArray =
    directorySearchResult?.tags?.split(' ').map((tag: string, index: number) => ({
      id: index,
      name: tag.trim(),
    })) || [];

  const { hiddenChildElements, parentContainerRef, visibleChildElements } = useVisibleElements();

  const { headLine, description } = useConsultantProductExperienceForDirectorySearch(
    directorySearchResult,
    true,
  );

  return (
    <StyledCompanyIconsTags>
      <Box height={{ md: 98 }}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Box
              display={{
                md: 'none',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography gutterBottom variant="h6" sx={{ my: 1 }}>
                {onAnonymousMode ? (
                  directorySearchResult?.initials
                ) : directorySearchResult?.firstName || directorySearchResult?.lastName ? (
                  <>
                    {directorySearchResult?.highlights?.includes('firstName') ? (
                      <HighlightedView text={directorySearchResult?.firstName || ''} />
                    ) : (
                      directorySearchResult?.firstName
                    )}{' '}
                    {directorySearchResult?.highlights?.includes('lastName') ? (
                      <HighlightedView text={directorySearchResult?.lastName || ''} />
                    ) : (
                      directorySearchResult?.lastName
                    )}
                  </>
                ) : (
                  directorySearchResult?.initials
                )}
              </Typography>
            </Box>
            <Typography
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
              variant="subtitle2"
              gutterBottom
            >
              {directorySearchResult?.highlights?.includes('description') ? (
                <HighlightedView text={headLine || ''} />
              ) : (
                headLine
              )}
            </Typography>
            <StyledClamp sx={{ mb: 2 }} variant="body2">
              {directorySearchResult?.highlights?.includes('description') ? (
                <HighlightedView text={description || ''} />
              ) : (
                description
              )}
            </StyledClamp>
          </Grid>
        </Grid>
      </Box>
      <Box position="relative">
        <StyledCompanyLogo>
          {directorySearchResult.companiesLogos?.split(' ').map((logo, index) => (
            <Box key={`logo-${index}`} mr={1} mb={1}>
              <Avatar
                size="xs-small"
                icon={<CameraIcon />}
                image={logo || ''}
                firstName={''}
                round
              />
            </Box>
          ))}
        </StyledCompanyLogo>

        {!!tagsArray.length && (
          <StyledTagsWrap
            ref={parentContainerRef}
            sx={{ opacity: 0, position: 'absolute', width: '100%' }}
          >
            {sortingBy(tagsArray, 'UserTags.showIndex').map((item: any) => (
              <Chip
                sx={{ mr: 0.5, mb: 0.5 }}
                key={item.id}
                label={
                  directorySearchResult?.highlights?.includes('tags') ? (
                    <HighlightedView text={item.name} />
                  ) : (
                    item.name
                  )
                }
                className="child-element"
                variant="outlined"
              />
            ))}
          </StyledTagsWrap>
        )}

        {!!tagsArray.length && (
          <StyledTagsWrap>
            {sortingBy(tagsArray, 'UserTags.showIndex')
              .slice(0, visibleChildElements.length)
              .map((item: any) => (
                <Chip
                  sx={{ mr: 0.5, mb: 0.5 }}
                  key={item.id}
                  label={
                    directorySearchResult?.highlights?.includes('tags') ? (
                      <HighlightedView text={item.name} />
                    ) : (
                      item.name
                    )
                  }
                  variant="outlined"
                />
              ))}
            {!!hiddenChildElements.length && (
              <StyledMoreTags variant="body2">
                {`+ ${hiddenChildElements.length} More`}
              </StyledMoreTags>
            )}
          </StyledTagsWrap>
        )}
      </Box>
    </StyledCompanyIconsTags>
  );
};

export default DirectorySearchDescriptionView;
