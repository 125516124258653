import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Autocomplete, Box, InputAdornment, Typography } from '@mui/material';

import { BootstrapInput, Select } from 'components/CustomFields';
import DateTextInput from 'components/CustomFields/DateTextInput';
import CustomTooltip from 'components/CustomTooltip';
import CuratedOrderToggleSwitch from '../CuratedOrderToggleSwitch';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DEALS_SCHEDULE_TYPES, ERole, ERoleId, TALENT_STAGES } from 'models/consts';
import getConsultantList from 'store/query/user/getConsultantList';
import fetchUsersList from 'store/query/user/fetchUsersList';

import { isFriday, isMonday } from 'utils/dateFormatter';
import { handlerTimeLineChange } from 'utils/orderCreationHelpers';
import CalendarIcon from 'styles/icons/CalendarIcon';
import checkCompanyByName from 'store/query/company/checkCompanyByName';
import Button from '@mui/material/Button';
import { getMeSelector } from 'store/selectors/getUserSelector';

interface isCuratedOrderDetailsProps {
  disableAllFields?: boolean;
  isOrderEdit: any;
}

const CuratedOrderDetails: FunctionComponent<isCuratedOrderDetailsProps> = ({
  disableAllFields,
  isOrderEdit,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

  const navigate = useNavigate();

  const user = useAppSelector(getMeSelector);
  const [salesManagerData, setSalesManagerData] = useState<any[]>([]);
  const [specialistData, setSpecialistData] = useState<any[]>([]);

  const {
    control,
    clearErrors,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();

  const {
    salesManager,
    talentUser,
    customer,
    specialist,
    orderName,
    description,
    customerRate,
    isCustomerRateDefault,
    specialistRate,
    isSpecialistRateDefault,
    totalHours,
    searchEndDate,
    searchStartDate,
    customerType,
  } = watch();

  const [isSpecialistDefaultRate, setIsSpecialistDefaultRate] = useState<
    'default' | 'custom' | null
  >(isSpecialistRateDefault ? 'default' : 'custom');
  const [isCustomerDefaultRate, setIsCustomerDefaultRate] = useState<'default' | 'custom' | null>(
    isCustomerRateDefault ? 'default' : 'custom',
  );

  useEffect(() => {
    setIsCustomerDefaultRate(isCustomerRateDefault ? 'default' : 'custom');
    setIsSpecialistDefaultRate(isSpecialistRateDefault ? 'default' : 'custom');
  }, [isCustomerRateDefault, isSpecialistRateDefault]);

  useEffect(() => {
    dispatch(getConsultantList({ withAgency: 'false', isActive: true })).then((data: any) => {
      setSpecialistData(data?.payload?.consultants || []);
    });
    dispatch(fetchUsersList({ limit: 100 })).then((data: any) => {
      setSalesManagerData(data?.payload?.users || []);
    });
  }, [dispatch]);

  const debounced = debounce((value: any, searchField: string) => {
    if (searchField === 'talentUser') {
      setSalesManagerData([]);
      dispatch(
        fetchUsersList({
          offset: 0,
          type: 'asc',
          search: value,
        }),
      ).then((data: any) => {
        let filter = data?.payload?.users.filter((item: any) => item.role.name === ERole.TALENT);
        setSalesManagerData(filter || []);
      });
    }
    if (searchField === 'specialist') {
      dispatch(getConsultantList({ withAgency: 'false', isActive: true, search: value })).then(
        (data: any) => {
          setSpecialistData(data?.payload?.consultants || []);
        },
      );
    }
  }, 500);

  const searchTerm = (value: string, searchField: string) => {
    debounced(value, searchField);
  };

  const setDate = (value: Date | null, fieldName: any, field: any) => {
    field?.onChange(value ? dayjs(value || null).format('YYYY-MM-DD') : undefined);
  };

  const onSwitchToggle = (value: any, type: 'customer' | 'specialist') => {
    if (type === 'customer') {
      setIsCustomerDefaultRate(value);
      setValue('isCustomerRateDefault', value === 'default' ? true : false);
    } else {
      setIsSpecialistDefaultRate(value);
      setValue('isSpecialistRateDefault', value === 'default' ? true : false);
    }
  };

  return (
    <Grid sx={{ ml: 1, mt: 1, mb: 2, display: 'flex', flexDirection: 'column' }}>
      <Grid
        sx={{ mt: 1, mb: 2, display: 'flex', flexDirection: isiPadOrMobile ? 'column' : 'row' }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            width: isiPadOrMobile ? '100%' : '60%',
            mr: 5,
          }}
        >
          <Grid
            xs={12}
            md={12}
            lg={6}
            display={'flex'}
            // alignItems={'center'}
            // justifyContent={'space-between'}
            flexDirection={isiPadOrMobile ? 'column' : 'row'}
            width="100%"
          >
            <Grid display={'flex'} alignItems={'center'} flexDirection={'row'} width={'40%'}>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  fontSize: !isiPadOrMobile ? '14px' : '12px',
                  fontWeight: 400,
                  color: '#5D5D5E',
                }}
                mr={4}
              >
                Temperature:
              </Typography>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  fontSize: !isiPadOrMobile ? '14px' : '12px',
                  fontWeight: 400,
                }}
                ml={1}
              >
                {isOrderEdit.metadata.temperature}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={6}
            display={'flex'}
            flexDirection={isiPadOrMobile ? 'column' : 'row'}
          >
            <Typography
              sx={{
                width: 142,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                color: '#5D5D5E',
              }}
            >
              Talent stage
            </Typography>
            <Controller
              control={control}
              name="talentPhase"
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label="Select the deal phase"
                  options={TALENT_STAGES}
                />
              )}
            />
          </Grid>

          <Grid
            xs={12}
            md={12}
            lg={6}
            display={'flex'}
            flexDirection={isiPadOrMobile ? 'column' : 'row'}
          >
            <InputLabel
              sx={{
                width: 140,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                color: '#5D5D5E',
              }}
            >
              Order Name{' '}
              <Typography component="span" color="red">
                *
              </Typography>
            </InputLabel>
            <Controller
              control={control}
              name="orderName"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="text"
                  placeholder="Type your order name here..."
                  disabled={disableAllFields}
                  sx={{
                    width: isiPadOrMobile ? '100%' : '100%',
                    marginLeft: isiPadOrMobile ? undefined : 0.7,
                  }}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    field.onChange(value);
                    setValue('orderName', value);
                  }}
                  autoComplete="off"
                />
              )}
              rules={{
                required: 'This field is required',
              }}
            />
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={6}
            display={'flex'}
            flexDirection={isiPadOrMobile ? 'column' : 'row'}
          >
            <InputLabel
              sx={{
                width: 140,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                color: '#5D5D5E',
              }}
            >
              Description
            </InputLabel>
            <Controller
              control={control}
              name="description"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="text"
                  multiline={true}
                  rows={isiPadOrMobile ? 1.5 : 3}
                  disabled={disableAllFields}
                  placeholder="Type your order description here..."
                  sx={{
                    width: '100%',
                    marginLeft: 0.5,
                  }}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    field.onChange(value);
                    setValue('description', value);
                  }}
                  autoComplete="off"
                />
              )}
              rules={{
                required: 'This field is required',
              }}
            />
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={6}
            display={'flex'}
            flexDirection={isiPadOrMobile ? 'column' : 'row'}
          >
            <InputLabel
              sx={{
                width: 140,
                marginTop: isiPadOrMobile ? 0 : 1,
                marginRight: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                color: '#5D5D5E',
              }}
            >
              Schedule Type
            </InputLabel>
            <Controller
              control={control}
              name="scheduleType"
              render={({ field: { ref, ...field } }) => (
                <Box
                  sx={{
                    width: isiPadOrMobile ? '100%' : '100%',
                  }}
                >
                  <Select
                    {...field}
                    inputRef={ref}
                    label="Select the deal phase"
                    disabled={disableAllFields}
                    options={DEALS_SCHEDULE_TYPES}
                    onChange={event => {
                      if (event.target.value) {
                        setValue('scheduleType', event.target.value);
                      }
                    }}
                  />
                </Box>
              )}
            />
          </Grid>

          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                marginRight: 2,
                color: '#5D5D5E',
              }}
            >
              Availability Type{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.availabilityType}</Typography>
          </Grid>

          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                marginRight: 2,
                color: '#5D5D5E',
              }}
            >
              Duration{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.duration}</Typography>
          </Grid>

          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                marginRight: 2,
                color: '#5D5D5E',
              }}
            >
              Duration Type{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.durationType}</Typography>
          </Grid>

          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                marginRight: 2,
                color: '#5D5D5E',
              }}
            >
              Reason for Work{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.reasonForWork}</Typography>
          </Grid>

          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                marginRight: 2,
                color: '#5D5D5E',
              }}
            >
              Timezone{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.timezone}</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                marginRight: 2,
                color: '#5D5D5E',
              }}
            >
              Reason Specialist is Required{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.reasonSpecialistIsRequired}</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                marginRight: 2,
                color: '#5D5D5E',
              }}
            >
              Industry required{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.industryRequired}</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                marginRight: 2,
                color: '#5D5D5E',
              }}
            >
              Industry{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.industry}</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                marginRight: 2,
                color: '#5D5D5E',
              }}
            >
              Interview Required{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.interviewRequired ? 'Yes' : 'No'}</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={6} display={'flex'} alignItems={'center'}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                whiteSpace: 'wrap',
                color: '#5D5D5E',
                marginRight: 2,
              }}
            >
              Background Check Required{' '}
            </InputLabel>
            <Typography>{isOrderEdit?.metadata?.backgroundCheckRequired ? 'Yes' : 'No'}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{
            mt: 1,
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: isiPadOrMobile ? '100%' : '50%',
          }}
        >
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontSize: isiPadOrMobile ? '25px' : '30px',
              fontWeight: 500,
            }}
          >
            Contact Info
          </Typography>

          <Grid xs={12} md={12} lg={6}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              Company Name
            </InputLabel>
            <Typography fontSize={'14px'}>{isOrderEdit?.metadata?.companyName}</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={6}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Contact Name
            </InputLabel>
            <Typography fontSize={'14px'}>{isOrderEdit?.metadata?.customerName}</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={6}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Contact Phone
            </InputLabel>
            <Typography fontSize={'14px'}>{isOrderEdit?.metadata?.customerPhone}</Typography>
          </Grid>
          <Grid xs={12} md={12} lg={6}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Contact Email
            </InputLabel>
            <Typography fontSize={'14px'}>{isOrderEdit?.metadata?.customerEmail}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} display={'flex'} flexDirection={'column'}>
        <Grid
          xs={12}
          md={6}
          lg={4}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Start date
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: isiPadOrMobile ? 'auto' : '31%',
              marginRight: 1,
            }}
          >
            <Controller
              name="searchStartDate"
              control={control}
              rules={{
                validate: {
                  beforeEnd: date => {
                    if (searchEndDate) {
                      const startDate = new Date(date);
                      const endDate = new Date(`${searchEndDate}T00:00:00`);
                      if (startDate > endDate) {
                        return 'Start Date cannot be in future than End Date';
                      }
                    }
                    return undefined;
                  },
                },
              }}
              render={({ field }) => (
                <DatePicker
                  selected={!!searchStartDate ? dayjs(searchStartDate).toDate() : null}
                  disabled={disableAllFields}
                  onChange={(date: Date) => {
                    if (date) {
                      handlerTimeLineChange(
                        totalHours,
                        40,
                        dayjs(date).format('YYYY-MM-DD'),
                        setValue,
                        clearErrors,
                      );
                    }
                    setDate(date, 'startDate', field);
                  }}
                  calendarStartDay={1}
                  customInput={
                    <DateTextInput
                      className="text-custom-input"
                      onReset={() => setDate(null, 'startDate', field)}
                      icon={<CalendarIcon />}
                      data-test="startDateField"
                    />
                  }
                  isClearable
                  showPopperArrow={false}
                  filterDate={isMonday}
                  minDate={dayjs().add(1, 'days').toDate()}
                  placeholderText="MM/DD/YYYY"
                  dayClassName={date => (isMonday(date) && 'available-start-date') || null}
                  popperPlacement="bottom-start"
                />
              )}
            />
            {!!errors.searchStartDate && (
              <Typography color="error">
                {(errors.searchStartDate as any)?.message || 'Incorrect data'}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={4}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            End date
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isiPadOrMobile ? 'column' : 'row',
              alignItems: 'center',
              width: isiPadOrMobile ? 'auto' : '31%',
              marginRight: 1,
            }}
          >
            <Controller
              name="searchEndDate"
              control={control}
              rules={{
                validate: {
                  afterStart: date =>
                    !date ||
                    !searchStartDate ||
                    dayjs(searchStartDate).isSameOrBefore(date) ||
                    'Incorrect date range',
                },
              }}
              render={({ field }) => (
                <DatePicker
                  selected={!!searchEndDate ? dayjs(searchEndDate).toDate() : null}
                  disabled={disableAllFields}
                  onChange={(date: Date) => {
                    setDate(date, 'searchStartDate', field);
                    if (searchStartDate && dayjs(searchStartDate).isSameOrBefore(date)) {
                      clearErrors('searchStartDate');
                    }
                  }}
                  calendarStartDay={1}
                  customInput={
                    <DateTextInput
                      className="text-custom-input"
                      onReset={() => setDate(null, 'searchStartDate', field)}
                      icon={<CalendarIcon />}
                      data-test="endDateField"
                    />
                  }
                  showPopperArrow={false}
                  minDate={searchStartDate ? dayjs(searchStartDate).toDate() : dayjs().toDate()}
                  filterDate={isFriday}
                  isClearable
                  placeholderText="MM/DD/YYYY"
                  dayClassName={date => (isFriday(date) && 'available-end-date') || null}
                  popperPlacement="bottom-start"
                />
              )}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Customer Rate
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isiPadOrMobile ? 'column' : 'row',
              alignItems: 'center',
              width: isiPadOrMobile ? '97%' : '31%',
              marginRight: 1,
            }}
          >
            <Controller
              control={control}
              name="customerRate"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="number"
                  disabled={isCustomerDefaultRate === 'default' || disableAllFields}
                  placeholder="Enter Customer Rate"
                  helperText={errors.customerRate?.message || 'Incorrect data'}
                  error={!!errors.customerRate}
                  startAdornment={
                    <InputAdornment position="start">
                      <Typography
                        ml={2}
                        color="secondary"
                        sx={{
                          display: 'flex',
                          marginRight: '-20px',
                          marginTop: 0.2,
                          fontSize: '14px',
                          color: isCustomerDefaultRate === 'default' ? '#757575' : '#000',
                        }}
                      >
                        {customerRate ? '$' : undefined}
                      </Typography>
                    </InputAdornment>
                  }
                  sx={{
                    width: '100%',
                    backgroundColor: '#f5f5f5',
                  }}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    field.onChange(parseInt(value));
                    setValue('customerRate', parseInt(value));
                  }}
                  autoComplete="off"
                />
              )}
              rules={{
                validate: {
                  greaterThanZero: value => {
                    if (Number(value) <= 0) {
                      return 'Rate must be greater than 0';
                    }
                  },
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={isiPadOrMobile ? 1 : undefined}
          >
            <CuratedOrderToggleSwitch
              selectedToggleValue={value => onSwitchToggle(value, 'customer')}
              defaultValue={isCustomerRateDefault ? 'default' : 'custom'}
              disabled={disableAllFields}
            />
            <CustomTooltip
              placement="top"
              title="Default Rate is determined by Product and Seniority"
              sx={{ width: '10px', height: '10px', ml: 1 }}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Specialist Rate
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isiPadOrMobile ? 'column' : 'row',
              alignItems: 'center',
              width: isiPadOrMobile ? '97%' : '31%',
              marginRight: 1,
            }}
          >
            <Controller
              control={control}
              name="specialistRate"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="number"
                  // disabled={isSpecialistDefaultRate === 'default' || user.role.name === ERole.SALES}
                  placeholder="Enter Specialist Rate"
                  helperText={errors.specialistRate?.message || 'Incorrect data'}
                  error={!!errors.specialistRate}
                  startAdornment={
                    <InputAdornment position="start">
                      <Typography
                        ml={2}
                        color={'secondary'}
                        sx={{
                          display: 'flex',
                          marginRight: '-20px',
                          marginTop: 0.2,
                          color: isSpecialistDefaultRate === 'default' ? '#757575' : '#000',
                        }}
                      >
                        {specialistRate ? '$' : undefined}
                      </Typography>
                    </InputAdornment>
                  }
                  onChange={(event: any) => {
                    const value = event.target.value;
                    field.onChange(parseInt(value));
                    setValue('specialistRate', parseInt(value));
                  }}
                  autoComplete="off"
                />
              )}
              rules={{
                validate: {
                  greaterThanZero: value => {
                    if (Number(value) <= 0) {
                      return 'Rate must be greater than 0';
                    }
                  },
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={isiPadOrMobile ? 1 : undefined}
          >
            <CuratedOrderToggleSwitch
              selectedToggleValue={value => onSwitchToggle(value, 'specialist')}
              defaultValue={isSpecialistRateDefault ? 'default' : 'custom'}
              // disabled={disableAllFields}
            />
            <CustomTooltip
              placement="top"
              title="Default Rate is determined by Product and Seniority"
              sx={{ width: '10px', height: '10px', ml: 1 }}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={theme => ({
              width: isiPadOrMobile ? '100%' : 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
              whiteSpace: isiPadOrMobile ? 'nowrap' : 'normal',
              wordBreak: isiPadOrMobile ? 'normal' : 'break-word',
              overflow: isiPadOrMobile ? 'hidden' : 'visible',
              textOverflow: isiPadOrMobile ? 'ellipsis' : 'unset',
              lineHeight: 1.4,
              [theme.breakpoints.up('lg')]: {
                maxWidth: '100%',
              },
            })}
          >
            Specialist Net Payout Terms
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: isiPadOrMobile ? 'auto' : '13%',
            }}
          >
            <Controller
              control={control}
              name="payoutTerms"
              rules={{
                validate: value => value >= 0 || 'Value must be 0 or greater',
              }}
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="number"
                  sx={{ width: '100%' }}
                  onChange={(event: any) => {
                    const value = Math.max(0, parseInt(event.target.value));
                    field.onChange(value);
                    setValue('payoutTerms', value);
                  }}
                  autoComplete="off"
                />
              )}
            />
            <Box display="flex" flexDirection="row" alignItems="center" ml={1}>
              <Typography>days</Typography>
              <CustomTooltip
                placement="top"
                title="Days from weekly billing approval"
                sx={{ width: '10px', height: '10px', ml: 1 }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          display={'flex'}
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Specialist
          </InputLabel>
          <Controller
            control={control}
            name="specialist"
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                {...field}
                disabled={user.role.name === ERole.SALES}
                onInputChange={(_, value) => {
                  searchTerm(value, 'specialist');
                }}
                onChange={(_, newValue) => {
                  setValue('specialist', newValue);
                  field.onChange(newValue);
                }}
                options={specialistData}
                getOptionLabel={option => {
                  return option
                    ? `${option.firstName || ''} ${option.lastName || option.email}`
                    : '';
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => (
                  <BootstrapInput {...params} placeholder="Select an option" />
                )}
                sx={{ width: isiPadOrMobile ? '97%' : '31%' }}
              />
            )}
          />
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          display={'flex'}
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Sales Manager
          </InputLabel>
          <Controller
            control={control}
            name="salesManager"
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                {...field}
                disabled={disableAllFields}
                onChange={(_, newValue) => {
                  setValue('salesManager', newValue);
                  field.onChange(newValue);
                }}
                options={salesManager}
                getOptionLabel={option => {
                  return option && option.firstName
                    ? `${option.firstName} ${option.lastName}`
                    : option?.email
                      ? option.email
                      : '';
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => (
                  <BootstrapInput {...params} placeholder="Select an option" />
                )}
                sx={{ width: isiPadOrMobile ? '97%' : '31%' }}
              />
            )}
          />
        </Grid>
        {/* <Grid
          xs={12}
          md={12}
          lg={6}
          display={'flex'}
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Talent User
          </InputLabel>
          <Controller
            control={control}
            name="talentUser"
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                {...field}
                disabled={user.role.name === ERole.SALES}
                onInputChange={(_, value) => {
                  searchTerm(value, 'usersList');
                }}
                onChange={(_, newValue) => {
                  setValue('talentUser', newValue);
                  field.onChange(newValue);
                }}
                options={talentUser}
                getOptionLabel={option => {
                  return option && option.firstName
                    ? `${option.firstName} ${option.lastName}`
                    : option?.email
                      ? option.email
                      : '';
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => (
                  <BootstrapInput {...params} placeholder="Select an option" />
                )}
                sx={{ width: isiPadOrMobile ? '97%' : '31%' }}
              />
            )}
          />
        </Grid> */}

        <Grid
          xs={12}
          md={12}
          lg={6}
          display={'flex'}
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Talent User
          </InputLabel>
          <Controller
            control={control}
            name="talentUser"
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                {...field}
                disabled={user.role.name === ERole.SALES}
                onInputChange={(_, value) => {
                  searchTerm(value, 'talentUser');
                }}
                onChange={(_, newValue) => {
                  setValue('talentUser', newValue);
                  field.onChange(newValue);
                }}
                options={salesManagerData}
                getOptionLabel={option => {
                  return option
                    ? `${option.firstName || ''} ${option.lastName || option.email}`
                    : '';
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => (
                  <BootstrapInput {...params} placeholder="Select an option" />
                )}
                sx={{ width: isiPadOrMobile ? '97%' : '31%' }}
              />
            )}
          />
        </Grid>
        {!!isOrderEdit && isOrderEdit.chat?.id && (
          <Grid mt={3} xs={12}>
            <Button
              onClick={() => navigate(`/chat/${isOrderEdit.chat?.id}`)}
              variant="contained"
              color="secondary"
            >
              Order Messages
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CuratedOrderDetails;
