import { createSelector } from 'reselect';
import { DefaultRootState } from 'react-redux';

const getDraftOrderState = (state: DefaultRootState) => state.draftOrders;
const getDealDetailsState = (state: DefaultRootState) => state.dealDeatils;

export const getDraftOrdersSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState,
);

export const getStaffingDraftOrdersSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.tabs.staffing,
);

export const getRecommendedCustomerDraftOrdersSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.tabs.recommended_customer,
);

export const getOrderCreatedDraftOrdersSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.tabs.order_created,
);

export const getActiveDraftOrdersSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.tabs.active,
);

export const getCompletedDraftOrdersSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.tabs.completed,
);

export const getOnHoldDraftOrdersSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.tabs.on_hold,
);

export const getDraftOrderCountStatusSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.support.countStatus,
);

export const getDealOwnersSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.support.dealOwners,
);

export const getDealCustomersSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.support.dealCustomers,
);

export const getDealOwnersStatusSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.support.dealOwnersStatus,
);

export const getDealCustomersStatusSelector = createSelector(
  [getDraftOrderState],
  draftOrdersState => draftOrdersState.support.dealCustomersStatus,
);

export const getDraftOrderDetailsSelector = createSelector(
  [getDealDetailsState],
  dealDetailsState => dealDetailsState.dealDetails,
);
