import React, { useState, FunctionComponent } from 'react';

import { SegmentControl, ActiveSegment, Segment } from './styled';

interface SegmentToggleProps {
  segments: string[];
  defaultSelected?: string;
  onSegmentChange?: (selectedSegment: string) => void;
  segmentStyles?: any;
  textStyles?: any;
}

export const SegmentToggle: FunctionComponent<SegmentToggleProps> = ({
  segments,
  defaultSelected,
  onSegmentChange,
  segmentStyles,
  textStyles,
}) => {
  const [selectedSegment, setSelectedSegment] = useState<string>(defaultSelected || segments[0]);

  const handleSegmentClick = (segment: string) => {
    if (segment === selectedSegment) {
      return;
    }
    setSelectedSegment(segment);

    if (onSegmentChange) {
      onSegmentChange(segment);
    }
  };

  return (
    <SegmentControl sx={segmentStyles}>
      {segments.map(segment =>
        segment === selectedSegment ? (
          <ActiveSegment key={segment} onClick={() => handleSegmentClick(segment)} sx={textStyles}>
            {segment}
          </ActiveSegment>
        ) : (
          <Segment key={segment} onClick={() => handleSegmentClick(segment)} sx={textStyles}>
            {segment}
          </Segment>
        ),
      )}
    </SegmentControl>
  );
};
