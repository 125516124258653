import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const IssueBlock: FunctionComponent = () => (
  <Box width={{ xs: '100%', sm: '45%', lg: '100%' }}>
    <Typography component="div" variant="h4">
      Have an issue?
    </Typography>
    <Divider />
    <>
      Contact us at{' '}
      <a
        href="mailto:support@myworkerbee.com"
        style={{ color: 'black', textDecoration: 'underline' }}
      >
        support@myworkerbee.com
      </a>
    </>
  </Box>
);

export default IssueBlock;
