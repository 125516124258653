import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getDealCustomersStatusSelector,
  getDealOwnersStatusSelector,
  getDraftOrderCountStatusSelector,
  getDraftOrdersSelector,
} from 'store/selectors/getDraftOrdersSelector';
import draftOrdersCount from 'store/query/draft-order/count';
import { getDealCustomers, getDealOwners } from 'store/query/draft-order/supportUsers';

import { EDealsFILTER } from 'models/consts';

import { tabsDeals } from 'components/Order/OrderList/tabOrder';
import DealsListStaffingTab from '../DealsListStaffingTab';
import DealsListRecommendedTab from '../DealsListRecommendedTab';
import DealsListOrderCreatedTab from '../DealsListOrderCreatedTab';
import DealsListActiveTab from '../DealsListActiveTab';
import DealsListCompletedTab from '../DealsListCompletedTab';
import DealsListOnHoldTab from '../DealsListOnHoldTab';

import { DealsListTab, DealsListTabContent } from './styled';

const DealsListTabs: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState<EDealsFILTER>(EDealsFILTER.STAFFING);

  const draftOrderList = useAppSelector(getDraftOrdersSelector);
  const countStatus = useAppSelector(getDraftOrderCountStatusSelector);
  const dealOwnersStatus = useAppSelector(getDealOwnersStatusSelector);
  const dealCustomersStatus = useAppSelector(getDealCustomersStatusSelector);

  const handleChangeFilter = (event: React.SyntheticEvent, newFilter: EDealsFILTER) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  useEffect(() => {
    if (countStatus === 'init') {
      dispatch(draftOrdersCount());
    }
  }, [countStatus, dispatch]);

  useEffect(() => {
    if (dealOwnersStatus === 'init') {
      dispatch(getDealOwners());
    }
  }, [dealOwnersStatus, dispatch]);

  useEffect(() => {
    if (dealCustomersStatus === 'init') {
      dispatch(getDealCustomers());
    }
  }, [dealCustomersStatus, dispatch]);

  return (
    <>
      <DealsListTab id="deals-list-tabs">
        <Tabs
          id="deals-tabs"
          value={filter}
          variant="scrollable"
          onChange={handleChangeFilter}
          aria-label="deals-tabs"
          textColor="secondary"
          indicatorColor="primary"
        >
          {tabsDeals.map(tab => {
            const tabCount = draftOrderList.tabs[tab.value]?.count || 0;

            return (
              <Tab
                id={`tab-${tab.value}`}
                key={tab.value}
                value={tab.value}
                label={tab.label + (tabCount ? `\u00A0\u00A0${tabCount}` : '')}
                disableRipple
              />
            );
          })}
        </Tabs>
      </DealsListTab>

      <DealsListTabContent id="deals-list-tab-content">
        {filter === EDealsFILTER.STAFFING && <DealsListStaffingTab />}
        {filter === EDealsFILTER.RECOMMENDED_CUSTOMER && <DealsListRecommendedTab />}
        {filter === EDealsFILTER.ORDER_CREATED && <DealsListOrderCreatedTab />}
        {filter === EDealsFILTER.ACTIVE && <DealsListActiveTab />}
        {filter === EDealsFILTER.COMPLETED && <DealsListCompletedTab />}
        {filter === EDealsFILTER.ON_HOLD && <DealsListOnHoldTab />}
      </DealsListTabContent>
    </>
  );
};

export default DealsListTabs;
