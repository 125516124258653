import { Box, styled } from '@mui/material';

const DealsListFilterParent = styled('header')(({ theme }) =>
  theme.unstable_sx({
    borderBottom: '1px solid rgba(231, 231, 231, 1)',
    display: 'flex',
    width: '100%',
    padding: '12px 24px',
    alignItems: 'center',
    gap: '16px',
    fontWeight: '500',
    textAlign: 'center',
    justifyContent: 'start',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  }),
);

const DealsListFilterChild = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    alignSelf: 'stretch',
    display: 'flex',
    minWidth: '240px',
    marginTop: 'auto',
    marginBottom: 'auto',
    alignItems: 'center',
    gap: '24px',
    justifyContent: 'start',
    flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
    },
  }),
);

const ClearButton = styled('button')(({ theme }) =>
  theme.unstable_sx({
    padding: '9px 16px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: '#1f1f1f',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
);

export { DealsListFilterParent, DealsListFilterChild, ClearButton };
