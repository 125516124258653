import React, { FunctionComponent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

import { Box, Button, Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import { SearchActivityIcon } from 'styles/icons/SearchActivityIcon';
import { SeniorityLevelIcon } from 'styles/icons/SeniorityLevelIcon';
import { NotesIcon } from 'styles/icons/NotesIcon';

import { useAppSelector } from 'store/hooks';
import { getDraftOrderDetailsSelector } from 'store/selectors/getDraftOrdersSelector';

const DealDeatilsSection: FunctionComponent = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { items, loading, loaded } = useAppSelector(getDraftOrderDetailsSelector);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { customerRate } = watch();

  return (
    <>
      {items && (
        <Box paddingLeft={'24px'} paddingRight={'24px'}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mb={'16px'}>
            <SearchActivityIcon />
            <Typography fontSize={'18px'} fontWeight={500} ml={'8px'}>
              Timeline
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Schedule Type
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.metadata?.scheduleType || '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Duration
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.metadata?.duration
                  ? `${items?.metadata?.duration} ${items?.metadata?.durationType}`
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Start Date
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {dayjs.utc(items?.timeLine?.startDate).format('MM/DD/YYYY') || '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Availability Type
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.metadata?.availabilityType || '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                End Date
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {dayjs.utc(items?.timeLine?.endDate).format('MM/DD/YYYY') || '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Timezone
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.metadata?.timezone || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mb={'16px'}>
            <SeniorityLevelIcon />
            <Typography fontSize={'18px'} fontWeight={500} ml={'8px'}>
              Seniority
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Seniority Level
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.levels?.name || '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Customer Rate
              </Typography>
              <Controller
                control={control}
                name="customerRate"
                render={({ field: { value } }) => {
                  return (
                    <Typography fontSize="16px" fontWeight={400} color="#1F1F1F">
                      {value ? `$${value / 100}/hour` : '-'}
                    </Typography>
                  );
                }}
              />
            </Grid>
          </Grid>

          <Divider />

          <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <NotesIcon />
              <Typography fontSize={'18px'} fontWeight={500} ml={'8px'}>
                Product Description
              </Typography>
            </Box>
            <Box mt={'16px'}>
              <Typography
                fontSize={'16px'}
                fontWeight={400}
                color={'#1F1F1F'}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: isExpanded ? 'none' : 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {items?.additionalRequirements}
              </Typography>
              <Button
                size="small"
                onClick={toggleExpand}
                sx={{
                  textTransform: 'none',
                  padding: 0,
                  marginTop: 1,
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#1F1F1F',
                  textDecoration: 'underline',
                }}
              >
                {isExpanded ? 'Show less' : 'Show more'}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DealDeatilsSection;
