import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import ReactJoyride, { CallBackProps, EVENTS, ACTIONS, STATUS, Step } from 'react-joyride';
import deepmerge from 'deepmerge';
import { useStateMachine } from 'little-state-machine';
import DatePicker from 'react-datepicker';
import dayjs from 'utils/dayjs';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { isFriday, isMonday } from 'utils/dateFormatter';

import { IBasicUser } from 'store/types/user';
import { IConsultantRegister } from 'store/types/inner-models';
import { IBasicConsultant } from 'store/types/consultant';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import updateAction from 'store/actions/updateAction';

import addAvailability from 'store/query/consultant/addAvailability';
import addOutDates from 'store/query/consultant/addOutDates';
import updateAvailability from 'store/query/consultant/updateAvailability';
import updateOutDates from 'store/query/consultant/updateOutDates';
import fetchAvailability from 'store/query/common/fetchAvailability';
import fetchTimePreferences from 'store/query/common/fetchTimePreferences';

import { getMeSelector } from 'store/selectors/getUserSelector';
import { availabilitiesSelector, timePreferencesSelector } from 'store/selectors/getCommonSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';

import CalendarIcon from 'styles/icons/CalendarIcon';

import Loader from 'components/Loader/Loader';
import DateTextInput from 'components/CustomFields/DateTextInput';
import { BootstrapInput, Select } from 'components/CustomFields';
import ROUTES from 'router/constants';
import { StyledSubtitle } from 'components/Modal/Modal/styled';
import { Autocomplete } from '@mui/material';
import { getBrowserTimezoneAbbreviation, timezoneList } from 'utils/timezoneList';
import { extendedHoursList } from 'models/consts';
import { LightBulbIcon } from 'styles/icons/LightBulbIcon';

const Availability: FunctionComponent<IConsultantRegister> = ({
  isModal,
  showIntro,
  onSubmitted,
}) => {
  const customButtonStyles = {
    buttonNext: {
      fontFamily: 'Visuelt Pro, Arial',
      color: '#000',
    },
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { actions } = useStateMachine({ updateAction });

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(id || user.id)) as IBasicConsultant;

  const {
    control,
    formState: { errors },
    getFieldState,
    handleSubmit,
    resetField,
  } = useFormContext();

  const { availabilities, outDates } = consultant;

  const availability = useAppSelector(availabilitiesSelector);
  const timePreferences = useAppSelector(timePreferencesSelector);

  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const [joyrideZIndex, setJoyrideZIndex] = useState(10000);

  const selectedAvailability = useWatch({
    control,
    name: 'availabilityWeek',
    defaultValue: availabilities[0]?.availabilityWeek,
  });

  const availabilityDate = useWatch({
    control,
    name: 'availableFrom',
    defaultValue: availabilities[0]?.availableFrom || dayjs().toDate(),
  });

  const selectedExtendedHours = useWatch({
    control,
    name: 'extendedHours',
    defaultValue: availabilities[0]?.extendedHours || 0,
  });

  const selectedTimezone = useWatch({
    control,
    name: 'timezone',
    defaultValue: {
      value: availabilities[0]?.timezone
        ? availabilities[0]?.timezone
        : (() => {
            const browserTz = getBrowserTimezoneAbbreviation();
            if (browserTz?.startsWith('GMT+')) {
              return '';
            }
            return timezoneList.find(item => item.value === browserTz)?.value;
          })(),
      label: availabilities[0]?.timezone
        ? timezoneList.find(item => item.value === availabilities[0]?.timezone)?.label
        : (() => {
            const browserTz = getBrowserTimezoneAbbreviation();
            if (browserTz?.startsWith('GMT+')) {
              return '';
            }
            return timezoneList.find(item => item.value === browserTz)?.label;
          })(),
    },
  });

  useEffect(() => {
    if (!availability.length) {
      dispatch(fetchAvailability());
    }
  }, [availability.length, dispatch]);

  useEffect(() => {
    if (!timePreferences.length) {
      dispatch(fetchTimePreferences());
    }
  }, [dispatch, timePreferences.length]);

  const onSubmit = async (values: any) => {
    if (!availabilities[0]?.id) {
      await dispatch(
        addAvailability({
          availabilities: [
            {
              availabilityWeek: values.availabilityWeek,
              availableFrom: values.availableFrom,
              forMeetTimeId: values.forMeetTimeId,
              forWorkTimeId: values.forWorkTimeId,
              timezone: selectedTimezone.value,
              extendedHours: selectedExtendedHours,
            },
          ],
          userId: id || user.id,
        }),
      );
    } else {
      await dispatch(
        updateAvailability({
          availabilities: [
            {
              id: availabilities[0]?.id,
              availabilityWeek: values.availabilityWeek,
              availableFrom: values.availableFrom,
              forMeetTimeId: values.forMeetTimeId,
              forWorkTimeId: values.forWorkTimeId,
              timezone: selectedTimezone.value,
              extendedHours: selectedExtendedHours,
            },
          ],
          userId: id || user.id,
        }),
      );
    }
    if (!!consultant.agencyId) {
      actions.updateAction({ experiences: [], expertise: [] });
      !isModal &&
        navigate(ROUTES.CONSULTANT, {
          state: {
            enableCongratsModal: false,
          },
        });
    }
    onSubmitted && onSubmitted();
  };

  useEffect(() => {
    if (showIntro) {
      const timeoutId: any = setTimeout(() => {
        let stepsList: Step[] = [];

        if (!consultant?.availabilities[0]?.availabilityWeek) {
          stepsList.push({
            target: '.availability-week-input',
            title: 'Weekly Availability',
            content: 'On average, how many hours per week are you available for work?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.availabilities[0]?.availableFrom) {
          stepsList.push({
            target: '.start-date-input',
            title: 'Start Date',
            content: 'When are you available to begin working on new projects?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.outDates[0]?.from || !consultant?.outDates[0]?.to) {
          stepsList.push({
            target: '.blockout-dates-input',
            title: 'Block Out Dates',
            content: 'When are you not available?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (
          !consultant?.availabilities[0]?.forMeetTimeId ||
          !consultant?.availabilities[0]?.forWorkTimeId
        ) {
          stepsList.push({
            target: '.availability-preference-input',
            title: 'Daily Availability',
            content: 'What hours of the day are you available to work?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        setSteps(stepsList);

        if (stepsList.length) {
          stepsList.push({
            target: '.submit-button',
            title: 'Save',
            content: 'Click Save button to save your changes.',
            placement: 'right',
            disableBeacon: true,
          });

          setRun(true);
        }
      }, 700);
      return () => clearTimeout(timeoutId);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    consultant?.availabilities[0]?.availableFrom,
    consultant?.outDates[0]?.from,
    consultant?.outDates[0]?.to,
    consultant?.availabilities[0]?.availabilityWeek,
    consultant?.availabilities[0]?.forMeetTimeId,
    showIntro,
    consultant?.availabilities[0]?.forWorkTimeId,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleComponentOpen = () => {
    if (mobile) {
      setJoyrideZIndex(0);
    }
  };

  const handleComponentClose = () => {
    if (mobile) {
      setJoyrideZIndex(10000);
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, type, index } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
    } else if (action === ACTIONS.CLOSE || action === ACTIONS.STOP) {
      setRun(false);
    } else if (type === EVENTS.STEP_AFTER) {
      setRun(false);
      setTimeout(() => {
        setRun(true);
      }, 500);
    }
  };

  return (
    <Box sx={{ mb: { xs: 12, xl: 0 } }}>
      <ReactJoyride
        continuous
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        showProgress
        disableOverlay
        scrollToFirstStep={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Skip',
        }}
        styles={deepmerge(
          {
            options: {
              backgroundColor: '#171717',
              arrowColor: '#171717',
              textColor: '#FFFFFF',
              primaryColor: '#FFCA28',
              zIndex: joyrideZIndex,
            },
          },
          customButtonStyles,
        )}
      />
      {!isModal && (
        <StyledSubtitle mb={2} component="p" variant="h6">
          Let us know when you&apos;re available to take on projects. This information helps us
          match you with the right opportunities. Remember to regularly update your availability in
          the platform to ensure you&apos;re visible to potential clients.
        </StyledSubtitle>
      )}
      <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
        {isModal && (
          <Box
            width={'100%'}
            height={'auto'}
            border={1}
            borderLeft={4}
            borderColor={'#F0B500'}
            mb={2}
            sx={{ backgroundColor: '#FEF8EB' }}
          >
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} m={'16px'}>
              <Box display={'flex'} flexDirection={'row'}>
                <LightBulbIcon />
                <Typography fontSize={'16px'} fontWeight={400} ml={'16px'}>
                  This information makes it easier for us to connect you with the best
                  opportunities. We encourage you to regularly update your availability on the
                  platform so you can stay visible to potential clients.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        <FormGroup sx={{ mb: 4 }} className="availability-week-input">
          <Typography
            mb={!isModal ? 2 : 0}
            variant="h6"
            sx={{ borderBottom: { md: !isModal ? '1px solid #ebebeb' : undefined } }}
          >
            {!isModal ? 'Availability' : 'Capacity'} {!isModal && <sup>*</sup>}
          </Typography>
          {isModal && (
            <Typography mb={2} variant="body1">
              Let us know how many hours each week you can dedicate to projects.
            </Typography>
          )}
          {availabilities ? (
            <>
              <Controller
                name="availabilityWeek"
                control={control}
                defaultValue={selectedAvailability}
                rules={{ required: 'Select your weekly availability' }}
                render={({ field }) => (
                  <Box {...field}>
                    {availability?.map((hours, i) => (
                      <Chip
                        key={`availbility-week-${i}`}
                        id={`availbility-week-${(i + 1) * 10}`}
                        label={`${hours} hours / week`}
                        onClick={() => field.onChange(hours)}
                        color={(selectedAvailability === hours && 'secondary') || undefined}
                        sx={{ mr: 2, mb: 1 }}
                        variant={(selectedAvailability === hours && 'filled') || 'outlined'}
                      />
                    ))}
                  </Box>
                )}
              />
              {!!errors.availabilityWeek && (
                <FormHelperText error>
                  {errors.availabilityWeek?.message?.toString() || 'Incorrect data'}
                </FormHelperText>
              )}
            </>
          ) : (
            <Loader />
          )}
        </FormGroup>
        {!isModal && (
          <FormGroup className="start-date-input">
            <InputLabel>Available From</InputLabel>
            <Controller
              name="availableFrom"
              defaultValue={availabilities[0]?.availableFrom || dayjs().toDate()}
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  isClearable
                  selected={
                    !!availabilityDate ? dayjs(availabilityDate).toDate() : dayjs().toDate()
                  }
                  onCalendarOpen={handleComponentOpen}
                  onCalendarClose={handleComponentClose}
                  calendarStartDay={1}
                  customInput={
                    <DateTextInput
                      className="text-custom-input"
                      onReset={() => resetField('availableFrom')}
                      icon={<CalendarIcon />}
                    />
                  }
                  showPopperArrow={false}
                  minDate={dayjs().toDate()}
                  placeholderText="DD/MM/YYYY"
                  withPortal={mobile}
                />
              )}
            />
            {!!errors.availableFrom && (
              <FormHelperText error>
                {errors?.availableFrom?.message?.toString() || 'Incorrect data'}
              </FormHelperText>
            )}
          </FormGroup>
        )}

        {isModal && (
          <Grid
            xs={12}
            md={6}
            mb={4}
            sx={{
              paddingTop: 3,
              paddingBottom: 2,
              borderTop: { md: '1px solid #ebebeb' },
              borderBottom: { md: '1px solid #ebebeb' },
            }}
          >
            <InputLabel>Work availability</InputLabel>
            <Controller
              name="forWorkTimeId"
              control={control}
              defaultValue={availabilities[0]?.forWorkTimeId || timePreferences[0]?.id}
              rules={{ required: 'This field is required' }}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  onOpen={handleComponentOpen}
                  onClose={handleComponentClose}
                  label="Select available time for work"
                  options={timePreferences || []}
                  labelMenuItem="text"
                />
              )}
            />
          </Grid>
        )}

        <Typography mb={1} variant="h6">
          Time zone
        </Typography>
        <Typography mb={2} variant="body1">
          Choose your time zone and the extended hours you are available to work.
        </Typography>
        <Grid container spacing={2} className="availability-preference-input">
          <Grid xs={12} md={6}>
            <InputLabel>Time zone</InputLabel>
            <Controller
              name="timezone"
              control={control}
              defaultValue={selectedTimezone}
              rules={{ required: 'This field is required' }}
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, newValue) => {
                    field.onChange(newValue);
                  }}
                  options={timezoneList}
                  getOptionLabel={option => {
                    return option ? `${option.label}` : '';
                  }}
                  renderInput={params => (
                    <BootstrapInput
                      {...params}
                      placeholder="Select an option"
                      sx={{
                        '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
                          width: '10px',
                        },
                      }}
                    />
                  )}
                  sx={{ backgroundColor: 'white' }}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <InputLabel>Extended hours</InputLabel>
            <Controller
              name="extendedHours"
              control={control}
              defaultValue={selectedExtendedHours}
              rules={{ required: 'This field is required' }}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  onOpen={handleComponentOpen}
                  onClose={handleComponentClose}
                  label="Select extended hours"
                  options={extendedHoursList}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Availability;
