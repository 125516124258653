import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  getDealOwnersSelector,
  getStaffingDraftOrdersSelector,
} from 'store/selectors/getDraftOrdersSelector';
import { staffingDraftOrdersSearchQuery } from 'store/query/draft-order/draft-order';
import { DraftOrderTalentPhase, DraftOrderTalentPhaseLabels } from 'store/types/draftOrder';

import { DealBadge } from 'components/Badge/DealBadge';
import {
  TableGrid,
  RowHeader,
  HeaderCell,
  DataRow,
  Cell,
  MainCell,
  MainHeaderCell,
} from 'components/Table/styled';
import UserRow from 'components/Table/UserCell';
import EmptyRow from 'components/Table/EmptyRow';
import { TableLoading } from 'components/Table/TableLoading';

import DealsListPageFooter from '../DealsListPageFooter';
import DealsListFilter, { EDealsListFilterType } from '../DealsListFilter';

import fetchUsersList from 'store/query/user/fetchUsersList';
import { outputQuery } from 'helpers/decorators';
import { getNameByRoleId } from 'models/consts';
import assignTalentToDeal from 'store/query/draft-order/assignTalentToDeal';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';

import {
  checkFilterBooleanValues,
  getUserFilterStr,
  navigateToDealDetails,
  talentPhaseKeys,
} from '../utils';

const DealsListStaffingTab: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const { items, count, page, status } = useAppSelector(getStaffingDraftOrdersSelector);
  const dealOwners = useAppSelector(getDealOwnersSelector);

  const [talentUserData, setTalentUserData] = useState<any[]>([]);
  const [talentId, setTalentId] = useState<number | undefined>(undefined);
  const [talentPhaseFilter, setTalentPhaseFilter] = useState<
    Record<DraftOrderTalentPhase, boolean>
  >(
    talentPhaseKeys.reduce(
      (obj, phase) => {
        obj[phase as DraftOrderTalentPhase] = true;
        return obj;
      },
      {} as Record<DraftOrderTalentPhase, boolean>,
    ),
  );
  const [dealOwnerFilter, setDealOwnerFilter] = useState<Record<string, boolean>>(
    getUserFilterStr(dealOwners),
  );

  const filterItems = [
    {
      label: 'All Deals',
      type: EDealsListFilterType.SEGMENT,
      items: [
        {
          label: 'All Deals',
          value: 'all',
          checked: true,
          onClick: () => setTalentId(undefined),
        },
        {
          label: 'My Deals',
          value: 'my',
          checked: true,
          onClick: () => setTalentId(user.id),
        },
      ],
    },
    {
      label: 'Talent Stage',
      type: EDealsListFilterType.SELECT,
      items: talentPhaseKeys.map(phase => ({
        label: DraftOrderTalentPhaseLabels[phase as keyof typeof DraftOrderTalentPhaseLabels],
        value: phase,
        checked: talentPhaseFilter[phase as DraftOrderTalentPhase],
        onClick: () =>
          setTalentPhaseFilter(prev => ({
            ...prev,
            [phase as DraftOrderTalentPhase]: !prev[phase as DraftOrderTalentPhase],
          })),
      })),
    },
    {
      label: 'Deal Owner',
      type: EDealsListFilterType.SELECT,
      items: Object.keys(dealOwnerFilter).map(dealOwner => {
        const [label, value] = dealOwner.split('|');

        return {
          label,
          value,
          checked: dealOwnerFilter[dealOwner],
          onClick: () =>
            setDealOwnerFilter(prev => ({
              ...prev,
              [dealOwner]: !prev[dealOwner],
            })),
        };
      }),
    },
  ];

  const filterMemo = useMemo(() => {
    const salesFilter = checkFilterBooleanValues<string>(dealOwnerFilter);

    return {
      talentUserIds: talentId ? [talentId] : undefined,
      salesIds:
        salesFilter && salesFilter.length > 0
          ? salesFilter.map(v => Number(v.split('|')[1]))
          : undefined,
      talentPhases: checkFilterBooleanValues<DraftOrderTalentPhase>(talentPhaseFilter),
    };
  }, [talentId, dealOwnerFilter, talentPhaseFilter]);

  useEffect(() => {
    dispatch(staffingDraftOrdersSearchQuery(filterMemo));
  }, [filterMemo, dispatch]);

  useEffect(() => {
    dispatch(
      fetchUsersList({
        filter: outputQuery({
          filterByRole: getNameByRoleId.find(item => item.name === 'Talent')?.id,
        }),
        limit: 100,
      }),
    ).then((data: any) => {
      setTalentUserData(data?.payload?.users || []);
    });
  }, [dispatch]);

  useEffect(() => {
    setDealOwnerFilter(getUserFilterStr(dealOwners));
  }, [dealOwners]);

  const handleAssignUser = (talentUserId: number | null, draftOrder: any) => {
    if (draftOrder?.id) {
      dispatch(assignTalentToDeal({ id: draftOrder.id, talentUserId: talentUserId })).then(
        response => {
          if (response) {
            dispatch(
              staffingDraftOrdersSearchQuery({
                talentUserIds: talentId ? [talentId] : undefined,
                talentPhases: checkFilterBooleanValues<DraftOrderTalentPhase>(talentPhaseFilter),
              }),
            );
          }
        },
      );
    }
  };

  return (
    <>
      <DealsListFilter filterItems={filterItems} />

      <TableGrid>
        <RowHeader>
          <MainHeaderCell>Deal Name</MainHeaderCell>
          <HeaderCell>Talent Stage</HeaderCell>
          <HeaderCell>Talent Manager</HeaderCell>
          <HeaderCell>Deal Owner</HeaderCell>
        </RowHeader>

        {status === 'loading' ? (
          <TableLoading columns={4} rows={3} firstColFlex={2} />
        ) : (
          <>
            {items ? (
              items.length === 0 ? (
                <EmptyRow />
              ) : (
                items.map((draftOrder, index) => (
                  <DataRow key={index}>
                    {/* Deal Name */}
                    <MainCell onClick={() => navigateToDealDetails(navigate, draftOrder.id)}>
                      {draftOrder.name}
                    </MainCell>

                    {/* Talent Stage */}
                    {draftOrder.talentPhase && (
                      <Cell onClick={() => navigateToDealDetails(navigate, draftOrder.id)}>
                        <DealBadge phase={draftOrder.talentPhase} />
                      </Cell>
                    )}

                    {/* Talent Manager */}
                    <UserRow
                      user={draftOrder.talentUser}
                      usersData={talentUserData}
                      onAssignUser={(talentUserId: number | null) =>
                        handleAssignUser(talentUserId, draftOrder)
                      }
                    />

                    {/* Deal Owner */}
                    <UserRow user={draftOrder.user} isEditable={false} />
                  </DataRow>
                ))
              )
            ) : null}

            <DealsListPageFooter
              count={count}
              page={page}
              onNextClick={() =>
                dispatch(
                  staffingDraftOrdersSearchQuery({
                    ...filterMemo,
                    pageAction: 'next',
                  }),
                )
              }
              onPreviousClick={() =>
                dispatch(
                  staffingDraftOrdersSearchQuery({
                    ...filterMemo,
                    pageAction: 'previous',
                  }),
                )
              }
            />
          </>
        )}
      </TableGrid>
    </>
  );
};

export default DealsListStaffingTab;
