import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

interface StyledHeaderWrapProps {
  maxWidth?: number | string;
}

const StyledHeaderWrap = styled(Grid)<StyledHeaderWrapProps>(({ theme, maxWidth }) =>
  theme.unstable_sx({
    maxWidth: maxWidth || 1076,
    position: 'sticky',
    top: 0,
    backgroundColor: 'common.white',
    zIndex: 2,
    boxShadow: '18px 0px white, -18px 0px white',
    [theme.breakpoints.up('md')]: {
      py: 4.5,
    },
    [theme.breakpoints.down('md')]: {
      py: 2,
    },
  }),
);

export { StyledHeaderWrap };
