import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChartUpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3.5 18.5L2 17L9.5 9.5L13.5 13.5L20.6 5.5L22 6.9L13.5 16.5L9.5 12.5L3.5 18.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
