import { styled, Box } from '@mui/material';

const DroplistContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    borderRadius: '4px',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.18)',
    padding: '6px 0',
    backgroundColor: '#fff',
    zIndex: 100,
  }),
);

export { DroplistContainer };
