import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, Box, Grid, InputAdornment, Typography } from '@mui/material';

import { SegmentToggle } from 'components/SegmentToggle';
import { BootstrapInput, Select } from 'components/CustomFields';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import getConsultantList from 'store/query/user/getConsultantList';
import { debounce } from 'lodash';
import { payoutTermsOptions } from 'models/consts';
import CustomTooltip from 'components/CustomTooltip';
import { IBasicOrders } from 'store/types/order';
import { DealsListFilterChild } from 'views/Deals/DealsList/DealsListFilter/styled';
import fetchProductPrices from 'store/query/common/fetchProductPrices';
import { productPricesSelector } from 'store/selectors/getCommonSelector';
import { TAXES } from 'store/constants';

interface ISpecialistSection {
  items?: IBasicOrders;
}

const SpecialistSection: FunctionComponent<ISpecialistSection> = ({ items }) => {
  const dispatch = useAppDispatch();
  const [specialistData, setSpecialistData] = useState<any[]>([]);
  const [isSpecialistRateCustomOrMarket, setIsSpecialistRateCustomOrMarket] =
    useState<boolean>(false);

  const {
    control,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useFormContext();

  const { specialistRate, customerRate, payoutTerms, isSpecialistCustomRateSelected, product } =
    watch();

  const productId = items?.expertise?.product?.id || null;

  useEffect(() => {
    dispatch(getConsultantList({ withAgency: 'false', isActive: true, isVerified: true })).then(
      (data: any) => {
        setSpecialistData(data?.payload?.consultants || []);
      },
    );
  }, [dispatch]);

  useEffect(() => {
    //on initial load
    if (productId) {
      dispatch(fetchProductPrices(productId))
        .unwrap()
        .then(data => {
          const defaultPriceData =
            data &&
            data.find((price: { levelId: number }) => price.levelId === (items?.levels?.id ?? 2));
          if (defaultPriceData) {
            const specialistPrice = defaultPriceData?.payoutCents / 100;
            setValue('specialistRate', specialistPrice);
            setValue('isSpecialistCustomRateSelected', false);
          }
          setIsSpecialistRateCustomOrMarket(isSpecialistCustomRateSelected);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, dispatch]);

  useEffect(() => {
    //run everytime product updates
    if (product) {
      dispatch(fetchProductPrices(product?.id));
    }
  }, [product, dispatch]);

  const debounced = debounce((value: any, searchField: string) => {
    if (searchField === 'specialist') {
      dispatch(
        getConsultantList({ withAgency: 'false', isActive: true, isVerified: true, search: value }),
      ).then((data: any) => {
        setSpecialistData(data?.payload?.consultants || []);
      });
    }
  }, 500);

  const searchTerm = (value: string, searchField: string) => {
    debounced(value, searchField);
  };

  const onSpecialistRateTypeChange = (segment: string) => {
    if (segment === 'Custom') {
      setValue('isSpecialistCustomRateSelected', true);
      setValue('specialistRate', '');
    } else {
      if (product) {
        dispatch(fetchProductPrices(product?.id))
          .unwrap()
          .then(data => {
            const defaultPriceData =
              data &&
              data.find((price: { levelId: number }) => price.levelId === (items?.levels?.id ?? 2));
            if (defaultPriceData) {
              const specialistPrice = defaultPriceData?.payoutCents / 100;
              setValue('specialistRate', specialistPrice);
            }
          });
      }
      setValue('isSpecialistCustomRateSelected', false);
    }
    setIsSpecialistRateCustomOrMarket(!isSpecialistRateCustomOrMarket);
  };

  return (
    <Box paddingLeft={'24px'} paddingRight={'24px'} paddingBottom={'24px'}>
      <Grid mb={'16px'} display={'flex'} flexDirection={'row'}>
        <Box width={'402px'}>
          <Typography fontSize={'16px'} fontWeight={500}>
            Specialist Rate, $/hour
            <CustomTooltip
              placement="top"
              title={
                !isSpecialistRateCustomOrMarket
                  ? "The market rate is based on the specialist's seniority and the selected product."
                  : 'Custom rates must be less than the Customer rate.'
              }
            />
          </Typography>
          <Controller
            control={control}
            name="specialistRate"
            render={({ field: { ref, ...field } }) => (
              <BootstrapInput
                {...field}
                type="number"
                disabled={!isSpecialistRateCustomOrMarket}
                placeholder={!isSpecialistCustomRateSelected ? 'Market rate' : 'Custom rate'}
                helperText={errors.specialistRate?.message || 'Incorrect data'}
                error={!!errors.specialistRate}
                onChange={(event: any) => {
                  const value = event.target.value;
                  field.onChange(parseInt(value));
                  setValue('specialistRate', parseInt(value));
                }}
                autoComplete="off"
              />
            )}
            rules={{
              validate: {
                greaterThanZero: value => {
                  if (Number(value) <= 0) {
                    return 'Rate must be greater than 0';
                  }
                },
                notGreaterThanCustomerRate: value => {
                  if (customerRate && Number(value) * 100 > Number(customerRate)) {
                    return 'Specialist rate cannot be greater than customer rate';
                  }
                },
              },
            }}
          />
        </Box>
        <Box width={'402px'} ml={2}>
          <Typography fontSize={'16px'} fontWeight={500}>
            Payment Terms
            <CustomTooltip
              placement="top"
              title="Number of days from timesheet approval to payout."
            />
          </Typography>
          <Controller
            control={control}
            name="payoutTerms"
            render={({ field: { ref, value, onChange, ...field } }) => (
              <Select
                {...field}
                value={value}
                // disabled={items?.status === 'draft'}
                inputRef={ref}
                label=""
                options={payoutTermsOptions}
                onChange={(event: any) => {
                  onChange(event.target.value);
                  setValue('payoutTerms', event.target.value);
                }}
              />
            )}
          />
        </Box>
      </Grid>

      <DealsListFilterChild>
        <SegmentToggle
          onSegmentChange={segment => onSpecialistRateTypeChange(segment)}
          defaultSelected={isSpecialistCustomRateSelected ? 'Custom' : 'Market'}
          segments={['Market', 'Custom']}
        />
      </DealsListFilterChild>

      <Box mt={'24px'}>
        <Typography fontSize={'16px'} fontWeight={500}>
          Specialist
        </Typography>
        <Controller
          control={control}
          name="specialist"
          render={({ field: { ref, ...field } }) => (
            <Autocomplete
              {...field}
              // disabled={items?.status === 'draft'}
              onInputChange={(_, value) => {
                searchTerm(value, 'specialist');
              }}
              onChange={(_, newValue) => {
                setValue('specialist', newValue);
                field.onChange(newValue);
              }}
              options={specialistData}
              getOptionLabel={option => {
                return option ? `${option.firstName || ''} ${option.lastName || option.email}` : '';
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={params => (
                <BootstrapInput {...params} placeholder="Start Typing Specialist's Name" />
              )}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default SpecialistSection;
