export const getReasonForWorkLabel = (name: string) => {
  const reasonLabels: any = {
    existing: 'Existing Instance | Enhancements | Upgrades | Customizations | Break/Fix',
    'new-project': 'New Project | Implementation | Migration | Integration',
    maintenance: 'Ongoing Operational Execution | Production | Maintenance',
  };

  return reasonLabels[name] || name;
};

export const getReasonSpecialistIsRequiredeLabel = (name: string) => {
  const reasonLabels: any = {
    'Net-New': 'Net-New Team Members',
    Existing: 'Replacing Existing Team Members',
  };

  return reasonLabels[name] || name;
};
