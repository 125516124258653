import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StyledDescription } from './DirectorySearchConsultantNameView/styled';

interface IDirectorySearchNotes {
  notes?: string | null;
}

const DirectorySearchNotes: React.FunctionComponent<IDirectorySearchNotes> = ({ notes }) => {
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h3">Notes</Typography>
      </Box>
      <StyledDescription>{notes}</StyledDescription>
    </>
  );
};

export default DirectorySearchNotes;
