import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" sx={{ fill: 'none' }}>
    <path
      d="M4.03125 8.03125L10.0312 14.0313L16.0312 8.03125L14.7562 6.75625L10.0312 11.4813L5.30625 6.75625L4.03125 8.03125Z"
      fill="#1F1F1F"
    />
  </SvgIcon>
);
