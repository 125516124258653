import dayjs from 'utils/dayjs';

export const timezoneList = [
  { value: 'BIT', label: '(GMT-12:00) Baker Island Time - Baker Island' },
  { value: 'SST', label: '(GMT-11:00) Samoa Standard Time - Pago Pago' },
  { value: 'CKT', label: '(GMT-10:00) Cook Island Time - Avarua' },
  { value: 'HST', label: '(GMT-10:00) Hawaii Standard Time - Honolulu, Hilo' },
  { value: 'HAST', label: '(GMT-10:00) Hawaii-Aleutian Standard Time - Adak' },
  { value: 'TAHT', label: '(GMT-10:00) Tahiti Time - Papeete' },
  { value: 'MIT', label: '(GMT-09:30) Marquesas Islands Time - Taiohae' },
  { value: 'AKST', label: '(GMT-09:00) Alaska Standard Time - Juneau, Anchorage, Fairbanks' },
  { value: 'GIT', label: '(GMT-09:00) Gambier Island Time - Rikitea' },
  { value: 'CIST', label: '(GMT-08:00) Clipperton Island Standard Time - Clipperton Island' },
  {
    value: 'PST',
    label: '(GMT-08:00) Pacific Standard Time - Los Angeles, San Francisco, Seattle, Vancouver',
  },
  {
    value: 'MST',
    label: '(GMT-07:00) Mountain Standard Time - Denver, Phoenix, Calgary, Edmonton',
  },
  { value: 'GALT', label: '(GMT-06:00) Galapagos Time - Puerto Ayora' },
  { value: 'EAST', label: '(GMT-06:00) Easter Island Standard Time - Hanga Roa' },
  {
    value: 'CST',
    label: '(GMT-06:00) Central Standard Time - Chicago, Mexico City, Houston, Winnipeg',
  },
  { value: 'COT', label: '(GMT-05:00) Colombia Time - Bogotá, Medellín, Cali' },
  { value: 'EST', label: '(GMT-05:00) Eastern Standard Time - New York, Toronto, Miami, Atlanta' },
  { value: 'ECT', label: '(GMT-05:00) Ecuador Time - Quito, Guayaquil' },
  { value: 'CLT', label: '(GMT-04:00) Chile Standard Time - Santiago, Valparaíso' },
  { value: 'BOT', label: '(GMT-04:00) Bolivia Time - La Paz, Santa Cruz, Sucre' },
  { value: 'AST', label: '(GMT-04:00) Atlantic Standard Time - Halifax, San Juan, Santo Domingo' },
  { value: 'GYT', label: '(GMT-04:00) Guyana Time - Georgetown' },
  { value: 'VET', label: '(GMT-04:00) Venezuela Standard Time - Caracas, Maracaibo' },
  { value: 'ECT', label: '(GMT-04:00) Eastern Caribbean Time - St. Lucia, Barbados, Grenada' },
  { value: 'NST', label: "(GMT-03:30) Newfoundland Standard Time - St. John's" },
  { value: 'ART', label: '(GMT-03:00) Argentina Time - Buenos Aires, Córdoba, Rosario' },
  { value: 'BRT', label: '(GMT-03:00) Brasilia Time - São Paulo, Rio de Janeiro, Brasília' },
  { value: 'FKT', label: '(GMT-03:00) Falkland Islands Time - Stanley' },
  { value: 'GFT', label: '(GMT-03:00) French Guiana Time - Cayenne' },
  { value: 'UYT', label: '(GMT-03:00) Uruguay Standard Time - Montevideo' },
  { value: 'GST', label: '(GMT-02:00) South Georgia Time - Grytviken' },
  { value: 'CVT', label: '(GMT-01:00) Cape Verde Time - Praia' },
  { value: 'GMT', label: '(GMT+00:00) Greenwich Mean Time - London, Dublin, Lisbon, Accra' },
  { value: 'WET', label: '(GMT+00:00) Western European Time - Lisbon, Reykjavik' },
  { value: 'DFT', label: '(GMT+01:00) AIX Time - Central European Time equivalent' },
  {
    value: 'CET',
    label: '(GMT+01:00) Central European Time - Paris, Berlin, Rome, Madrid, Prague',
  },
  { value: 'MET', label: '(GMT+01:00) Middle European Time - Same as CET' },
  { value: 'WAT', label: '(GMT+01:00) West Africa Time - Lagos, Kinshasa, Luanda' },
  { value: 'EET', label: '(GMT+02:00) Eastern European Time - Athens, Helsinki, Kyiv, Cairo' },
  { value: 'SAST', label: '(GMT+02:00) South African Time - Johannesburg, Cape Town, Pretoria' },
  { value: 'CAT', label: '(GMT+02:00) Central Africa Time - Harare, Lusaka' },
  { value: 'IST', label: '(GMT+02:00) Israel Standard Time - Jerusalem, Tel Aviv' },
  { value: 'MSK', label: '(GMT+03:00) Moscow Time - Moscow, St. Petersburg' },
  { value: 'AST', label: '(GMT+03:00) Arabia Standard Time - Riyadh, Baghdad, Kuwait' },
  { value: 'EAT', label: '(GMT+03:00) East Africa Time - Nairobi, Addis Ababa, Dar es Salaam' },
  { value: 'IRST', label: '(GMT+03:30) Iran Standard Time - Tehran, Mashhad' },
  { value: 'AMT', label: '(GMT+04:00) Armenia Time - Yerevan' },
  { value: 'AZT', label: '(GMT+04:00) Azerbaijan Time - Baku' },
  { value: 'GET', label: '(GMT+04:00) Georgia Time - Tbilisi' },
  { value: 'GST', label: '(GMT+04:00) Gulf Standard Time - Dubai, Abu Dhabi, Muscat' },
  { value: 'MUT', label: '(GMT+04:00) Mauritius Time - Port Louis' },
  { value: 'RET', label: '(GMT+04:00) Réunion Time - Saint-Denis' },
  { value: 'SCT', label: '(GMT+04:00) Seychelles Time - Victoria' },
  { value: 'SAMT', label: '(GMT+04:00) Samara Time - Samara' },
  { value: 'AFT', label: '(GMT+04:30) Afghanistan Time - Kabul' },
  { value: 'YEKT', label: '(GMT+05:00) Yekaterinburg Time - Yekaterinburg' },
  { value: 'PKT', label: '(GMT+05:00) Pakistan Time - Karachi, Lahore, Islamabad' },
  { value: 'TJT', label: '(GMT+05:00) Tajikistan Time - Dushanbe' },
  { value: 'HMT', label: '(GMT+05:00) Heard and McDonald Islands Time - Heard Island' },
  { value: 'IST', label: '(GMT+05:30) India Standard Time - Mumbai, Delhi, Bangalore, Kolkata' },
  { value: 'SLT', label: '(GMT+05:30) Sri Lanka Time - Colombo' },
  { value: 'NPT', label: '(GMT+05:45) Nepal Time - Kathmandu' },
  { value: 'OMST', label: '(GMT+06:00) Omsk Time - Omsk' },
  { value: 'BIOT', label: '(GMT+06:00) British Indian Ocean Time - Diego Garcia' },
  { value: 'BST', label: '(GMT+06:00) Bangladesh Time - Dhaka, Chittagong' },
  { value: 'BTT', label: '(GMT+06:00) Bhutan Time - Thimphu' },
  { value: 'CCT', label: '(GMT+06:30) Cocos Islands Time - West Island' },
  { value: 'MST', label: '(GMT+06:30) Myanmar Time - Yangon, Mandalay' },
  { value: 'KRAT', label: '(GMT+07:00) Krasnoyarsk Time - Krasnoyarsk' },
  { value: 'ICT', label: '(GMT+07:00) Indochina Time - Bangkok, Hanoi, Jakarta' },
  { value: 'THA', label: '(GMT+07:00) Thailand Time - Bangkok, Phuket' },
  { value: 'CXT', label: '(GMT+07:00) Christmas Island Time - Flying Fish Cove' },
  { value: 'WIB', label: '(GMT+07:00) Western Indonesia Time - Jakarta, Bandung' },
  { value: 'AWST', label: '(GMT+08:00) Australian Western Time - Perth' },
  { value: 'BNT', label: '(GMT+08:00) Brunei Time - Bandar Seri Begawan' },
  { value: 'CST', label: '(GMT+08:00) China Standard Time - Beijing, Shanghai, Hong Kong' },
  { value: 'HKT', label: '(GMT+08:00) Hong Kong Time - Hong Kong' },
  { value: 'MST', label: '(GMT+08:00) Malaysia Time - Kuala Lumpur, Penang' },
  { value: 'MYT', label: '(GMT+08:00) Malaysian Time - Same as MST' },
  { value: 'PHT', label: '(GMT+08:00) Philippines Time - Manila, Cebu, Davao' },
  { value: 'SGT', label: '(GMT+08:00) Singapore Time - Singapore' },
  { value: 'SST', label: '(GMT+08:00) Singapore Standard Time - Same as SGT' },
  { value: 'IRKT', label: '(GMT+08:00) Irkutsk Time - Irkutsk' },
  { value: 'ACST', label: '(GMT+09:30) Australian Central Time - Adelaide, Darwin' },
  { value: 'JST', label: '(GMT+09:00) Japan Standard Time - Tokyo, Osaka, Kyoto' },
  { value: 'KST', label: '(GMT+09:00) Korea Standard Time - Seoul, Pyongyang' },
  { value: 'YAKT', label: '(GMT+09:00) Yakutsk Time - Yakutsk' },
  { value: 'AEST', label: '(GMT+10:00) Australian Eastern Time - Sydney, Melbourne, Brisbane' },
  { value: 'CHST', label: '(GMT+10:00) Chamorro Time - Guam, Saipan' },
  { value: 'VLAT', label: '(GMT+10:00) Vladivostok Time - Vladivostok' },
  { value: 'LHST', label: '(GMT+10:30) Lord Howe Time - Lord Howe Island' },
  { value: 'MAGT', label: '(GMT+11:00) Magadan Time - Magadan' },
  { value: 'SBT', label: '(GMT+11:00) Solomon Islands Time - Honiara' },
  { value: 'NFT', label: '(GMT+11:30) Norfolk Time - Kingston' },
  { value: 'FJT', label: '(GMT+12:00) Fiji Time - Suva' },
  { value: 'GILT', label: '(GMT+12:00) Gilbert Islands Time - Tarawa' },
  { value: 'NZST', label: '(GMT+12:00) New Zealand Time - Auckland, Wellington' },
  { value: 'PETT', label: '(GMT+12:00) Kamchatka Time - Petropavlovsk-Kamchatsky' },
  { value: 'PHOT', label: '(GMT+13:00) Phoenix Islands Time - Kanton Island' },
  { value: 'CHAST', label: '(GMT+12:45) Chatham Time - Chatham Islands' },
  { value: 'LINT', label: '(GMT+14:00) Line Islands Time - Kiritimati' },
];

export const TIMEZONE_ABBREVIATIONS: Record<string, string> = {
  // 🇺🇸 North America
  'America/New_York': 'EST',
  'America/Chicago': 'CST',
  'America/Denver': 'MST',
  'America/Los_Angeles': 'PST',
  'America/Phoenix': 'MST',
  'America/Anchorage': 'AKST',
  'America/Adak': 'HST',
  'Pacific/Honolulu': 'HST',

  // 🇨🇦 Canada
  'America/Toronto': 'EST',
  'America/Vancouver': 'PST',
  'America/Edmonton': 'MST',
  'America/Winnipeg': 'CST',
  'America/Halifax': 'AST',
  'America/St_Johns': 'NST',

  // 🌍 South America
  'America/Sao_Paulo': 'BRT',
  'America/Buenos_Aires': 'ART',
  'America/Lima': 'PET',
  'America/Bogota': 'COT',
  'America/Caracas': 'VET',

  // 🌍 Europe
  'Europe/London': 'GMT',
  'Europe/Berlin': 'CET',
  'Europe/Paris': 'CET',
  'Europe/Madrid': 'CET',
  'Europe/Rome': 'CET',
  'Europe/Amsterdam': 'CET',
  'Europe/Stockholm': 'CET',
  'Europe/Athens': 'EET',
  'Europe/Istanbul': 'TRT',
  'Europe/Moscow': 'MSK',
  'Europe/Kiev': 'EET',
  'Europe/Kyiv': 'EET',

  // 🌍 Africa
  'Africa/Cairo': 'EET',
  'Africa/Johannesburg': 'SAST',
  'Africa/Nairobi': 'EAT',
  'Africa/Lagos': 'WAT',

  // 🌏 Asia
  'Asia/Kolkata': 'IST',
  'Asia/Calcutta': 'IST',
  'Asia/Dubai': 'GST',
  'Asia/Shanghai': 'CST',
  'Asia/Tokyo': 'JST',
  'Asia/Seoul': 'KST',
  'Asia/Hong_Kong': 'HKT',
  'Asia/Singapore': 'SGT',
  'Asia/Bangkok': 'ICT',
  'Asia/Jakarta': 'WIB',
  'Asia/Manila': 'PHT',
  'Asia/Kuala_Lumpur': 'MYT',

  // 🌏 Australia & Pacific
  'Australia/Sydney': 'AEST',
  'Australia/Melbourne': 'AEST',
  'Australia/Brisbane': 'AEST',
  'Australia/Perth': 'AWST',
  'Australia/Adelaide': 'ACST',
  'Australia/Darwin': 'ACST',
  'Pacific/Auckland': 'NZST',
  'Pacific/Fiji': 'FJT',
  'Pacific/Tahiti': 'TAHT',

  // 🌏 Middle East
  'Asia/Tehran': 'IRST',
  'Asia/Jerusalem': 'IST',
  'Asia/Riyadh': 'AST',

  // 🌍 Russia (Main Time Zones)
  'Asia/Yekaterinburg': 'YEKT',
  'Asia/Omsk': 'OMST',
  'Asia/Krasnoyarsk': 'KRAT',
  'Asia/Irkutsk': 'IRKT',
  'Asia/Vladivostok': 'VLAT',
  'Asia/Kamchatka': 'PETT',

  // 🌍 UTC & Offsets
  UTC: 'UTC',
  'Etc/GMT': 'GMT',
  'Etc/GMT+12': 'GMT-12',
  'Etc/GMT+11': 'GMT-11',
  'Etc/GMT+10': 'GMT-10',
  'Etc/GMT+9': 'GMT-9',
  'Etc/GMT+8': 'GMT-8',
  'Etc/GMT+7': 'GMT-7',
  'Etc/GMT+6': 'GMT-6',
  'Etc/GMT+5': 'GMT-5',
  'Etc/GMT+4': 'GMT-4',
  'Etc/GMT+3': 'GMT-3',
  'Etc/GMT+2': 'GMT-2',
  'Etc/GMT+1': 'GMT-1',
  'Etc/GMT-1': 'GMT+1',
  'Etc/GMT-2': 'GMT+2',
  'Etc/GMT-3': 'GMT+3',
  'Etc/GMT-4': 'GMT+4',
  'Etc/GMT-5': 'GMT+5',
  'Etc/GMT-6': 'GMT+6',
  'Etc/GMT-7': 'GMT+7',
  'Etc/GMT-8': 'GMT+8',
  'Etc/GMT-9': 'GMT+9',
  'Etc/GMT-10': 'GMT+10',
  'Etc/GMT-11': 'GMT+11',
  'Etc/GMT-12': 'GMT+12',
};

export const getBrowserTimezoneAbbreviation = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Use January 1st to always get standard time
  const standardTimeDate = new Date(Date.UTC(2024, 0, 1));
  let abbreviation = new Intl.DateTimeFormat('en-US', { timeZone: timezone, timeZoneName: 'short' })
    .formatToParts(standardTimeDate)
    .find(part => part.type === 'timeZoneName')?.value;

  return TIMEZONE_ABBREVIATIONS[timezone] || abbreviation;
};
