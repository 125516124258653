import { useEffect, useState } from 'react';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { remoteConfig } from '../firebase';

type RemoteConfigValue = string | boolean | Record<string, unknown> | undefined;

export enum FeatureFlagName {
  SHOW_SPECIALIST_RATING = 'SHOW_SPECIALIST_RATING',
}

const useRemoteConfig = (key: string) => {
  const [value, setValue] = useState<RemoteConfigValue>(undefined);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        await fetchAndActivate(remoteConfig);
        const fetchedRawValue = getValue(remoteConfig, key).asString();

        let parsedValue: RemoteConfigValue;
        if (fetchedRawValue === 'true' || fetchedRawValue === 'false') {
          parsedValue = fetchedRawValue === 'true';
        } else {
          try {
            parsedValue = JSON.parse(fetchedRawValue);
          } catch {
            parsedValue = fetchedRawValue;
          }
        }

        setValue(parsedValue);
      } catch (error) {
        console.error(`Failed to fetch Remote Config for key "${key}"`, error);
      }
    };

    fetchConfig();
  }, [key]);

  return value;
};

export default useRemoteConfig;
