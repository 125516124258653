import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { DropListCheckbox } from './DropListCheckbox';
import ChevronDown from 'styles/icons/ChevronDown';
import ChevronUp from 'styles/icons/ChevronUp';
import { DropListButton, DropListButtonCheckboxMain } from './styled';

interface DropListButtonCheckboxProps {
  buttonLabel: string;
  dropListItems: { label: string; checked: boolean; onClick: () => void }[];
  dropListWidth?: number | string;
  dropListPosition?: { left?: number | string; bottom?: number | string };
}

export const DropListButtonCheckbox: FunctionComponent<DropListButtonCheckboxProps> = ({
  buttonLabel,
  dropListItems,
  dropListWidth,
  dropListPosition,
}) => {
  const [isDropListVisible, setIsDropListVisible] = useState(false);

  const dropListRef = useRef<HTMLDivElement>(null);

  // Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropListRef.current && !dropListRef.current.contains(event.target as Node)) {
        setIsDropListVisible(false); // Close the dropdown
      }
    };

    if (isDropListVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropListVisible]);

  // Toggle the visibility of the DropList
  const toggleDropList = () => {
    setIsDropListVisible(!isDropListVisible);
  };

  return (
    <DropListButtonCheckboxMain ref={dropListRef}>
      <DropListButton
        onClick={toggleDropList}
        style={isDropListVisible ? { background: 'var(--Gray-300, #E7E7E7)' } : {}}
      >
        <span>{buttonLabel}</span>
        {isDropListVisible ? <ChevronUp /> : <ChevronDown />}
      </DropListButton>

      {isDropListVisible && (
        <DropListCheckbox items={dropListItems} width={dropListWidth} position={dropListPosition} />
      )}
    </DropListButtonCheckboxMain>
  );
};
