import React, { forwardRef, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { matchSorter } from 'match-sorter';

import { ToastService } from 'services';

import find from 'lodash/fp/find';
import filter from 'lodash/fp/filter';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import BootstrapInput from 'components/CustomFields/BootstrapInput';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import useMatchingParams from 'hooks/useMatchingParams';
import { useAppSelector } from 'store/hooks';
import { onTypeaheadItemChange } from 'utils/orderCreationHelpers';

import updateAction from 'store/actions/updateAction';

import { ICustomerOrderFlowProps } from 'store/types/inner-models';
import { EProductFilters } from 'models/consts';
import {
  IExtendCategoryWithProducts,
  IModuleOfProduct,
  IWorkTypesOfProduct,
} from 'store/types/categoryProducts';
import { IProduct } from 'store/types/categoryProducts';
import { IPurchaseOrder } from 'store/types/enterprise';
import { IBasicUser } from 'store/types/user';

import { getMeSelector, hasVerifiedCustomerSelector } from 'store/selectors/getUserSelector';
import {
  modulesByProductSelector,
  workTypesByProductSelector,
} from 'store/selectors/getCommonSelector';

import HeaderFlow from '../../Header/HeaderFlow';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import ToastMessage from 'components/Toast/ToastMessage';
import { StyledMasonry } from '../../CustomFields/CustomAutocomplete/styled';
import useSafeAreaView from 'components/SafeAreaView';
import ProductAutocomplete from 'components/CustomFields/CustomAutocomplete/ProductAutocomplete';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import RequestProductCustomerModal from 'components/Modal/RequestProductModal/RequestCustomer';
import BottomButton from '../BottomButton';

interface IExpertiseProps extends ICustomerOrderFlowProps {
  handleSignup: () => void;
  modulesList: IModuleOfProduct[];
  productList: IExtendCategoryWithProducts[];
  worksList: IWorkTypesOfProduct[];
}

interface IExpertiseForm {
  modules: IModuleOfProduct[];
  product?: IProduct | null;
  primaryModuleId: number;
  primaryWorkId: number;
  purchaseOrder?: IPurchaseOrder;
  works: IWorkTypesOfProduct[];
  description?: string;
}

const Expertise: React.FunctionComponent<IExpertiseProps> = ({
  isModal,
  handleSignup,
  modulesList,
  productList,
  worksList,
}) => {
  const location = useLocation();

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));

  const { safeAreaPadding } = useSafeAreaView();

  const [isRequestModalVisible, setRequestModalVisible] = useState(false);
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [isProductReqSuccess, setIsProductReqSuccess] = useState<boolean>(false);

  const user = useAppSelector(getMeSelector) as IBasicUser;

  const navigate = useNavigate();
  const [openCancel, setOpenCancel] = useState<boolean>(false);

  const { actions } = useStateMachine({ updateAction });

  const {
    matchingParams: { moduleIds, primaryModuleId, primaryWorkId, productId, workIds },
    order: { description },
  } = useMatchingParams();

  const {
    control,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
    resetField,
    watch,
    reset,
  } = useForm<IExpertiseForm>({
    mode: 'onChange',
    defaultValues: {
      description: description,
      modules:
        filter((item: IModuleOfProduct) => moduleIds?.includes(item.id))(modulesList) || undefined,
      product: find({ id: productId } as IProduct)(productList) || undefined,
      primaryModuleId,
      primaryWorkId,
      purchaseOrder: location.state || undefined,
      works:
        filter((item: IWorkTypesOfProduct) => workIds?.includes(item.id))(worksList) || undefined,
    },
  });

  const [product, primaryModule, primaryWorkType, descriptionWatch] = watch([
    'product',
    'primaryModuleId',
    'primaryWorkId',
    'description',
  ]);

  const modulesByProductList = useAppSelector(modulesByProductSelector(product?.id || productId));
  const worksByProductList = useAppSelector(workTypesByProductSelector(product?.id || productId));

  const hasVerifiedCustomer = useAppSelector(hasVerifiedCustomerSelector);

  useEffect(() => {
    if (user && user.role?.id === 2) {
      ToastService.error(
        ToastMessage({
          title: 'Alert',
          body: 'As a specialist you can not create an order.',
        }),
        { autoClose: false },
      );
    }
  }, [user]);

  useEffect(() => {
    actions.updateAction({ searchParams: {} });
  }, [actions]);

  const onSubmit: SubmitHandler<any> = values => {
    if (!hasVerifiedCustomer) {
      handleSignup();
    } else {
      const params = {
        workIds:
          (product?.isBasic && values.works.map((item: any) => item.id).toString()) ||
          worksByProductList[0].id.toString(),
        moduleIds: values.modules.map((item: any) => item.id).toString(),
        primaryModuleId: primaryModule,
        primaryWorkId: (product?.isBasic && primaryWorkType) || worksByProductList[0].id,
        productId: values.product?.id,
        description: values.description,
      };

      navigate({
        pathname: '/order-flow/2',
        search: createSearchParams(params as any).toString(),
      });
    }
  };

  const productFilters = (options: any, param: String, productType?: EProductFilters) => {
    const inputLower = (param || '').toLowerCase();

    const optionList =
      productType === EProductFilters.ALL
        ? options
        : options.filter((item: any) =>
            productType === EProductFilters.ROLE ? item?.isGeneralRoles : !item?.isGeneralRoles,
          );

    if (inputLower) {
      const filteredOptions = matchSorter(optionList as IProduct[], inputLower, {
        keys: ['name', 'categoryName', 'company.name', 'aliases'],
      });
      return (filteredOptions || []) as any[];
    }
    return optionList;
  };

  return (
    <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
      {!isModal && (
        <HeaderFlow
          id="expertise-header"
          disableBtn={!isValid || user?.role?.id === 2}
          btn="Next Step"
          title="Expertise needed"
          setOpenCancel={setOpenCancel}
        />
      )}
      <Grid container spacing={2} alignItems="flex-start" mb={2}>
        <Grid xs={12} md={5}>
          <FormLabel>
            <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
              Product or General Role <sup>*</sup>
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
              Which software product or general role fits your project needs?
            </Typography>
          </FormLabel>
        </Grid>
        <Grid xs={12} md={7}>
          <Controller
            control={control}
            name="product"
            render={({ field }) => (
              <ProductAutocomplete
                autocompleteProps={{
                  groupBy: option => option.categoryName,
                  ListboxComponent: forwardRef((props: any, ref: any) => (
                    <StyledMasonry ref={ref} columns={1} component="ul" spacing={2} {...props} />
                  )),
                  multiple: undefined,
                }}
                clerable
                inputProps={{
                  ...(tablet && { readOnly: true }),
                }}
                customFilter={(options, inputValue, type) =>
                  productFilters(options, inputValue, type)
                }
                field={field}
                handleChange={(event, fieldName) => {
                  if (!event) {
                    setValue('product', undefined);
                    !!productId &&
                      reset({
                        modules: [],
                        product: undefined,
                        works: [],
                      });
                  } else {
                    onTypeaheadItemChange(event, fieldName, setValue);
                    resetField('modules', { defaultValue: [] });
                    resetField('works', { defaultValue: [] });
                  }
                }}
                options={productList || []}
                placeholder="Select a Product or General Role"
                mobilesx={{
                  marginTop: safeAreaPadding.top,
                }}
                onRequestProduct={() => {
                  setRequestModalVisible(true);
                }}
                successProductRequest={isProductReqSuccess}
                setIsProductReqSuccess={setIsProductReqSuccess}
              />
            )}
            rules={{
              validate: {
                isProductSelceted: v => (typeof v === 'object' && !!v) || 'This field is required',
              },
            }}
          />
        </Grid>
      </Grid>
      {product?.id && (
        <>
          <Grid container spacing={2} alignItems="flex-start" mb={2}>
            <Grid xs={12} md={5} hidden={!product?.isBasic}>
              <FormLabel>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
                  Types of Work <sup>*</sup>
                </Typography>
                <Typography sx={{ fontSize: 16 }}>
                  Which type of work are you looking for?
                </Typography>
              </FormLabel>
            </Grid>
            <Grid xs={12} md={7} hidden={!product?.isBasic}>
              <Controller
                control={control}
                name="works"
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: true,
                    }}
                    inputProps={{
                      readOnly: true,
                      error: !!errors.works,
                      helperText: (errors.works as any)?.message || 'Incorrect data',
                      sx: {
                        '& .MuiInputBase-inputAdornedStart': {
                          minWidth: '0px !important',
                          padding: 0,
                        },
                      },
                    }}
                    field={field}
                    isMainTag
                    options={worksByProductList || worksList}
                    handleChange={(event, fieldName) =>
                      onTypeaheadItemChange(event, fieldName, setValue)
                    }
                    placeholder="Select Types of Work"
                    primaryItem={primaryWorkType}
                    onPrimaryItemChange={id => {
                      setValue('primaryWorkId', id);
                    }}
                    mobilesx={{
                      marginTop: 0,
                    }}
                    sx={{
                      '& .MuiAutocomplete-root': {
                        border: `1px solid ${theme.palette.grey[600]} !important`,
                      },
                    }}
                  />
                )}
                rules={{ required: (product?.isBasic && 'This field is required') || undefined }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-start" mb={2}>
            <Grid xs={12} md={5}>
              <FormLabel>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
                  Modules <sup>*</sup>
                </Typography>
                <Typography sx={{ fontSize: 16 }}>
                  {(product?.isBasic && 'Which modules do you need help with?') ||
                    'Which skills do you need help with?'}
                </Typography>
              </FormLabel>
            </Grid>
            <Grid xs={12} md={7}>
              <Controller
                control={control}
                name="modules"
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: true,
                    }}
                    inputProps={{
                      readOnly: true,
                      error: !!errors.modules,
                      helperText: (errors.modules as any)?.message || 'Incorrect data',
                      sx: {
                        '& .MuiInputBase-inputAdornedStart': {
                          minWidth: '0px !important',
                          padding: 0,
                        },
                      },
                    }}
                    field={field}
                    handleChange={(event, fieldName) =>
                      onTypeaheadItemChange(event, fieldName, setValue)
                    }
                    isMainTag
                    options={modulesByProductList || modulesList}
                    placeholder={`Select ${(product?.isBasic && 'Modules') || 'Skills'}`}
                    primaryItem={primaryModule}
                    onPrimaryItemChange={id => setValue('primaryModuleId', id)}
                    mobilesx={{
                      marginTop: 0,
                    }}
                  />
                )}
                rules={{ required: 'This field is required' }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="flex-start" mb={10}>
            <Grid xs={12} md={5}>
              <FormLabel>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
                  Order Description{' '}
                  <span style={{ color: '#8E8E8E', fontWeight: 'normal' }}>(optional)</span>
                </Typography>
                <Typography sx={{ fontSize: 16 }}>
                  Give an overview of your project goals, scope of work, and any must-have
                  expertise, so specialists know exactly how to help.
                </Typography>
              </FormLabel>
            </Grid>
            <Grid xs={12} md={7}>
              <Controller
                control={control}
                name="description"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.description}
                    placeholder="Add your Order Description"
                    helperText={errors.description?.message || 'Incorrect data'}
                    type="text"
                    multiline
                    sx={{
                      minHeight: 200,
                      paddingRight: 0,
                      paddingTop: 1,
                      alignItems: 'flex-start',
                    }}
                  />
                )}
                rules={{
                  maxLength: {
                    message: 'The max count of characters is 1000',
                    value: 1000,
                  },
                }}
              />
              <Typography textAlign="right" mt={1}>
                {descriptionWatch?.length || 0} <span style={{ color: '#5D5D5E' }}>/ 1000</span>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <BottomButton
        disabledBtn={!isValid || user?.role?.id === 2}
        openCancel={openCancel}
        setOpenCancel={setOpenCancel}
      />

      <RequestProductCustomerModal
        visible={isRequestModalVisible}
        close={() => setRequestModalVisible(false)}
        afterSubmit={() => {
          setConfirmationModalVisible(true);
          setIsProductReqSuccess(true);
          ToastService.success(
            ToastMessage({
              title: 'Request submitted successfully!',
              body: 'Thanks for reaching out. Our support team has received your request.',
            }),
          );
        }}
      />

      <ConfirmationModal
        visible={isConfirmationModalVisible}
        close={() => {
          setConfirmationModalVisible(false);
        }}
        title="Your product request has been sent to our team."
        subtitle="A copy of your request has been sent to your email."
        primaryButtonText="Request Another Product"
        onPrimaryButtonClick={() => {
          setConfirmationModalVisible(false);
          setRequestModalVisible(true);
        }}
      />
    </form>
  );
};

export default Expertise;
