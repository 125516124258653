import React, { FunctionComponent, useState } from 'react';
import dayjs from 'utils/dayjs';

import { Box, Button, Divider, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IOrdersHistory } from 'store/types/order';
import { getOrderActionText } from 'utils/orderCreationHelpers';
import ArrowUpColored from 'styles/icons/ArrowUpColored';
import ArrowDownColored from 'styles/icons/ArrowDownColored';
import { VerifiedIcon } from 'styles/icons/OrderHistoryIcons/VerifiedIcon';
import { getIconForChangesType } from 'utils/orderHistoryUtil';

interface IOrderHistoryProps {
  isPreOrder?: boolean;
  history: IOrdersHistory[];
}

const OrderHistory: FunctionComponent<IOrderHistoryProps> = ({ isPreOrder, history }) => {
  const [expanded, setExpanded] = useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const visibleActivities = expanded ? history : history.slice(0, 2);

  //TODO: When Responsive designs are available delete the old code.
  return (
    <Box
      mb={3}
      sx={{
        ...(mobile
          ? {}
          : {
              boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05)',
              border: '1px solid rgba(231, 231, 231, 0.7)',
              borderRadius: '4px',
              padding: '24px',
            }),
      }}
    >
      {isPreOrder && (
        <Typography variant="h4" mb={1}>
          {mobile ? 'Preorder History' : 'Preorder Activity'}
        </Typography>
      )}
      {!isPreOrder && (
        <Typography variant="h4" mb={1}>
          {mobile ? 'Order History' : 'Activity'}
        </Typography>
      )}
      {mobile && (
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ pl: 0 }}>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>By</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((row: IOrdersHistory) => (
                <TableRow key={row.id} sx={{ '& td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ pl: 0 }}>
                    {dayjs.utc(row.createdAt).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>{dayjs.utc(row.createdAt).format('HH:mm:ss')}</TableCell>
                  <TableCell>{getOrderActionText(row.changesType)}</TableCell>
                  <TableCell>
                    {isPreOrder
                      ? row.metadata.createdBy
                      : (!row.user?.firstName && !row.user?.lastName && 'System') ||
                        `${row.user?.firstName} ${row.user?.lastName}`}
                  </TableCell>
                  <TableCell>{row.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!mobile && (
        <Box mt={'24px'}>
          {visibleActivities.map((activity, index) => {
            const IconComponent = getIconForChangesType(activity.changesType);
            return (
              <Box key={activity.id}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      flexGrow: 1,
                    }}
                  >
                    <IconComponent />
                    <Typography
                      fontSize={'18px'}
                      fontWeight={400}
                      color={'#1F1F1F'}
                      ml={'16px'}
                      sx={{ maxWidth: '365px', flexGrow: 1 }}
                    >
                      {activity.message}
                    </Typography>
                  </Box>
                  <Box sx={{ whiteSpace: 'nowrap', ml: '16px' }}>
                    <Typography fontSize={'18px'} fontWeight={400} color={'#8E8E8E'}>
                      {dayjs.utc(activity.createdAt).format('MMM DD, YYYY, h:mm a')}
                    </Typography>
                  </Box>
                </Box>

                {index < visibleActivities.length - 1 && (
                  <Box
                    height={'25px'}
                    borderLeft={2}
                    borderColor={'#D4D4D4'}
                    ml={1.4}
                    mt={0.2}
                    mb={0.2}
                  ></Box>
                )}
              </Box>
            );
          })}
          {history.length > 2 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setExpanded(!expanded)}
              sx={{
                width: '150px',
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '0px',
                marginTop: '20px',
              }}
            >
              {expanded ? (
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <ArrowUpColored arrowColor="#5D5D5E" />
                  <Typography ml={'16px'} fontSize={'16px'} fontWeight={500}>
                    Less Updates
                  </Typography>
                </Box>
              ) : (
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <ArrowDownColored arrowColor="#5D5D5E" />
                  <Typography ml={'16px'} fontSize={'16px'} fontWeight={500}>
                    More Updates
                  </Typography>
                </Box>
              )}
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OrderHistory;
