import React, { FunctionComponent } from 'react';

import { IStepperItem } from 'store/types/common';

import { StyledRoot } from './styled';

import Step from './Step';

interface IStepper<T> {
  steps: Array<IStepperItem<T>>;
}

const Stepper: FunctionComponent<IStepper<any>> = ({ steps }) => (
  <StyledRoot mt={{ md: 2 }}>
    {steps.map((props: any) => (
      <Step key={props.number} {...props} />
    ))}
  </StyledRoot>
);

export default Stepper;
