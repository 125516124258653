export enum EDraftOrder {
  staffing = 'draftOrders/staffing',
  recommended_customer = 'draftOrders/recommended_customer',
  order_created = 'draftOrders/order_created',
  active = 'draftOrders/active',
  completed = 'draftOrders/completed',
  on_hold = 'draftOrders/on_hold',
  fetchDealDetailsById = 'draftOrders/fetchDealDetailsById',
  finalizeADeal = 'draftOrder/finalizeADeal',
  assignTalentToDeal = 'draftOrder/assignTalentToDeal',
  count = 'draftOrders/count',
  dealOwners = 'draftOrders/dealOwners',
  dealCustomers = 'draftOrders/dealCustomers',
}
