import React, { FunctionComponent } from 'react';

import { DropList } from '../DropList';

import CheckBoxIcon from 'styles/icons/CheckBoxIcon';
import { ListItem, Checkbox } from './styled';

interface DropListCheckboxProps {
  items: { label: string; checked: boolean; onClick: () => void }[];
  width?: number | string;
  position?: { left?: number | string; bottom?: number | string };
}

export const DropListCheckbox: FunctionComponent<DropListCheckboxProps> = ({
  items,
  width,
  position,
}) => {
  const listItems = items.map((item, index) => (
    <ListItem key={index} onClick={item.onClick}>
      <Checkbox checked={item.checked}>{item.checked && <CheckBoxIcon />}</Checkbox>

      <span>{item.label}</span>
    </ListItem>
  ));

  return <DropList items={listItems} width={width} position={position} />;
};
