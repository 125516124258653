import React from 'react';

interface ProgressBarProps {
  progress: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  height = 8,
  color = '#FFCA28',
  backgroundColor = '#E7E7E7',
}) => {
  // Ensure progress is between 0 and 100
  const clampedProgress = Math.min(100, Math.max(0, progress));

  return (
    <div
      style={{
        width: '100%',
        height: `${height}px`,
        backgroundColor,
        borderRadius: `${height / 2}px`,
        overflow: 'hidden',
        marginTop: '12px',
      }}
    >
      <div
        style={{
          width: `${clampedProgress}%`,
          height: '100%',
          backgroundColor: color,
          borderRadius: `${height / 2}px`,
          transition: 'width 0.3s ease',
        }}
      />
    </div>
  );
};

export default ProgressBar;
