import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" sx={{ fill: 'none' }}>
    <path
      d="M6.45 12.8002L11.25 8.0002L6.45 3.2002L5.43 4.2202L9.21 8.0002L5.43 11.7802L6.45 12.8002Z"
      fill="#D4D4D4"
    />
  </SvgIcon>
);
