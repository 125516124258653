import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { Capacitor } from '@capacitor/core';

const getMobilePlatform = Capacitor.getPlatform();

const StyledRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  width: '100%',
  height: '100%',
});

const StyledFluid = styled(Box)({
  position: 'relative',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  ...(getMobilePlatform === 'android' && {
    position: 'fixed',
    height: '80%',
  }),
});

const StyledWrap = styled(Box)({
  display: 'flex',
  position: 'absolute',
  inset: 0,
  flexDirection: 'column',
  overflowY: 'scroll',
  overflowX: 'hidden',
});

const StyledContainer = styled(Container)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    px: {
      xs: 2,
      sm: 3,
      md: 5,
      lg: 8,
    },
    height: '100%',
    // justifyContent: 'stretch',
  }),
);

export { StyledContainer, StyledFluid, StyledRoot, StyledWrap };
