import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_ENTITY } from 'models/consts';
import fetchDealDetailsById from 'store/query/draft-order/fetchDealDetailsById';

export interface DealDetailsState {
  dealDetails: any | null;
}
const initialState: DealDetailsState = {
  dealDetails: DEFAULT_ENTITY,
};

const { actions, reducer } = createSlice({
  name: 'deal-details',
  initialState,
  reducers: {
    resetDealDetails: state => {
      state.dealDetails = DEFAULT_ENTITY;
    },
  },
  extraReducers: builder => {
    builder
      //Update curated Order
      .addCase(fetchDealDetailsById.pending, state => {
        state.dealDetails = {
          ...state.dealDetails,
          items: [],
          loading: true,
          loaded: false,
        };
      })
      /**
       * Handles the fulfilled state of updating a curated order draft.
       */
      .addCase(fetchDealDetailsById.fulfilled, (state, action) => {
        const data = action.payload;
        state.dealDetails = {
          items: data,
          loading: false,
          loaded: true,
        };
      });
  },
});

export const { resetDealDetails } = actions;
export default reducer;
