import { styled, Box } from '@mui/material';

const SegmentControl = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: '2px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '1px solid rgba(31, 31, 31, 1)',
    alignSelf: 'stretch',
    display: 'flex',
    marginTop: 'auto',
    marginBottom: 'auto',
    alignItems: 'center',
    overflow: 'hidden',
    fontSize: '16px',
    lineHeight: '1',
    justifyContent: 'start',
  }),
);

// Base Segment button
const Segment = styled('button')(({ theme }) =>
  theme.unstable_sx({
    alignSelf: 'stretch',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRight: '1px solid rgba(231, 231, 231, 1)',
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: '8px 16px',
    gap: '8px',
    color: 'var(--Gray-900, #1F1F1F)',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    '&:last-child': {
      borderRight: 'none', // Remove border from the last segment
    },
  }),
);

// Active Segment button
const ActiveSegment = styled(Segment)(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: 'rgba(31, 31, 31, 1)',
    color: '#fff',
  }),
);

export { SegmentControl, Segment, ActiveSegment };
