import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  getCompletedDraftOrdersSelector,
  getDealCustomersSelector,
} from 'store/selectors/getDraftOrdersSelector';
import { completedDraftOrdersSearchQuery } from 'store/query/draft-order/draft-order';

import EmptyRow from 'components/Table/EmptyRow';
import {
  TableGrid,
  RowHeader,
  MainHeaderCell,
  HeaderCell,
  DataRow,
  Cell,
  MainCell,
} from 'components/Table/styled';
import UserRow from 'components/Table/UserCell';
import { OrderBadge } from 'components/Badge/OrderBadge';
import TimelineCell from 'components/Table/TimelineCell';
import { TableLoading } from 'components/Table/TableLoading';

import DealsListFilter, { EDealsListFilterType } from '../DealsListFilter';
import DealsListPageFooter from '../DealsListPageFooter';

import { checkFilterBooleanValues, getUserFilterStr, navigateToDealDetails } from '../utils';

const DealsListCompletedTab: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const dealCustomers = useAppSelector(getDealCustomersSelector);

  const { items, count, page, status } = useAppSelector(getCompletedDraftOrdersSelector);
  const [customerFilter, setCustomerFilter] = useState<Record<string, boolean>>(
    getUserFilterStr(dealCustomers),
  );

  const filterItems = [
    {
      label: 'Customer Name',
      type: EDealsListFilterType.SELECT,
      items: Object.keys(customerFilter).map(customer => {
        const [label, value] = customer.split('|');

        return {
          label,
          value,
          checked: customerFilter[customer],
          onClick: () =>
            setCustomerFilter(prev => ({
              ...prev,
              [customer]: !prev[customer],
            })),
        };
      }),
    },
  ];

  const filterMemo = useMemo(() => {
    const customerFiltersChecked = checkFilterBooleanValues<string>(customerFilter);

    return {
      customerIds:
        customerFiltersChecked && customerFiltersChecked.length > 0
          ? customerFiltersChecked.map(v => Number(v.split('|')[1]))
          : undefined,
    };
  }, [customerFilter]);

  useEffect(() => {
    dispatch(completedDraftOrdersSearchQuery(filterMemo));
  }, [filterMemo, dispatch]);

  return (
    <>
      <DealsListFilter filterItems={filterItems} />

      <TableGrid>
        <RowHeader>
          <MainHeaderCell>Order Name</MainHeaderCell>
          <HeaderCell>Order Status</HeaderCell>
          <HeaderCell>Customer Name</HeaderCell>
          <HeaderCell>Specialist Name</HeaderCell>
          <HeaderCell>Timeline</HeaderCell>
          <HeaderCell>Schedule Type</HeaderCell>
        </RowHeader>

        {status === 'loading' ? (
          <TableLoading columns={6} rows={3} firstColFlex={2} />
        ) : (
          <>
            {items ? (
              items.length === 0 ? (
                <EmptyRow />
              ) : (
                items
                  .filter(draftOrder => draftOrder.order?.status === 'completed')
                  .map((draftOrder, index) => (
                    <DataRow key={index}>
                      {/* Order Name */}
                      <MainCell onClick={() => navigateToDealDetails(navigate, draftOrder.id)}>
                        {draftOrder.name}
                      </MainCell>

                      {/* Order Status */}
                      {draftOrder.order && (
                        <Cell onClick={() => navigateToDealDetails(navigate, draftOrder.id)}>
                          <OrderBadge orderStatus={draftOrder.order.status} />
                        </Cell>
                      )}

                      {/* Customer Name */}
                      <UserRow user={draftOrder.customer as any} nameDisplayLength={10} />

                      {/* Specialist Name */}
                      <UserRow user={draftOrder.worker} nameDisplayLength={10} />

                      {/* Timeline */}
                      <TimelineCell timeline={draftOrder.order?.timeLine} />

                      {/* Schedule Type */}
                      <Cell onClick={() => navigateToDealDetails(navigate, draftOrder.id)}>
                        {draftOrder.metadata ? draftOrder.metadata.scheduleType : 'N/A'}
                      </Cell>
                    </DataRow>
                  ))
              )
            ) : null}

            <DealsListPageFooter
              count={count}
              page={page}
              onNextClick={() =>
                dispatch(
                  completedDraftOrdersSearchQuery({
                    ...filterMemo,
                    pageAction: 'next',
                  }),
                )
              }
              onPreviousClick={() =>
                dispatch(
                  completedDraftOrdersSearchQuery({
                    ...filterMemo,
                    pageAction: 'previous',
                  }),
                )
              }
            />
          </>
        )}
      </TableGrid>
    </>
  );
};

export default DealsListCompletedTab;
