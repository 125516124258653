import { styled, Box } from '@mui/material';

const StyledBadge = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: '999px',
    padding: '8px 12px',
    gap: '6px',
    display: 'flex',
    width: 'fit-content',
    background: '#FFFFFF',
    color: '#1F1F1F',
    border: '1px solid #E7E7E7',
    alignItems: 'center',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
  }),
);

export { StyledBadge };
