import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <rect width="24" height="24" fill="white" />
      <path
        d="M5.46664 18.5333H6.79664L15.92 9.41001L14.59 8.08001L5.46664 17.2033V18.5333ZM3.59998 20.4V16.4333L15.92 4.13667C16.1066 3.96556 16.3128 3.83334 16.5383 3.74001C16.7639 3.64667 17.0011 3.60001 17.25 3.60001C17.4989 3.60001 17.74 3.64667 17.9733 3.74001C18.2066 3.83334 18.4089 3.97334 18.58 4.16001L19.8633 5.46667C20.05 5.63778 20.1861 5.84001 20.2716 6.07334C20.3572 6.30667 20.4 6.54001 20.4 6.77334C20.4 7.02223 20.3572 7.25945 20.2716 7.48501C20.1861 7.71056 20.05 7.91667 19.8633 8.10334L7.56664 20.4H3.59998ZM15.2433 8.75667L14.59 8.08001L15.92 9.41001L15.2433 8.75667Z"
        fill="#5D5D5E"
      />
    </SvgIcon>
  );
};
