import { useMemo } from 'react';
import { useStateMachine } from 'little-state-machine';

import updateAction from '../store/actions/updateAction';
import { ETimeLinePlan } from '../components/OrderCreationComponents/Timeline/consts';
import { EPeriodNumberOfHours } from '../models/consts';
import { calcWeeks } from '../utils/dateFormatter';

const useMatchingParams = () => {
  const {
    state: { searchParams },
  } = useStateMachine({ updateAction });

  return useMemo(() => {
    const {
      agencyOnly,
      availabilityType,
      description,
      endDate,
      moduleIds,
      levelId,
      numberPeriod,
      period,
      primaryModuleId,
      primaryWorkId,
      productId,
      startDate,
      workIds,
      workType,
    } = searchParams;

    const fullTime: number = 40;
    const partTime: number = 20;
    const hoursPerDay: number = 8;
    const newWeeks = calcWeeks(endDate, startDate);

    const availabilityWeek =
      workType === ETimeLinePlan.WeeklyCommitment
        ? fullTime
        : workType === ETimeLinePlan.PartTimeWeeklyCommitment
          ? partTime
          : period === EPeriodNumberOfHours.HOURS
            ? Math.ceil(numberPeriod / newWeeks)
            : period === EPeriodNumberOfHours.DAYS
              ? Math.ceil((numberPeriod * hoursPerDay) / newWeeks)
              : 0;

    const calcAllHours =
      period === EPeriodNumberOfHours.HOURS
        ? numberPeriod
        : period === EPeriodNumberOfHours.DAYS
          ? numberPeriod * hoursPerDay
          : 0;

    return {
      matchingParams: {
        availabilityType,
        primaryModuleId,
        productId: productId || 0,
        startDate,
        endDate,
        availabilityWeek,
        flexible: workType === ETimeLinePlan.NumberOfHours || false,
        workIds: workIds?.split(',').map((str: string) => parseInt(str, 10)),
        moduleIds: moduleIds?.split(',').map((str: string) => parseInt(str, 10)),
        primaryWorkId,
        agencyCardMaxConsultantsCount: 50,
        matchingModeOrAgencyId: agencyOnly === 'true' ? -1 : 0,
      },
      order: {
        description,
        levelId: levelId || 0,
        allHours:
          workType === ETimeLinePlan.NumberOfHours
            ? calcAllHours
            : calcWeeks(endDate, startDate) * availabilityWeek,
      },
      params: {
        availabilityType,
        numberPeriod,
        period,
        workType,
      },
    };
  }, [searchParams]);
};

export default useMatchingParams;
