import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CalenderIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="M5.39998 21.6C4.90498 21.6 4.48123 21.4209 4.12873 21.0625C3.77623 20.7042 3.59998 20.2834 3.59998 19.8V6.60002C3.59998 6.11669 3.77623 5.69586 4.12873 5.33752C4.48123 4.97919 4.90498 4.80002 5.39998 4.80002H7.19998V2.40002H8.99998V4.80002H15V2.40002H16.8V4.80002H18.6C19.095 4.80002 19.5187 4.97919 19.8712 5.33752C20.2237 5.69586 20.4 6.11669 20.4 6.60002V19.8C20.4 20.2834 20.2237 20.7042 19.8712 21.0625C19.5187 21.4209 19.095 21.6 18.6 21.6H5.39998ZM5.39998 19.8H18.6V10.8H5.39998V19.8ZM5.39998 9.00002H18.6V6.60002H5.39998V9.00002Z"
        fill="#5D5D5E"
      />
    </SvgIcon>
  );
};
