import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  getDealOwnersSelector,
  getOrderCreatedDraftOrdersSelector,
} from 'store/selectors/getDraftOrdersSelector';
import { orderCreatedDraftOrdersSearchQuery } from 'store/query/draft-order/draft-order';
import { OrderStatus, OrderStatusLabels } from 'store/types/draftOrder';

import EmptyRow from 'components/Table/EmptyRow';
import {
  TableGrid,
  RowHeader,
  MainHeaderCell,
  HeaderCell,
  Cell,
  MainCell,
  DataRow,
} from 'components/Table/styled';
import UserRow from 'components/Table/UserCell';
import { OrderBadge } from 'components/Badge/OrderBadge';
import { TableLoading } from 'components/Table/TableLoading';

import DealsListPageFooter from '../DealsListPageFooter';
import DealsListFilter, { EDealsListFilterType } from '../DealsListFilter';

import {
  orderStatusValues,
  checkFilterBooleanValues,
  getUserFilterStr,
  navigateToDealDetails,
} from '../utils';

const DealsListOrderCreatedTab: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { items, count, page, status } = useAppSelector(getOrderCreatedDraftOrdersSelector);
  const dealOwners = useAppSelector(getDealOwnersSelector);

  const [orderStatusFilter, setOrderStatusFilter] = useState<Record<OrderStatus, boolean>>(
    orderStatusValues.reduce(
      (obj, status) => {
        obj[status as OrderStatus] = true;
        return obj;
      },
      {} as Record<OrderStatus, boolean>,
    ),
  );
  const [dealOwnerFilter, setDealOwnerFilter] = useState<Record<string, boolean>>(
    getUserFilterStr(dealOwners),
  );

  const filterItems = [
    {
      label: 'Order Status',
      type: EDealsListFilterType.SELECT,
      items: orderStatusValues.map(status => ({
        label: OrderStatusLabels[status as keyof typeof OrderStatusLabels],
        value: status,
        checked: orderStatusFilter[status as OrderStatus],
        onClick: () =>
          setOrderStatusFilter(prev => ({
            ...prev,
            [status as OrderStatus]: !prev[status as OrderStatus],
          })),
      })),
    },
    {
      label: 'Deal Owner',
      type: EDealsListFilterType.SELECT,
      items: Object.keys(dealOwnerFilter).map(dealOwner => {
        const [label, value] = dealOwner.split('|');

        return {
          label,
          value,
          checked: dealOwnerFilter[dealOwner],
          onClick: () =>
            setDealOwnerFilter(prev => ({
              ...prev,
              [dealOwner]: !prev[dealOwner],
            })),
        };
      }),
    },
  ];

  const filterMemo = useMemo(() => {
    const salesFilter = checkFilterBooleanValues<string>(dealOwnerFilter);

    return {
      orderStatuses: checkFilterBooleanValues<OrderStatus>(orderStatusFilter),
      salesIds:
        salesFilter && salesFilter.length > 0
          ? salesFilter.map(v => Number(v.split('|')[1]))
          : undefined,
    };
  }, [orderStatusFilter, dealOwnerFilter]);

  useEffect(() => {
    dispatch(orderCreatedDraftOrdersSearchQuery(filterMemo));
  }, [filterMemo, dispatch]);

  return (
    <>
      <DealsListFilter filterItems={filterItems} />

      <TableGrid>
        <RowHeader>
          <MainHeaderCell>Order Name</MainHeaderCell>
          <HeaderCell>Order Status</HeaderCell>
          <HeaderCell>Deal Owner</HeaderCell>
        </RowHeader>

        {status === 'loading' ? (
          <TableLoading columns={6} rows={3} firstColFlex={2} />
        ) : (
          <>
            {items ? (
              items.length === 0 ? (
                <EmptyRow />
              ) : (
                items.map((draftOrder, index) => (
                  <DataRow key={index}>
                    {/* Order Name */}
                    <MainCell onClick={() => navigateToDealDetails(navigate, draftOrder.id)}>
                      {draftOrder.name}
                    </MainCell>

                    {/* Order Status */}
                    {draftOrder.order ? (
                      <Cell onClick={() => navigateToDealDetails(navigate, draftOrder.id)}>
                        <OrderBadge orderStatus={draftOrder.order.status} />
                      </Cell>
                    ) : draftOrder.status ? (
                      <Cell onClick={() => navigateToDealDetails(navigate, draftOrder.id)}>
                        <OrderBadge orderStatus={draftOrder.status} />
                      </Cell>
                    ) : null}

                    {/* Deal Owner */}
                    <UserRow user={draftOrder.user} />
                  </DataRow>
                ))
              )
            ) : null}

            <DealsListPageFooter
              count={count}
              page={page}
              onNextClick={() =>
                dispatch(
                  orderCreatedDraftOrdersSearchQuery({
                    ...filterMemo,
                    pageAction: 'next',
                  }),
                )
              }
              onPreviousClick={() =>
                dispatch(
                  orderCreatedDraftOrdersSearchQuery({
                    ...filterMemo,
                    pageAction: 'previous',
                  }),
                )
              }
            />
          </>
        )}
      </TableGrid>
    </>
  );
};

export default DealsListOrderCreatedTab;
