import React, { FunctionComponent } from 'react';

import { DroplistContainer } from './styled';

interface DropListProps {
  items: React.ReactNode[];
  width?: number | string;
  position?: { left?: number | string; bottom?: number | string };
}

export const DropList: FunctionComponent<DropListProps> = ({
  items,
  width = 264,
  position = {},
}) => {
  return <DroplistContainer style={{ width, ...position }}>{items}</DroplistContainer>;
};
