import React, { FunctionComponent } from 'react';

import { Badge } from '..';
import CircleIcon from 'components/CircleIcon';

import { TOrderStatus } from 'models/consts';
import { DraftOrderStatus } from 'store/types/draftOrder';

interface DealBadgeProps {
  orderStatus: TOrderStatus | DraftOrderStatus;
}

const orderStatusMap: {
  [key in TOrderStatus | string]: [{ background: string; color: string; border: string }, string];
} = {
  created: [
    {
      background: ' #D1E9FF',
      color: ' #1849A9',
      border: 'none',
    },
    'Created',
  ],
  expired: [
    {
      background: '#FFFFFF',
      color: '#1F1F1F',
      border: '1px solid #E7E7E7',
    },
    'Expired',
  ],
  waiting_for_payment: [
    {
      background: ' #FFF2D6',
      color: ' #9A7400',
      border: 'none',
    },
    'Waiting for payment',
  ],
  canceled: [
    {
      background: '#FFDBE4',
      color: '#DB204D',
      border: 'none',
    },
    'Canceled',
  ],
  pending_confirmation: [
    {
      background: '#CFF9FE',
      color: '#0E7090',
      border: 'none',
    },
    'Pending Confirmation',
  ],
  ready_to_start: [
    {
      background: '#CEEAB0',
      color: '#4F7A21',
      border: 'none',
    },
    'Ready to start',
  ],
  active: [
    {
      background: ' #CDF2CE',
      color: ' #027805',
      border: 'none',
    },
    'Active',
  ],
  on_hold: [
    {
      background: '#FDE7DD',
      color: '#C4460D',
      border: 'none',
    },
    'On Hold',
  ],
  completed: [{ background: '#E7E7E7', color: '#1F1F1F', border: 'none' }, 'Completed'],
  terminated: [
    {
      background: '#FCCEEE',
      color: '#C11574',
      border: 'none',
    },
    'Terminated',
  ],
  terminated_during_trial: [
    {
      background: '#FCCEEE',
      color: '#C11574',
      border: 'none',
    },
    'Terminated During Trial',
  ],
  draft: [
    {
      background: '#FFFFFF',
      color: '#1F1F1F',
      border: '1px solid #E7E7E7',
    },
    'Draft',
  ],
  drafts: [
    {
      background: '#FFFFFF',
      color: '#1F1F1F',
      border: '1px solid #E7E7E7',
    },
    'Drafts',
  ],
  pending_customer: [
    {
      background: 'var(--Tangerine-200, #FDE7DD);',
      color: 'var(--Tangerine-900, #C4460D);',
      border: 'none',
    },
    'Customer Invited',
  ],
  in_revision: [{ background: '#e8f5e9', color: '#2e7d32', border: 'none' }, 'In Revision'],
  pending_introduction: [
    {
      background: '#CFF9FE',
      color: '#0E7090',
      border: 'none',
    },
    'Pending Introduction',
  ],
  pending_agency: [
    {
      background: '#CFF9FE',
      color: '#0E7090',
      border: 'none',
    },
    'Pending Agency',
  ],
  pending_approval: [
    {
      background: '#CFF9FE',
      color: '#0E7090',
      border: 'none',
    },
    'Pending Approval',
  ],
  ready_to_finalize: [
    {
      background: '#FFFFFF',
      color: '#1F1F1F',
      border: '1px solid #E7E7E7',
    },
    'Ready to Finalize',
  ],
  awaiting_customer_signup: [
    {
      background: '#FFFFFF',
      color: '#1F1F1F',
      border: '1px solid #E7E7E7',
    },
    'Awaiting Customer Signup',
  ],
  new: [
    {
      background: '#FFFFFF',
      color: '#1F1F1F',
      border: '1px solid #E7E7E7',
    },
    'New',
  ],
};

export const OrderBadge: FunctionComponent<DealBadgeProps> = ({ orderStatus }) => {
  const style = orderStatusMap[orderStatus.toLowerCase()][0];
  const text = orderStatusMap[orderStatus.toLowerCase()][1];

  return (
    <Badge style={style}>
      <CircleIcon color={style.color} />
      {text}
    </Badge>
  );
};
