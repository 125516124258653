import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" sx={{ fill: 'none' }}>
    <path
      d="M16.0312 11.9688L10.0312 5.96875L4.03125 11.9688L5.30625 13.2438L10.0312 8.51875L14.7563 13.2438L16.0312 11.9688Z"
      fill="#1F1F1F"
    />
  </SvgIcon>
);
