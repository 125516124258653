import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import EditIcon from 'styles/icons/EditIcon';
import ShareArrowIcon from 'styles/icons/ShareArrow';

import { statusText } from 'utils/textFormatter';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  getMeRoleSelector,
  isAgencyAdministratorsSelector,
  isConsultantSelector,
} from 'store/selectors/getUserSelector';
import {
  currentOrderSelector,
  currentOrderSharedLinkSelector,
  orderHistorySelector,
} from 'store/selectors/getOrderSelector';
import fetchSharedLinks from 'store/query/order/fetchSharedLinks';
import fetchOrderHistory from 'store/query/order/fetchOrderHistory';
import fetchOrderList from 'store/query/order/fetchOrderList';
import getCompaniesLogos from 'store/query/consultant/getCompaniesLogos';

import ChangeTimeLineModal from 'components/Modal/ChangeTimeline/ChangeTimeline';
import CustomerPreview from 'components/CustomerPreview/CustomerPreview';
import IssueBlock from 'components/IssueBlock/IssueBlock';
import ManageOrder from 'components/Order/ManageOrder';
import OrderDetails from 'components/Order/Details';
import OrderHistory from 'components/Order/History';
import TypeOfWork from 'components/Chips/TypeOfWork/TypeOfWork';
import WeeklyBillings from 'components/Order/WeeklyBillings';
import ChangeNameModal from 'components/Modal/ChangeNameModal/ChangeNameModal';
import OrderFeedback from 'components/Order/Feedback';
import AgencyCard from 'components/AgencyComponents/AgencyCard';
import ConsultantPreview from 'components/ConsultantComponents/ConsultantPreview';

import { Accordion, AccordionDetails, AccordionSummary } from './styled';

import ROUTES from 'router/constants';
import { EOrderFilter, ERole, ERoleId, ESorting } from 'models/consts';
import axios from 'axios';
import useCalculatePrice from '../../../hooks/useCalculatePrice';

interface IOrderPreviewProps {
  id: string;
  isMinimized?: boolean;
  isDraftOrder?: boolean;
}

const OrderPreview: FunctionComponent<IOrderPreviewProps> = ({
  id,
  isMinimized,
  isDraftOrder = false,
}) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const role = useAppSelector(getMeRoleSelector);

  const order = useAppSelector(currentOrderSelector(id));
  const history = useAppSelector(orderHistorySelector(id));

  const sharedLink = useAppSelector(currentOrderSharedLinkSelector(id));

  const isConsultant = useAppSelector(isConsultantSelector);
  const isAgency = useAppSelector(isAgencyAdministratorsSelector);

  const [showTimeline, setTimelineModal] = useState<boolean>(false);
  const [showRenameModal, setVisiblityRename] = useState<boolean>(false);
  const [isDescriptionOpen, setDescriptionOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isDraftOrder) {
      dispatch(fetchOrderHistory({ id, role, isPurchase: !!order?.purchaseOrder }));
    }
  }, [dispatch, id, isDraftOrder, role, order?.purchaseOrder]);

  useEffect(() => {
    if (order?.worker?.id && order?.id && !order.agencyId) {
      dispatch(getCompaniesLogos({ orderId: order?.id, workerId: order?.worker?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, order?.id, order?.worker?.id]);

  useEffect(() => {
    if (!isDraftOrder) {
      dispatch(fetchSharedLinks(id));
    }
  }, [dispatch, id, isDraftOrder]);

  useEffect(() => {
    if (isDraftOrder) {
      dispatch(
        fetchOrderList({
          params: {
            limit: 4,
            offset: 0,
            sortStartDate: ESorting.ASC,
          },
          filter: EOrderFilter.DRAFT_ORDERS,
          key: ['draftOrders', 'nonEnterprise', 'ASC'].join(':'),
          id,
          role,
        }),
      );
    }
  }, [isDraftOrder, dispatch, id, role]);

  const handleOpenPreview = () => {
    navigate(`${ROUTES.CONSULTANT}/${order?.worker.consultant.nickName || order?.workerId}`);
  };

  const toggleVisibility = () => {
    setDescriptionOpen((s: boolean) => !s);
  };

  const orderStatus = useMemo(() => {
    if (isDraftOrder) {
      return 'drafts';
    }
    return order?.status;
  }, [order?.status, isDraftOrder]);

  return (
    <>
      {order ? (
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid container lg={(isMinimized && 12) || 9}>
            <Grid
              container
              xs={12}
              alignItems={{ xs: 'center', md: 'start' }}
              justifyContent="space-between"
              spacing={1}
            >
              <Grid container xs={12} lg="auto" sx={{ maxWidth: { md: 'calc(100% - 170px)' } }}>
                <Grid xs>
                  <Typography component="span" variant="h5" gutterBottom data-test="order-header">
                    {order?.name}
                  </Typography>
                </Grid>
                <Grid xs="auto">
                  <Grid display={{ md: 'none' }}>
                    {!isMinimized &&
                      [ERoleId.SELF_CUSTOMER, ERoleId.CUSTOMER_ADMIN, ERoleId.CUSTOMER].includes(
                        role?.id,
                      ) &&
                      order.status !== 'completed' && (
                        <IconButton onClick={() => setVisiblityRename(true)} size="small">
                          <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
                        </IconButton>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs="auto" container alignItems="center" spacing={1} order={{ xs: 2, md: 1 }}>
                <Grid display={{ xs: 'none', md: 'block' }}>
                  {!isMinimized &&
                    [ERoleId.SELF_CUSTOMER, ERoleId.CUSTOMER_ADMIN, ERoleId.CUSTOMER].includes(
                      role?.id,
                    ) &&
                    order.status !== 'completed' && (
                      <IconButton onClick={() => setVisiblityRename(true)} size="small">
                        <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
                      </IconButton>
                    )}
                </Grid>
                <Grid>
                  {orderStatus && (
                    <Chip
                      data-test="order-status"
                      className={orderStatus}
                      label={statusText(orderStatus)}
                      variant="filled"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid lg={12} order={{ xs: 1, md: 2 }}>
                <Typography variant="body2">
                  Order{' '}
                  <Typography component="span" variant="subtitle2">
                    #{isDraftOrder ? order.id : order.hashCode}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid py={0} container xs={12} spacing={0}>
              <Grid p={0} xs>
                <Divider sx={{ marginTop: 0 }} />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid xs={12} md={5}>
                <Typography mb={1} variant="body1">
                  <b>{order.expertise?.product.name}</b>{' '}
                  <strong>
                    {(order?.levels?.name && '- ') || ''}
                    {order?.levels?.name}
                  </strong>
                </Typography>
                <Typography mb={1} variant="body1">
                  <strong>{order.expertise?.modules.map(({ name }) => name).join(' / ')}</strong>
                </Typography>
                <Box display="flex" flexWrap="wrap">
                  {order.expertise?.works.map(({ id, name }) => (
                    <TypeOfWork key={`work-type-${id}`} name={name} />
                  ))}
                </Box>
              </Grid>
              <Grid xs={12} md>
                <OrderDetails order={order} />
              </Grid>
              <Grid xs="auto" display={{ xs: 'none', md: 'block' }}>
                <Grid container direction="column" alignItems="flex-end">
                  {sharedLink &&
                    (role.name === ERole.CUSTOMER_ADMIN ||
                      role.name === ERole.CUSTOMER ||
                      role.name === ERole.CUSTOMER_TEAM_ADMIN) &&
                    !order.purchaseOrder && (
                      <Button
                        color="tertiary"
                        href={`${ROUTES.PAYMENT}/${sharedLink?.hashCode}`}
                        sx={{ mb: 2 }}
                        startIcon={<ShareArrowIcon />}
                        variant="contained"
                      >
                        Share Payment Link
                      </Button>
                    )}
                  {!isMinimized && order.status === 'created' && role?.id === 3 && (
                    <Button
                      color="tertiary"
                      onClick={() => setTimelineModal(true)}
                      variant="contained"
                      fullWidth
                    >
                      Edit Timeline
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} container display={{ md: 'none' }}>
              {sharedLink &&
                (role.name === ERole.CUSTOMER_ADMIN ||
                  role.name === ERole.CUSTOMER ||
                  role.name === ERole.CUSTOMER_TEAM_ADMIN) &&
                !order.purchaseOrder && (
                  <Grid xs={12}>
                    <Button
                      color="tertiary"
                      href={`${ROUTES.PAYMENT}/${sharedLink.hashCode}`}
                      startIcon={<ShareArrowIcon />}
                      variant="contained"
                      fullWidth
                    >
                      Share Payment Link
                    </Button>
                  </Grid>
                )}
              {!isMinimized && order.status === 'created' && role?.id === 3 && (
                <Grid xs={12}>
                  <Button
                    color="tertiary"
                    onClick={() => setTimelineModal(true)}
                    variant="contained"
                    fullWidth
                  >
                    Edit Timeline
                  </Button>
                </Grid>
              )}
            </Grid>

            {order.description && (
              <>
                <Grid py={0} container xs={12} spacing={0}>
                  <Grid p={0} xs>
                    <Divider sx={{ margin: 0 }} />
                  </Grid>
                </Grid>
                <Grid container xs={12}>
                  <Grid xs>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography variant="h4">Order Description</Typography>
                      </AccordionSummary>
                      <AccordionDetails>{order.description}</AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                <Grid py={0} container xs={12} spacing={0}>
                  <Grid p={0} xs>
                    <Divider sx={{ marginTop: 0 }} />
                  </Grid>
                </Grid>
              </>
            )}

            {!isConsultant && (
              <Grid container xs={12}>
                <Grid display="grid" xs>
                  {order.workerId && order.worker && (
                    <ConsultantPreview
                      hashCode={order.hashCode || ''}
                      isPaid={order.isPaid}
                      modules={order.expertise?.modules}
                      onOpenPreview={handleOpenPreview}
                      order={order}
                      primaryModule={order.expertise?.modules[0].id}
                      worker={order.worker}
                      workerMatching={order.workerMatching}
                    />
                  )}
                  {!isAgency && order.agencyId && !!order.agency && (
                    <AgencyCard
                      agency={order.agency}
                      workerMatching={order.workerMatching}
                      order={order}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {!isMinimized &&
              order.status === 'completed' &&
              [
                ERole.CUSTOMER_ADMIN,
                ERole.SELF_CUSTOMER,
                ERole.CUSTOMER,
                ERole.CUSTOMER_TEAM_ADMIN,
              ].includes(role?.name) && (
                <Grid container xs={12}>
                  <Grid xs>
                    <OrderFeedback order={order} />
                  </Grid>
                </Grid>
              )}
            {!isDraftOrder && (
              <>
                {!order.isQuick && (
                  <Grid container xs={12}>
                    <Grid xs mb={5}>
                      <WeeklyBillings
                        isConsultantOrAgency={isConsultant || isAgency}
                        order={order}
                        profile={(!isConsultant && order.worker) || order.customer}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid xs={12}>
                  <OrderHistory history={history} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid xs={12} lg={3} sx={{ marginBottom: { xs: 10.5, sm: 0 } }}>
            {!isMinimized && (
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={{ xs: 'column', sm: 'row', lg: 'column' }}
              >
                <ManageOrder order={order} />
                <Box mb={2}>
                  <IssueBlock />
                </Box>
              </Box>
            )}
            <div className="mt-4 w-100">
              {role?.id !== ERoleId.SELF_CUSTOMER &&
                role?.id !== ERoleId.CUSTOMER_ADMIN &&
                order.customer && (
                  <CustomerPreview company={order?.company} customer={order.customer} />
                )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <Typography>No Order</Typography>
      )}
      <ChangeTimeLineModal visible={showTimeline} close={() => setTimelineModal(false)} />
      {order && (
        <ChangeNameModal
          filter={EOrderFilter.CURRENT}
          order={order}
          visible={showRenameModal}
          close={() => setVisiblityRename(false)}
        />
      )}
    </>
  );
};

export default OrderPreview;
