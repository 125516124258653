import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createSearchParams } from 'react-router-dom';
import pick from 'lodash/fp/pick';
import { useStateMachine } from 'little-state-machine';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import updateAction from 'store/actions/updateAction';
import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';
import { IBasicConsultant } from 'store/types/consultant';
import { useMemo } from 'react';
import ROUTES from 'router/constants';

import {
  CustomerRegistrationStepper,
  ConsultantRegistrationStepper,
  AgencyRegistrationStepper,
  AgencyConsultantRegistrationStepper,
} from 'models/steppers';
import { ERole } from 'models/consts';

interface GoBackParams {
  step: undefined | string | number;
  isOrderCreationFlow: boolean;
}

const pickBy = pick([
  'workIds',
  'moduleIds',
  'primaryModule',
  'primaryWorkType',
  'productId',
  'purchaseOrderId',
]);

export const useGoBack = ({ step, isOrderCreationFlow }: GoBackParams) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { searchParams },
  } = useStateMachine({ updateAction });

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(user?.id)) as IBasicConsultant;

  const steps = useMemo(() => {
    switch (true) {
      case location.pathname?.includes(ROUTES.ORDER_CREATION):
        return CustomerRegistrationStepper;
      case location.pathname?.includes(ROUTES.AGENCY_CREATION):
        return AgencyRegistrationStepper;

      default:
        return consultant?.agencyId || user?.role?.name == ERole.INFLUENCER
          ? AgencyConsultantRegistrationStepper.filter(
              item =>
                item.number !== 4 || (!user.isVirtual && user?.role?.name !== ERole.INFLUENCER),
            )
          : ConsultantRegistrationStepper;
    }
  }, [consultant?.agencyId, location.pathname, user?.role?.name, user?.isVirtual]);

  const goBack = () => {
    if (step) {
      const currentStep = +step;

      if (currentStep === 1) {
        mobile && isOrderCreationFlow ? navigate('/') : navigate(-1);
      } else if (searchParams.isQuick) {
        navigate({
          pathname: '/order-flow/2',
          search: createSearchParams(pickBy(searchParams)).toString(),
        });
      } else {
        const { pathname } = new URL('.', window.origin + location.pathname);
        const destinationStep = Math.min(currentStep - 1, steps.length - 1);

        if (consultant) {
          navigate(
            {
              pathname: `${pathname}${destinationStep}`,
              search: location.search,
            },
            consultant?.agencyId ? { state: { isAgencyAddingUser: true } } : {},
          );
        } else {
          navigate(-1);
        }
      }
    }
  };

  return goBack;
};
