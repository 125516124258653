import React, { FunctionComponent } from 'react';
import { Row } from '../styled';

const EmptyRow: FunctionComponent = () => {
  return (
    <Row
      style={{
        padding: '50px 20px',
        color: '#5d5d5e',
        fontWeight: 400,
        justifyContent: 'center',
      }}
    >
      There are no items to display
    </Row>
  );
};

export default EmptyRow;
