import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface CustomSvgIconProps extends SvgIconProps {
  arrowColor?: string;
}

export default (props: CustomSvgIconProps) => {
  const { arrowColor = '#FFCA28', ...rest } = props;
  return (
    <SvgIcon {...props} sx={{ fill: 'transparent' }}>
      <path
        d="M6 9L12 15L18 9"
        stroke={arrowColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
