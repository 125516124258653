import { styled, Box } from '@mui/material';

const TableWrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
    borderColor: 'rgba(231, 231, 231, 0.7)',
    borderStyle: 'solid',
    borderWidth: '1px',
    marginTop: '24px',
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
    },
  }),
);

const TableGrid = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    width: '100%',
    alignItems: 'start',
    fontSize: '16px',
    color: '#1f1f1f',
    fontWeight: 400,
    lineHeight: 1,
    justifyContent: 'start',
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  }),
);

const RowHeader = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    width: '100%',
    backgroundColor: 'rgba(245, 245, 245, 1)',
    borderBottom: '1px solid rgba(231, 231, 231, 1)',
  }),
);

const HeaderCell = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    flex: 1,
    padding: '12px 24px',
    fontSize: '14px',
    color: '#5d5d5e',
    fontWeight: 400,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  }),
);

const MainHeaderCell = styled(HeaderCell)(({ theme }) =>
  theme.unstable_sx({
    flex: 2,
  }),
);

const Row = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderBottom: '1px solid rgba(231, 231, 231, 1)',
  }),
);

const DataRow = styled(Row)(({ theme }) =>
  theme.unstable_sx({
    '&:hover': {
      backgroundColor: '#FCFCFC',
    },
  }),
);

const Cell = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '26px 24px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  }),
);

const MainCell = styled(Cell)(({ theme }) =>
  theme.unstable_sx({
    fontWeight: 500,
    flex: 2,
  }),
);

export {
  TableWrapper,
  TableGrid,
  RowHeader,
  HeaderCell,
  Row,
  DataRow,
  Cell,
  MainCell,
  MainHeaderCell,
};
