import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import instance from 'store/middlewares/api';
import { EDraftOrder } from 'store/constants/draft-order';
import { IBasicUser } from 'store/types/user';

export const getDealOwners = createAsyncThunk<AxiosResponse<IBasicUser[]>>(
  EDraftOrder.dealOwners,
  () => instance.get('draft-orders/deal-owners'),
);

export const getDealCustomers = createAsyncThunk<AxiosResponse<IBasicUser[]>>(
  EDraftOrder.dealCustomers,
  () => instance.get('draft-orders/deal-customers'),
);
