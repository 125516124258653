import { styled, Box } from '@mui/material';

const DealsListTab = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    mx: 0,
    my: 3,
  }),
);

const DealsListTabContent = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: '4px',
    border: '1px solid rgba(231, 231, 231, 0.70)',
    background: 'var(--Base-white, #FFF)',
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05)',
  }),
);

export { DealsListTab, DealsListTabContent };
