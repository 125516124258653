import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const SeniorityLevelIcon: FunctionComponent<ISvgProps> = ({ className }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3335 16.3333V3H16.6668V16.3333H13.3335Z" fill="#F0B500" />
      <path d="M8.3335 16.3333V7.16667H11.6668V16.3333H8.3335Z" fill="#F0B500" />
      <path d="M3.3335 16.3333V10.5H6.66683V16.3333H3.3335Z" fill="#F0B500" />
    </svg>
  );
};
