import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';

import dayjs from 'utils/dayjs';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import InfoIcon from 'styles/icons/InfoIcon';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { IWorker } from 'store/types/consultant';
import { IBasicCustomer, IExtendUserCustomer } from 'store/types/customer';
import { IBasicOrders, IOrderBalance } from 'store/types/order';

import adjustWeeklyBillings from 'store/query/order/adjustWeeklyBillings';
import submitWeeklyBillings from 'store/query/order/submitWeeklyBillings';
import fetchOrderBalance from 'store/query/order/fetchOrderBalance';
import approveWeeklyBillings from 'store/query/order/approveWeeklyBillings';
import declineWeeklyBillings from 'store/query/order/declineWeeklyBillings';

import { getMeRoleSelector } from 'store/selectors/getUserSelector';
import { weeklyBillingByIndexSelector } from 'store/selectors/getOrderSelector';

import { BootstrapInput } from '../../CustomFields';
import Avatar from '../../Avatar';
import ModalWrapper from '../Modal';
import { useMediaQuery } from '@mui/material';

const StyledDetailKey = styled(Typography)({
  flexBasis: '40%',
  '& b': {
    color: '#5D5D5E',
  },
});
const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

interface IBillingModalProps {
  modal: any;
  close: () => void;
  isConsultantOrAgency: boolean;
  orderBalance?: IOrderBalance;
  profile: IWorker | IExtendUserCustomer | IBasicCustomer | undefined;
}
const BillingModal: FunctionComponent<IBillingModalProps> = ({
  modal,
  close,
  isConsultantOrAgency,
  orderBalance,
  profile,
}) => {
  const dispatch = useAppDispatch();

  const role = useAppSelector(getMeRoleSelector);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

  const weeklyBilling = useAppSelector(weeklyBillingByIndexSelector(modal.orderId, modal.index));

  const [isDisputeEnabled, setIsDisputeEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const billAdjustedReason = useWatch({
    control,
    name: 'billAdjustedReason',
  });

  const handleClose = () => {
    setValue('hours', '');
    close && close();
    setIsDisputeEnabled(false);
  };

  const handleApprove = () => {
    if (weeklyBilling) {
      setIsLoading(true);
      dispatch(
        approveWeeklyBillings({
          orderId: weeklyBilling?.orderId,
          id: weeklyBilling?.id,
          index: modal.index,
        }),
      )
        .unwrap()
        .then(handleClose)
        .finally(() => setIsLoading(false));
    }
  };

  const handleDispute = () => {
    setIsDisputeEnabled(true);
  };

  const confirmDispute = () => {
    if (weeklyBilling) {
      setIsLoading(true);
      dispatch(
        declineWeeklyBillings({
          id: weeklyBilling.id,
          index: modal.index,
          orderId: weeklyBilling.orderId,
          text: billAdjustedReason || '',
        }),
      )
        .unwrap()
        .then(handleClose)
        .finally(() => setIsLoading(false));
    }
  };
  const onSubmit: SubmitHandler<any> = ({ hours, notes }) => {
    if (hours >= 0 && weeklyBilling) {
      setIsLoading(true);
      const billingDetails = {
        orderId: weeklyBilling.orderId,
        id: weeklyBilling.id,
        index: modal.index,
        hours: parseInt(hours),
        notes: notes,
      };
      const action: any =
        weeklyBilling.status === 'disputed'
          ? adjustWeeklyBillings(billingDetails)
          : submitWeeklyBillings(billingDetails);

      dispatch(action)
        .unwrap()
        .then(() => {
          dispatch(fetchOrderBalance({ id: modal.orderId, role, isPurchase: modal.isPurchase }))
            .unwrap()
            .then(handleClose)
            .finally(() => setIsLoading(false));
        })
        .finally(() => setIsLoading(false));
    }
  };

  const billingMax = useMemo(() => {
    if (orderBalance && weeklyBilling) {
      return orderBalance.payedHours - orderBalance.billedHours + weeklyBilling.hours;
    }
    return 0;
  }, [orderBalance, weeklyBilling]);

  useEffect(() => {
    if (weeklyBilling) {
      setValue('notes', weeklyBilling?.notes || '');
      setValue('billAdjustedReason', weeklyBilling?.disputeText || '');
    }
  }, [weeklyBilling, setValue]);

  return (
    <ModalWrapper
      dialogProps={{
        open: !!modal.open,
        onClose: handleClose,
      }}
      title={{
        title: `Weekly Billings ${(modal.type === 'review' && 'Review') || (weeklyBilling && weeklyBilling.status === 'disputed' && isConsultantOrAgency && 'Adjust') || 'Detail'}`,
      }}
    >
      {weeklyBilling && (
        <>
          <DialogContent data-test="weekly-billings-modal">
            <Box>
              {profile && (
                <Box display="flex" alignItems="center" mb={4}>
                  <Avatar
                    size="medium"
                    image={(profile as IWorker)?.consultant?.avatarUrl}
                    firstName={profile?.firstName || ''}
                    lastName={profile?.lastName || ''}
                  />
                  <Typography sx={{ ml: 2 }} variant="body1">
                    {profile?.firstName || ''} {profile?.lastName || ''}
                  </Typography>
                </Box>
              )}
              <Box display="flex" alignItems="center" mb={1}>
                <StyledDetailKey variant="body2">Week</StyledDetailKey>
                <Typography component="div">
                  <b>Week {weeklyBilling.week}</b>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <StyledDetailKey variant="body2">Start Date</StyledDetailKey>
                <Typography component="div" data-test="weekly-modal-start-date">
                  <strong>{dayjs.utc(weeklyBilling.startDate).format('MM/DD/YYYY')}</strong>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <StyledDetailKey variant="body2">End Date</StyledDetailKey>
                <Typography component="div" data-test="weekly-modal-end-date">
                  <strong>{dayjs.utc(weeklyBilling.endDate).format('MM/DD/YYYY')}</strong>
                </Typography>
              </Box>
              {((isConsultantOrAgency && weeklyBilling.status === 'pending') ||
                weeklyBilling.status === 'disputed' ||
                weeklyBilling.status === 'adjusted') && (
                <>
                  <Box display="flex" alignItems="center" mb={1}>
                    <StyledDetailKey variant="body2">Weekly Needs</StyledDetailKey>
                    <Typography component="div">
                      <b data-test="weekly-modal-hours">{weeklyBilling.hours}</b>{' '}
                      <b>hours / week</b>
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <StyledDetailKey variant="body2">Billed To Date</StyledDetailKey>
                    <Typography component="div">
                      <b>{orderBalance?.approvedHours} hours</b>
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <StyledDetailKey variant="body2">Billing Maximum</StyledDetailKey>
                    <Typography component="div">
                      <b>{billingMax} hours</b>
                    </Typography>
                  </Box>
                </>
              )}
              <Box display="flex" alignItems="center" mb={1}>
                <StyledDetailKey variant="body2">Hours</StyledDetailKey>
                <Typography component="div">
                  <b>{weeklyBilling.hours} hours</b>
                </Typography>
              </Box>
              {(weeklyBilling.status === 'disputed' || weeklyBilling.status === 'adjusted') &&
                isConsultantOrAgency && (
                  <>
                    <Box display="flex" alignItems="center" mb={1}>
                      {weeklyBilling?.order?.timeLine && (
                        <>
                          <StyledDetailKey variant="body2">Original Billing</StyledDetailKey>
                          <Typography component="div">
                            <b>{weeklyBilling.order.timeLine.availabilityWeek} h</b>
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <StyledDetailKey variant="body2">Disputed On</StyledDetailKey>
                      <Typography component="div">
                        <b>{dayjs(modal?.updatedAt).format('DD MMM, YYYY')}</b>
                      </Typography>
                    </Box>
                  </>
                )}
              <Box display="flex" alignItems="center" mb={1}>
                <StyledDetailKey variant="body2">
                  <b>Status</b>
                </StyledDetailKey>
                <Typography component="div" sx={{ textTransform: 'capitalize' }}>
                  <b>{weeklyBilling.status}</b>
                </Typography>
              </Box>
              {weeklyBilling &&
                (weeklyBilling.status === 'approved' ||
                  weeklyBilling.status === 'submitted' ||
                  weeklyBilling.status === 'adjusted' ||
                  (!isConsultantOrAgency && weeklyBilling.status === 'disputed')) && (
                  <Box display="flex" mb={1}>
                    <StyledDetailKey variant="body2">
                      <b>Notes</b>
                    </StyledDetailKey>
                    <Box
                      alignItems="center"
                      sx={{
                        height: 100,
                        backgroundColor: '#F5F5F5',
                        width: '63%',
                        overflow: 'auto',
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                      }}
                    >
                      <Typography sx={{ padding: 1 }}>{weeklyBilling?.notes || ''}</Typography>
                    </Box>
                  </Box>
                )}
              {weeklyBilling.status === 'disputed' && (
                <Box alignItems="center">
                  <StyledDetailKey variant="body2">Customer Reason</StyledDetailKey>
                  <Box
                    alignItems="center"
                    sx={{
                      minHeight: 150,
                      backgroundColor: '#F5F5F5',
                      overflow: 'auto',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word',
                    }}
                  >
                    <Typography sx={{ padding: 1 }}>
                      {weeklyBilling && weeklyBilling.disputeText}
                    </Typography>
                  </Box>
                </Box>
              )}
              {isConsultantOrAgency &&
                weeklyBilling &&
                (weeklyBilling.status === 'disputed' || weeklyBilling.status === 'pending') && (
                  <Box
                    display="flex"
                    alignItems={isiPadOrMobile ? 'flex-start' : 'center'}
                    mb={isiPadOrMobile ? 2 : 1}
                    mt={4}
                    flexDirection={isiPadOrMobile ? 'column' : 'row'}
                  >
                    <StyledDetailKey
                      sx={{
                        fontSize: '18px',
                        maxWidth: isiPadOrMobile ? '100%' : '170px',
                      }}
                      mb={isiPadOrMobile ? 1 : undefined}
                      variant="body2"
                    >
                      {weeklyBilling.status === 'disputed'
                        ? 'Adjusted billing'
                        : 'This week’s billing'}
                    </StyledDetailKey>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ width: mobile ? '100%' : undefined }}
                    >
                      <Controller
                        control={control}
                        name="hours"
                        render={({ field: { ref, ...field } }) => (
                          <BootstrapInput
                            {...field}
                            inputRef={ref}
                            onChange={(e: any) => {
                              if (!e.target.value || Number(e.target.value) >= 0) {
                                field.onChange(parseInt(e.target.value));
                                setValue('hours', e.target.value);
                              }
                            }}
                            error={!!errors.hours}
                            helperText={errors.hours?.message || 'Incorrect data'}
                            placeholder="Enter your hours"
                            sx={{ width: '100%', maxWidth: '100%' }}
                            type="number"
                            dataTest="weekly-modal-hours-field"
                          />
                        )}
                        rules={{
                          required: 'This field is required',
                          validate: {
                            isInteger: value => {
                              if (!Number.isInteger(Number(value))) {
                                return 'Only whole numbers are allowed';
                              }
                            },
                            maxValue: value => {
                              if (orderBalance && parseInt(value) > billingMax) {
                                return 'Wrong number of hours';
                              }
                            },
                            minValue: value => {
                              if (value < 0) {
                                return 'Wrong number of hours';
                              }
                            },
                          },
                        }}
                      />
                      <Typography ml={1}>
                        {' '}
                        <b>hours</b>
                      </Typography>
                    </Box>
                  </Box>
                )}
              {isConsultantOrAgency &&
                weeklyBilling &&
                (weeklyBilling.status === 'disputed' || weeklyBilling.status === 'pending') && (
                  <Box
                    display="flex"
                    alignItems={isiPadOrMobile ? 'flex-start' : 'center'}
                    mb={1}
                    flexDirection={isiPadOrMobile ? 'column' : 'row'}
                  >
                    <StyledDetailKey
                      sx={{
                        fontSize: '18px',
                      }}
                      mb={isiPadOrMobile ? 1 : undefined}
                      variant="body2"
                    >
                      Notes
                    </StyledDetailKey>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="notes"
                      rules={{
                        maxLength: {
                          value: 1000,
                          message: 'Text cannot exceed 1000 characters',
                        },
                      }}
                      render={({ field: { ref, ...field }, fieldState: { error } }) => {
                        return (
                          <Box display="flex" flexDirection="column" width="100%">
                            <BootstrapInput
                              {...field}
                              inputRef={ref}
                              onChange={(e: any) => {
                                field.onChange(e.target.value);
                              }}
                              sx={{ paddingRight: '0px' }}
                              type="text"
                              multiline
                              rows={2}
                            />
                            {error && <Typography color="error">{error.message}</Typography>}
                            <Typography variant="body2" color="textSecondary">
                              {field.value.length}/1000
                            </Typography>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                )}
            </Box>
            {isDisputeEnabled && !isConsultantOrAgency && (
              <>
                <Box alignItems="center">
                  <Typography
                    component="div"
                    variant="inherit"
                    color="textPrimary"
                    sx={{ my: 1, fontSize: '1.125rem' }}
                  >
                    Why should this billing be adjusted?
                  </Typography>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Controller
                      control={control}
                      name="billAdjustedReason"
                      rules={{
                        maxLength: {
                          value: 1000,
                          message: 'Text cannot exceed 1000 characters',
                        },
                      }}
                      render={({ field: { ref, ...field }, fieldState: { error } }) => (
                        <Box display="flex" flexDirection="column" width="100%">
                          <BootstrapInput
                            {...field}
                            inputRef={ref}
                            onChange={(e: any) => {
                              field.onChange(e.target.value);
                            }}
                            type="text"
                            multiline
                            sx={{ minHeight: 150, textAlign: 'start', alignItems: 'flex-start' }}
                          />
                          {error && <Typography color="error">{error.message}</Typography>}
                          <Typography variant="body2" color="textSecondary">
                            {field.value.length}/1000
                          </Typography>
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', my: 3, backgroundColor: '#F5F5F5' }}
                >
                  <Box mt={2} ml={1}>
                    <StyledInfoIcon />
                  </Box>
                  <Typography component="div" m={2} ml={1} fontSize="0.625rem">
                    Clicking on Dispute starts a 7 Day period during which this weekly billing
                    should be adjusted and marked as resolved. If this is not resolved within 7 days
                    the whole order will be terminated. We strongly suggest communication with the
                    specialist during this period.
                  </Typography>
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {(weeklyBilling.status === 'pending' || weeklyBilling.status === 'disputed') &&
              isConsultantOrAgency && (
                <LoadingButton
                  color="secondary"
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid || isSubmitting || isLoading}
                  data-test="submit-weekly-billing"
                >
                  Submit
                </LoadingButton>
              )}
            {(weeklyBilling.status === 'submitted' || weeklyBilling.status === 'adjusted') &&
              !isConsultantOrAgency &&
              !isDisputeEnabled && (
                <>
                  <Button
                    disabled={isLoading}
                    color="secondary"
                    fullWidth
                    variant="outlined"
                    onClick={() => handleDispute()}
                  >
                    Dispute
                  </Button>
                  <Button
                    disabled={isLoading}
                    color="secondary"
                    fullWidth
                    variant="contained"
                    onClick={() => handleApprove()}
                    data-test="approve-weekly-billing"
                  >
                    Approve
                  </Button>
                </>
              )}
            {isDisputeEnabled && !isConsultantOrAgency && (
              <Button
                disabled={Object.keys(errors).length > 0 || isLoading}
                color="secondary"
                fullWidth
                variant="contained"
                onClick={() => confirmDispute()}
              >
                Dispute
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </ModalWrapper>
  );
};

export default BillingModal;
