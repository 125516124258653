import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  UserCell,
  UserAvatar,
  UserName,
  UserAvatarWithText,
  DropdownMenu,
  DropdownItem,
} from './styled';
import { IBasicUser } from 'store/types/user';
import { Box } from '@mui/material';
import ArrowUpColored from 'styles/icons/ArrowUpColored';

interface UserRowProps {
  isEditable?: boolean;
  user?: IBasicUser;
  usersData?: any;
  avatarSX?: any;
  namesx?: any;
  cellsx?: any;
  nameDisplayLength?: number;
  onAssignUser?: (userId: number | null) => void;
}

const getFullName = (firstName: string, lastName?: string, nameDisplayLength?: number) => {
  const fullName = `${firstName} ${lastName || ''}`.trim();

  return fullName.length > (nameDisplayLength ? nameDisplayLength : 14)
    ? `${fullName.slice(0, nameDisplayLength)}...`
    : fullName;
};

const UserRow: FunctionComponent<UserRowProps> = ({
  user,
  usersData,
  avatarSX,
  namesx,
  cellsx,
  onAssignUser,
  isEditable = true,
  nameDisplayLength,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const userCellRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const unAssignedAvatarUrl =
    'https://cdn.builder.io/api/v1/image/assets/TEMP/b3676fdfc8240b864588621003595243d7eee27eb3380c3e9f86cec58f17e400?placeholderIfAbsent=true&apiKey=f8bba43aec4448c4a76e30c7a4a68ee7';

  const getInitials = () => {
    if (!user?.firstName) return '';
    return user.lastName
      ? `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`
      : user.firstName.replace(/\./g, '');
  };

  // Toggle dropdown visibility
  const handleRowClick = () => {
    if (isEditable) {
      setIsDropdownVisible((prev: any) => !prev);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        userCellRef.current &&
        !userCellRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleUserSelect = (userId: number | undefined | null, event: React.MouseEvent) => {
    event.stopPropagation();
    if (onAssignUser) {
      if (userId) {
        onAssignUser(userId);
      } else if (!userId && user) {
        onAssignUser(null);
      }
    }
    setIsDropdownVisible(false);
  };

  return (
    <UserCell
      ref={userCellRef}
      onClick={handleRowClick}
      sx={{
        border: isDropdownVisible ? '1px solid #ccc' : undefined,
        backgroundColor: isDropdownVisible ? '#F5F5F5' : undefined,
        padding: isDropdownVisible ? '6px 14px' : '16px 24px',
        marginX: isDropdownVisible ? '9px' : undefined,
        position: 'relative',
        ...cellsx,
      }}
    >
      {user?.consultant?.avatarUrl ? (
        <UserAvatar src={user.consultant.avatarUrl} alt="User Avatar" sx={avatarSX} />
      ) : user ? (
        <UserAvatarWithText>{getInitials().toUpperCase()}</UserAvatarWithText>
      ) : (
        <UserAvatar src={unAssignedAvatarUrl} alt="Default Avatar" sx={avatarSX} />
      )}

      <UserName sx={namesx}>
        {user?.firstName
          ? getFullName(user.firstName, user.lastName, nameDisplayLength)
          : user?.initials || 'Unassigned'}
      </UserName>
      {isDropdownVisible && (
        <Box>
          <ArrowUpColored arrowColor="#5D5D5E" />
        </Box>
      )}

      {isDropdownVisible && (
        <DropdownMenu
          ref={dropdownRef}
          sx={{
            top: '100%',
            left: 0,
            zIndex: 1000,
          }}
        >
          <Box
            onClick={event => handleUserSelect(undefined, event)}
            sx={{
              padding: '16px 18px 12px',
              color: '#5D5D5E',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '20px',
              ...(user ? { '&:hover': { backgroundColor: '#f5f5f5' } } : {}),
            }}
          >
            {user ? 'Unassign' : 'Assign deal to:'}
          </Box>
          {usersData?.map((user: any) => (
            <DropdownItem
              sx={{
                padding: '13px 18px',
              }}
              key={user.id}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                handleUserSelect(user.id, event)
              }
            >
              {`${user.firstName} ${user.lastName}`}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </UserCell>
  );
};

export default UserRow;
