import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 12 12">
    <path
      d="M12.0005 10.5011C12.0005 10.8486 11.8769 11.1462 11.6296 11.3938C11.382 11.641 11.0844 11.7646 10.737 11.7646L1.89241 11.7646C1.54495 11.7646 1.24739 11.641 0.999745 11.3938C0.752519 11.1462 0.628906 10.8486 0.628906 10.5011L0.628906 6.71062C0.628906 6.53162 0.689344 6.38148 0.810219 6.26018C0.931516 6.13931 1.08166 6.07887 1.26066 6.07887C1.43966 6.07887 1.58959 6.13931 1.71047 6.26018C1.83176 6.38148 1.89241 6.53162 1.89241 6.71062L1.89241 10.5011L10.737 10.5011L10.737 6.71062C10.737 6.53162 10.7976 6.38148 10.9189 6.26018C11.0398 6.1393 11.1897 6.07887 11.3687 6.07887C11.5477 6.07887 11.6976 6.1393 11.8185 6.26018C11.9398 6.38148 12.0005 6.53162 12.0005 6.71062L12.0005 10.5011Z"
      fill="#5D5D5E"
    />
    <path
      d="M3.35429 4.65697C3.49117 4.54115 3.64659 4.48324 3.82053 4.48324C3.99405 4.48324 4.13872 4.54115 4.25454 4.65697L5.42329 5.82572L5.42329 1.29289C5.42329 1.11389 5.48394 0.963953 5.60523 0.843078C5.72611 0.721781 5.87604 0.661133 6.05504 0.661133C6.23404 0.661133 6.38418 0.721781 6.50548 0.843078C6.62636 0.963953 6.68679 1.11389 6.68679 1.29289L6.68679 5.82572L7.85554 4.65697C7.98189 4.53062 8.13183 4.46745 8.30535 4.46745C8.47929 4.46745 8.62944 4.53062 8.75579 4.65697C8.88214 4.77279 8.94531 4.91768 8.94531 5.09162C8.94531 5.26514 8.8874 5.40981 8.77158 5.52563L6.49727 7.79995C6.43409 7.86312 6.36565 7.90798 6.29195 7.93451C6.21824 7.96062 6.13927 7.97368 6.05504 7.97368C5.97081 7.97368 5.89184 7.96062 5.81813 7.93451C5.74443 7.90798 5.67599 7.86312 5.61281 7.79995L3.3385 5.52563C3.20162 5.38875 3.14118 5.23882 3.15719 5.07583C3.17277 4.91241 3.23847 4.77279 3.35429 4.65697Z"
      fill="#5D5D5E"
    />
  </SvgIcon>
);
