import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { Controller, useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import {
  categoryProductsSelector,
  expertiseLevelsSelector,
  modulesSelector,
  productPricesSelector,
  workTypesSelector,
} from 'store/selectors/getCommonSelector';
import { EMAIL_REGEX, ERole } from 'models/consts';

import { Autocomplete, Grid, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@mui/material';

import ProductsModulesWork from 'components/CustomFields/ProductsModulesWork';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { BootstrapInput, RadioGroupInput } from 'components/CustomFields';
import { Select } from 'components/CustomFields';

import fetchExpertiseLevels from 'store/query/common/fetchExpertiseLevels';
import fetchProductPrices from 'store/query/common/fetchProductPrices';
import { TAXES } from 'store/constants';

import checkCompanyByName from 'store/query/company/checkCompanyByName';
import getCustomerList from 'store/query/user/getCustomerList';
import { getMeSelector } from 'store/selectors/getUserSelector';

interface ICuratedOrderScopeProps {
  isEdit: boolean;
  disableAllFields?: boolean;
  isOrderEdit: any;
}

const CuratedOrdersScope: FunctionComponent<ICuratedOrderScopeProps> = ({
  isEdit,
  disableAllFields,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [customerInfoType, setCustomerInfoType] = useState<string>();
  const [customerData, setCustomerData] = useState<any[]>([]);

  const {
    clearErrors,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();
  const {
    product,
    totalHours,
    searchEndDate,
    searchStartDate,
    scheduleType,
    customerRate,
    isCustomerRateDefault,
    specialistRate,
    isSpecialistRateDefault,
    levelId,
    customerType,
  } = watch();

  const defaultProductRef = useRef(null);

  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);

  const seniorities = useAppSelector(expertiseLevelsSelector);
  const productPrices = useAppSelector(productPricesSelector(product?.id));
  const user = useAppSelector(getMeSelector);

  useEffect(() => {
    if (customerType) {
      setCustomerInfoType(customerType);
    }
  }, [customerType]);

  useEffect(() => {
    if (!categoriesWithProductsList?.length || !modulesList?.length || !worksList.length) {
      dispatch(fetchProductsWithModulesWorks());
    }
    dispatch(fetchExpertiseLevels());
  }, [categoriesWithProductsList, modulesList, worksList, dispatch]);

  useEffect(() => {
    if (product) {
      dispatch(fetchProductPrices(product?.id));
    }
  }, [product, dispatch]);

  useEffect(() => {
    if (!productPrices) return;
    const updateRates = (priceData: any) => {
      const coefficient = (100 - TAXES) / 100;
      const specialistPrice = Math.round((priceData.stripePriceCents / 100) * coefficient);
      setValue('specialistRate', specialistPrice);
    };
    const defaultPriceData = productPrices.find(price => price.levelId === 2);
    if (defaultPriceData) {
      updateRates(defaultPriceData);
    }
    if (levelId) {
      const levelSpecificPriceData = productPrices.find(price => price.levelId === levelId);
      if (levelSpecificPriceData) {
        updateRates(levelSpecificPriceData);
      }
    }
  }, [productPrices, levelId, setValue, isEdit]);

  useEffect(() => {
    if (product) {
      setValue('levelId', 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, setValue, isEdit]);

  useEffect(() => {
    if (isInitialLoad && product) {
      defaultProductRef.current = product;
      if (isSpecialistRateDefault && product) {
        if (!productPrices) return;
        const updateRates = (priceData: any) => {
          const coefficient = (100 - TAXES) / 100;
          const specialistPrice = Math.round((priceData.stripePriceCents / 100) * coefficient);
          if (isSpecialistRateDefault) {
            setValue('specialistRate', specialistPrice);
          }
        };
        const defaultPriceData = productPrices.find(price => price.levelId === 2);
        if (defaultPriceData) {
          updateRates(defaultPriceData);
        }
        if (levelId) {
          const levelSpecificPriceData = productPrices.find(price => price.levelId === levelId);
          if (levelSpecificPriceData) {
            updateRates(levelSpecificPriceData);
          }
        }
      }
      setIsInitialLoad(false);
      return;
    }
    if (product && product !== defaultProductRef.current) {
      if (!productPrices) return;
      const updateRates = (priceData: any) => {
        const coefficient = (100 - TAXES) / 100;
        const specialistPrice = Math.round((priceData.stripePriceCents / 100) * coefficient);
        setValue('specialistRate', specialistPrice);
        setValue('isSpecialistRateDefault', true);
      };
      const defaultPriceData = productPrices.find(price => price.levelId === 2);
      if (defaultPriceData) {
        updateRates(defaultPriceData);
      }
      if (levelId) {
        const levelSpecificPriceData = productPrices.find(price => price.levelId === levelId);
        if (levelSpecificPriceData) {
          updateRates(levelSpecificPriceData);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, productPrices]);

  useEffect(() => {
    dispatch(getCustomerList({ offset: 0, type: 'asc' })).then((data: any) => {
      setCustomerData(data?.payload?.customers || []);
    });
  }, [dispatch]);

  const debounced = debounce((value: any, searchField: string) => {
    if (searchField === 'customer') {
      dispatch(
        getCustomerList({
          offset: 0,
          type: 'asc',
          search: value,
        }),
      ).then((data: any) => {
        setCustomerData(data?.payload?.customers || []);
      });
    }
  }, 500);

  const searchTerm = (value: string, searchField: string) => {
    debounced(value, searchField);
  };

  const checkIsCompanyNotExist = (companyName: string | undefined) => {
    if (companyName) {
      return dispatch(checkCompanyByName({ name: companyName, availableForCustomer: true }))
        .unwrap()
        .then(({ isHave }) => {
          if (isHave) {
            setValue('companyName', undefined);
          }
          return !isHave;
        });
    }
    return true;
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ mr: 2, mt: 1, mb: 2, display: 'flex', flexDirection: 'column', paddingRight: 1 }}
    >
      <Grid width={'100%'} maxWidth={'97%'} ml={2}>
        <ProductsModulesWork disableAllFields={user.role.name === ERole.SALES} />
      </Grid>
      <Grid
        width={'100%'}
        maxWidth={'97%'}
        ml={2}
        display={'flex'}
        flexDirection={isiPadOrMobile ? 'column' : 'row'}
      >
        <Grid
          xs={12}
          md={6}
          lg={4}
          mr={!isiPadOrMobile ? 1 : undefined}
          mt={isiPadOrMobile ? -1 : undefined}
        >
          <InputLabel>Availability Type</InputLabel>
          <Controller
            control={control}
            name="availabilityType"
            defaultValue={undefined}
            render={({ field }) => (
              <CustomAutocomplete
                disabled={disableAllFields}
                inputProps={{
                  readOnly: true,
                  error: !!errors.modules,
                  helperText: (errors.modules as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                onOpen={() => setValue('availabilityType', '')}
                field={field}
                handleChange={event => setValue('availabilityType', event)}
                // isMainTag
                options={[
                  { id: 1, name: 'Fully' },
                  { id: 2, name: 'Partially' },
                  { id: 3, name: 'Outside' },
                ]}
                placeholder="Select Availability Type"
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6} lg={4} mt={isiPadOrMobile ? 2 : undefined}>
          <InputLabel>Seniority Level</InputLabel>
          <Controller
            control={control}
            name="levelId"
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                disabled={disableAllFields}
                inputRef={ref}
                label="Select Level"
                options={seniorities || []}
                labelMenuItem="name"
                arrowIconSx={{ right: '10px' }}
                isCuratedOrder={true}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        mt={isiPadOrMobile ? 1 : 3}
        width={'100%'}
        maxWidth={'97%'}
        ml={2}
        display={'flex'}
        flexDirection={'column'}
      >
        <InputLabel>Product Description:</InputLabel>
        <Controller
          control={control}
          name="additionalRequirements"
          render={({ field: { ref, ...field } }) => (
            <BootstrapInput
              {...field}
              type="text"
              multiline={true}
              rows={1}
              disabled={disableAllFields}
              placeholder="Interview Required: Yes
                Number of Specialists: 1
                Additional Background Check: Details
                Customer availability requirements: full time"
              sx={{
                width: '100%',
              }}
              onChange={(event: any) => {
                const value = event.target.value;
                field.onChange(value);
                setValue('additionalRequirements', value);
              }}
              autoComplete="off"
            />
          )}
        />
      </Grid>

      <Grid
        mt={isiPadOrMobile ? 1 : 3}
        width={'100%'}
        maxWidth={'97%'}
        ml={2}
        display={'flex'}
        flexDirection={'column'}
      >
        <Typography
          sx={{
            textTransform: 'capitalize',
            fontSize: isiPadOrMobile ? '25px' : '30px',
            fontWeight: 500,
          }}
        >
          Customer Info
        </Typography>

        <Grid xs={12} md={12} lg={6} mb={isiPadOrMobile ? -2 : -4}>
          <Controller
            control={control}
            name="customerType"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <RadioGroupInput
                {...field}
                ariaLabelledby="customer-type"
                values={['existing_customer', 'new_customer']}
                isHorizontal
                labels={{
                  ['existing_customer']: {
                    subLabel1: 'Existing Customer',
                    subLabel2: `Order for my existing customer`,
                  },
                  ['new_customer']: {
                    subLabel1: 'New Customer',
                    subLabel2: `I have a lead with new customer`,
                  },
                }}
                defaultValue={customerType}
                onChange={event => {
                  field.onChange(event);
                  const selectedType = event.target.value;
                  setCustomerInfoType(selectedType);
                  setValue('customerType', selectedType);
                  if (customerType === 'existing_customer') {
                    setValue('companyName', undefined);
                    setValue('customerEmail', undefined);
                  }
                }}
              />
            )}
          />
        </Grid>
        {customerType === 'new_customer' && (
          <>
            <Grid xs={12} md={12} lg={6}>
              <InputLabel
                sx={{
                  width: 120,
                  marginTop: isiPadOrMobile ? 0 : 1,
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                Customer Email
              </InputLabel>
              <Controller
                control={control}
                name="customerEmail"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.email}
                    placeholder="Enter Customer email"
                    helperText={errors.email?.message || 'Incorrect data'}
                    type="email"
                    onChange={(event: any) => {
                      setValue('customerEmail', event.target.value);
                    }}
                    disabled={!customerInfoType || user.role.name === ERole.SALES}
                    sx={{ width: '100%' }}
                  />
                )}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Invalid email',
                  },
                }}
              />
            </Grid>
            <Grid xs={12} md={12} lg={6}>
              <InputLabel
                sx={{
                  width: 120,
                  marginTop: isiPadOrMobile ? 0 : 1,
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                Company Name
              </InputLabel>
              <Controller
                control={control}
                name="companyName"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    placeholder="Type a company name"
                    disabled={user.role.name === ERole.SALES}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      field.onChange(value);
                      setValue('companyName', value);
                    }}
                    error={!!errors.companyName}
                    helperText={errors.companyName?.message || 'Incorrect data'}
                  />
                )}
                rules={{
                  maxLength: {
                    message: 'The max count of characters is 100',
                    value: 100,
                  },
                  validate: {
                    checkCompanyExist: async (companyName?: string) =>
                      (await checkIsCompanyNotExist(companyName)) ||
                      'This Company name already exists',
                  },
                }}
              />
            </Grid>
          </>
        )}
        {customerType === 'existing_customer' && (
          <Grid xs={12} md={12} lg={6}>
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Customer
            </InputLabel>
            <Controller
              control={control}
              name="customer"
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  onInputChange={(_, value) => {
                    searchTerm(value, 'customer');
                  }}
                  onChange={(_, newValue) => {
                    setValue('customer', newValue);
                    field.onChange(newValue);
                  }}
                  options={customerData}
                  disabled={user.role.name === ERole.SALES}
                  getOptionLabel={option => {
                    return option
                      ? `${option.firstName || ''} ${option.lastName || option.email} ${
                          option?.customer?.companies[0]?.name
                            ? ` - ${option.customer.companies[0].name}`
                            : ''
                        }`
                      : '';
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={params => (
                    <BootstrapInput {...params} placeholder="Select an option" />
                  )}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CuratedOrdersScope;
