import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, Box, Grid, InputAdornment, Typography } from '@mui/material';

import { SegmentToggle } from 'components/SegmentToggle';
import { BootstrapInput, Select } from 'components/CustomFields';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import getConsultantList from 'store/query/user/getConsultantList';
import { debounce } from 'lodash';
import getCustomerList from 'store/query/user/getCustomerList';
import checkCompanyByName from 'store/query/company/checkCompanyByName';
import { EMAIL_REGEX, ERoleId } from 'models/consts';
import { getDraftOrderDetailsSelector } from 'store/selectors/getDraftOrdersSelector';
import { DealsListFilterChild } from 'views/Deals/DealsList/DealsListFilter/styled';

const CustomerSection: FunctionComponent = () => {
  const { items, loading, loaded } = useAppSelector(getDraftOrderDetailsSelector);

  const dispatch = useAppDispatch();
  const [specialistData, setSpecialistData] = useState<any[]>([]);
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [customerType, setCustomerType] = useState<string>('Select Existing Customer');

  const {
    control,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useFormContext();

  const { customer, specialistRate, payoutTerm } = watch();

  useEffect(() => {
    items?.customerId
      ? setCustomerType('Select Existing Customer')
      : setCustomerType('Create a New Customer');
  }, [items]);

  useEffect(() => {
    dispatch(getConsultantList({ withAgency: 'false', isActive: true })).then((data: any) => {
      setSpecialistData(data?.payload?.consultants || []);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getCustomerList({
        offset: 0,
        type: 'asc',
        isActive: true,
        roleId: [ERoleId.CUSTOMER_TEAM_ADMIN, ERoleId.CUSTOMER_ADMIN],
      }),
    ).then((data: any) => {
      setCustomerData(data?.payload?.customers || []);
    });
  }, [dispatch]);

  const debounced = debounce((value: any, searchField: string) => {
    if (searchField === 'specialist') {
      dispatch(getConsultantList({ withAgency: 'false', isActive: true, search: value })).then(
        (data: any) => {
          setSpecialistData(data?.payload?.consultants || []);
        },
      );
    }
    if (searchField === 'customer') {
      dispatch(
        getCustomerList({
          offset: 0,
          type: 'asc',
          search: value,
          isActive: true,
          roleId: [ERoleId.CUSTOMER_TEAM_ADMIN, ERoleId.CUSTOMER_ADMIN],
        }),
      ).then((data: any) => {
        setCustomerData(data?.payload?.customers || []);
      });
    }
  }, 500);

  const searchTerm = (value: string, searchField: string) => {
    debounced(value, searchField);
  };

  const onCustomerTypeChange = (value: string) => {
    setCustomerType(value);
    setValue('customerType', value);
  };

  return (
    <Box paddingLeft={'24px'} paddingRight={'24px'} paddingBottom={'24px'}>
      <DealsListFilterChild>
        <SegmentToggle
          defaultSelected={customer ? 'Select Existing Customer' : 'Create a New Customer'}
          segments={['Select Existing Customer', 'Create a New Customer']}
          onSegmentChange={(value: string) => onCustomerTypeChange(value)}
        />
      </DealsListFilterChild>

      {customerType === 'Select Existing Customer' && (
        <Grid mt={'16px'}>
          <Typography fontSize={'16px'} fontWeight={500}>
            Customer
          </Typography>
          <Controller
            control={control}
            name="customer"
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                {...field}
                // disabled={items?.status === 'draft'}
                onInputChange={(_, value) => {
                  searchTerm(value, 'customer');
                }}
                onChange={(_, newValue) => {
                  setValue('customer', newValue);
                  field.onChange(newValue);
                }}
                options={customerData}
                getOptionLabel={option =>
                  option
                    ? `${option.firstName || ''} ${option.lastName || ''} - ${option.email}`
                    : ''
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => <BootstrapInput {...params} placeholder="Select Customer" />}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box mb={2}>
                      <Typography fontSize={'18px'} fontWeight={400}>
                        {option.firstName ? `${option.firstName} ${option.lastName}` : 'N/A'}
                      </Typography>
                      <Typography fontSize={'16px'} fontWeight={400}>
                        {option.email} • {option?.customer?.companies[0]?.name || ''}
                      </Typography>
                    </Box>
                  </li>
                )}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Grid>
      )}
      {customerType === 'Create a New Customer' && (
        <Grid mt={'16px'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Grid width={'402.5px'}>
            <Typography fontSize={'16px'} fontWeight={500}>
              Company Name
            </Typography>
            <Controller
              control={control}
              name="companyName"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  // disabled={items?.status === 'draft'}
                  inputRef={ref}
                  placeholder="Type a company name"
                  onChange={(event: any) => {
                    const value = event.target.value;
                    field.onChange(value);
                    setValue('companyName', value);
                  }}
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message || 'Incorrect data'}
                />
              )}
              rules={{
                maxLength: {
                  message: 'The max count of characters is 100',
                  value: 100,
                },
              }}
            />
          </Grid>
          <Grid width={'402.5px'}>
            <Typography fontSize={'16px'} fontWeight={500}>
              Customer Email
            </Typography>
            <Controller
              control={control}
              name="customerEmail"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  inputRef={ref}
                  // disabled={items?.status === 'draft'}
                  error={!!errors.email}
                  placeholder="Enter Customer email"
                  helperText={errors.email?.message || 'Incorrect data'}
                  type="email"
                  onChange={(event: any) => {
                    setValue('customerEmail', event.target.value);
                  }}
                  sx={{ width: '100%' }}
                />
              )}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email',
                },
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CustomerSection;
