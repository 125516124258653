import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';
import { EDraftOrder } from 'store/constants/draft-order';

const finalizeADeal = createAsyncThunk(EDraftOrder.finalizeADeal, async (payload: any) => {
  const { data } = await instance.post('draft-orders/finalize', payload);

  return data;
});

export default finalizeADeal;
