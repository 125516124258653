import React, { FunctionComponent } from 'react';
import dayjs from 'dayjs';

import ProgressBar from 'components/ProgressBar';

import { TimelineCellStyled, DateSpan, TimelineDates } from './styled';
import ChevronRight from 'styles/icons/ChevronRight';
import { ITimeLine } from '../../../store/types/common';

interface TimelineCellProps {
  timeline?: ITimeLine;
  showProgressBar?: boolean;
}

const DATE_FORMAT = 'MM/DD/YYYY';

const calculateElapsedPercentage = (startDate?: string, endDate?: string): number => {
  if (!startDate || !endDate) {
    return 0;
  }

  const now = dayjs(); // Current date
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (now.isBefore(start)) {
    return 0;
  }

  if (now.isAfter(end)) {
    return 100;
  }

  const totalDuration = end.diff(start, 'millisecond');

  const elapsedDuration = now.diff(start, 'millisecond');

  const percentage = (elapsedDuration / totalDuration) * 100;

  return Math.min(100, Math.max(0, percentage));
};

const TimelineCell: FunctionComponent<TimelineCellProps> = ({ timeline, showProgressBar }) => {
  const startDate = timeline?.startDate;
  const endDate = timeline?.endDate;

  return (
    <TimelineCellStyled>
      <TimelineDates>
        {timeline ? (
          <>
            <DateSpan>{startDate ? dayjs.utc(startDate).format(DATE_FORMAT) : 'N/A'}</DateSpan>

            <ChevronRight style={{ height: '16px', width: '16px' }} />

            <DateSpan>{endDate ? dayjs.utc(endDate).format(DATE_FORMAT) : 'N/A'}</DateSpan>
          </>
        ) : (
          'N/A'
        )}
      </TimelineDates>

      {showProgressBar && <ProgressBar progress={calculateElapsedPercentage(startDate, endDate)} />}
    </TimelineCellStyled>
  );
};

export default TimelineCell;
