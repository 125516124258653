import React from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import ROUTES from 'router/constants';

import ModalWrapper from '../../Modal/Modal';

interface IBottomButton {
  btnName?: string;
  disabledBtn?: boolean;
  formName?: string;
  hideButton?: boolean;
  loadingBtn?: boolean;
  openCancel: boolean;
  setOpenCancel: (open: boolean) => void;
}

export const StyledAppBar = styled(AppBar)(({ theme }) =>
  theme.unstable_sx({
    display: {
      xl: 'none',
    },
    bottom: 0,
    top: 'auto',
  }),
);

const BottomButton: React.FunctionComponent<IBottomButton> = ({
  btnName = 'Next Step',
  disabledBtn,
  formName,
  hideButton,
  loadingBtn,
  openCancel,
  setOpenCancel,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <StyledAppBar color="default" position="absolute" elevation={6}>
        <Toolbar>
          <Stack direction="row" flex="1 0 0" spacing={2} useFlexGap my={2}>
            <Stack flex="1 0 0">
              <Button
                data-test="header-flow-button"
                variant="outlined"
                color="secondary"
                onClick={() => setOpenCancel(true)}
              >
                Cancel
              </Button>
            </Stack>
            {!hideButton && (
              <Stack flex="1 0 0">
                <LoadingButton
                  disabled={disabledBtn || loadingBtn}
                  loading={loadingBtn}
                  data-test="mobile-flow-button"
                  id="mobile-flow-button"
                  form={formName || 'hook-form'}
                  type="submit"
                  variant="contained"
                >
                  {btnName}
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </Toolbar>
      </StyledAppBar>
      <ModalWrapper
        dialogProps={{
          id: 'datepicker',
          onClose: () => setOpenCancel(false),
          open: openCancel,
          PaperProps: {
            sx: { width: 600 },
          },
        }}
        title={{ title: 'Cancel order creation?' }}
      >
        <DialogContent>
          This is where you can provide additional details about the action or any important notes
          users should be aware of before proceeding.
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setOpenCancel(false)}
            variant="outlined"
            fullWidth
          >
            Leave
          </Button>
          <Button
            color="primary"
            onClick={() => navigate(ROUTES.ORDER_LIST)}
            variant="contained"
            fullWidth
          >
            Cancel Creation
          </Button>
        </DialogActions>
      </ModalWrapper>
    </>
  );
};

export default BottomButton;
