import { Row } from 'components/Table/styled';
import React, { FunctionComponent } from 'react';

import { DRAFT_ORDERS_LIST_PAGE_SIZE } from 'store/query/draft-order/draft-order';
import { Caption, FooterBar, PaginationButton } from './styled';

interface DealsListPageFooterProps {
  page: number;
  count: number;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
}

const DealsListPageFooter: FunctionComponent<DealsListPageFooterProps> = ({
  page,
  count,
  onPreviousClick,
  onNextClick,
}) => {
  const totalPages = Math.ceil(count / DRAFT_ORDERS_LIST_PAGE_SIZE);

  return totalPages > 1 ? (
    <FooterBar>
      <PaginationButton
        onClick={onPreviousClick}
        style={{
          visibility: page === 1 ? 'hidden' : 'visible',
        }}
      >
        Previous
      </PaginationButton>

      <Caption>
        Page {page} of {totalPages}
      </Caption>

      <PaginationButton
        onClick={onNextClick}
        style={{
          visibility: page === totalPages ? 'hidden' : 'visible',
        }}
      >
        Next
      </PaginationButton>
    </FooterBar>
  ) : null;
};

export default DealsListPageFooter;
