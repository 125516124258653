import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  activeDraftOrdersSearchQuery,
  completedDraftOrdersSearchQuery,
  onHoldDraftOrdersSearchQuery,
  orderCreatedDraftOrdersSearchQuery,
  recommendedCustomerDraftOrdersSearchQuery,
  staffingDraftOrdersSearchQuery,
} from 'store/query/draft-order/draft-order';
import draftOrdersCount from 'store/query/draft-order/count';
import { getDealOwners, getDealCustomers } from 'store/query/draft-order/supportUsers';

import { DraftOrderSalesPhase, IDraftOrder, IDraftOrdersAndCount } from 'store/types/draftOrder';
import { IBasicUser } from 'store/types/user';

type LoadingStatus = 'init' | 'loading' | 'loaded' | 'error';

const salesPhaseMap: { [key in DraftOrderSalesPhase]: string } = {
  [DraftOrderSalesPhase.TALENT_STAFFING_60]: 'staffing',
  [DraftOrderSalesPhase.RECOMMENDATION_SENT_70]: 'recommended_customer',
  [DraftOrderSalesPhase.VERBAL_AGREEMENT_80]: 'order_created',
  [DraftOrderSalesPhase.CLOSED_WON_PAID_100]: 'active',
  [DraftOrderSalesPhase.ON_HOLD_15]: 'on_hold',
  [DraftOrderSalesPhase.LEAD]: 'lead',
  [DraftOrderSalesPhase.CLOSED_LOST]: 'closed_lost',
};

export interface DraftOrderSupportState {
  countStatus: LoadingStatus;
  dealOwners: IBasicUser[] | null;
  dealOwnersStatus: LoadingStatus;
  dealCustomers: IBasicUser[] | null;
  dealCustomersStatus: LoadingStatus;
}
export interface DraftOrderTabState {
  items: IDraftOrder[] | null;
  status: LoadingStatus;
  count: number;
  page: number;
}

export interface DraftOrderState {
  support: DraftOrderSupportState;
  tabs: {
    staffing: DraftOrderTabState;
    recommended_customer: DraftOrderTabState;
    order_created: DraftOrderTabState;
    active: DraftOrderTabState;
    completed: DraftOrderTabState;
    on_hold: DraftOrderTabState;
  };
}

const getTabInitialState = (): DraftOrderTabState => ({
  items: null,
  status: 'init',
  count: 0,
  page: 1,
});

const createDraftOrderTabSlice = (
  name: string,
  draftOrdersSearchQuery: any,
  requiredStatus?: string,
) => {
  return createSlice({
    name,
    initialState: getTabInitialState(),
    reducers: {
      resetDraftOrder: state => {
        state = getTabInitialState();
      },
      setDraftOrders: (state, action: PayloadAction<IDraftOrdersAndCount>) => {
        const { draftOrders, count, page } = action.payload;
        state = {
          items: draftOrders,
          status: 'loaded',
          count,
          page,
        };
      },
      addDraftOrders: (state, action: PayloadAction<IDraftOrdersAndCount>) => {
        const { draftOrders, count, page } = action.payload;
        state = {
          items: [...(state.items || []), ...draftOrders],
          status: 'loaded',
          count,
          page,
        };
      },
    },
    extraReducers: builder =>
      builder
        .addCase(draftOrdersSearchQuery.pending, (state, action) => {
          return {
            ...state,
            status: 'loading',
          };
        })
        .addCase(draftOrdersSearchQuery.fulfilled, (state, action) => {
          const {
            payload: { data },
          } = action;

          const { draftOrders, count, page } = data;

          return {
            items: draftOrders,
            status: 'loaded',
            count,
            page,
          };
        })
        .addCase(draftOrdersSearchQuery.rejected, (state, action) => {
          return {
            ...state,
            status: 'error',
          };
        })
        .addCase(draftOrdersCount.fulfilled, (state, action) => {
          const salesPhaseCount = action.payload.data;

          const count =
            salesPhaseCount.find(
              item =>
                item.salesPhase &&
                salesPhaseMap[item.salesPhase as keyof typeof salesPhaseMap] === name &&
                (!requiredStatus || item.status === requiredStatus),
            )?.count || 0;

          return {
            ...state,
            count,
          };
        }),
  });
};

const staffingDraftOrdersSlice = createDraftOrderTabSlice(
  salesPhaseMap[DraftOrderSalesPhase.TALENT_STAFFING_60],
  staffingDraftOrdersSearchQuery,
);

const recommendedCustomerDraftOrdersSlice = createDraftOrderTabSlice(
  salesPhaseMap[DraftOrderSalesPhase.RECOMMENDATION_SENT_70],
  recommendedCustomerDraftOrdersSearchQuery,
);

const orderCreatedDraftOrdersSlice = createDraftOrderTabSlice(
  salesPhaseMap[DraftOrderSalesPhase.VERBAL_AGREEMENT_80],
  orderCreatedDraftOrdersSearchQuery,
);

const activeDraftOrdersSlice = createDraftOrderTabSlice(
  salesPhaseMap[DraftOrderSalesPhase.CLOSED_WON_PAID_100],
  activeDraftOrdersSearchQuery,
  'active',
);

const completedDraftOrdersSlice = createDraftOrderTabSlice(
  salesPhaseMap[DraftOrderSalesPhase.CLOSED_WON_PAID_100],
  completedDraftOrdersSearchQuery,
  'completed',
);

const onHoldDraftOrdersSlice = createDraftOrderTabSlice(
  salesPhaseMap[DraftOrderSalesPhase.ON_HOLD_15],
  onHoldDraftOrdersSearchQuery,
);

export const {
  resetDraftOrder: resetStaffingDraftOrder,
  setDraftOrders: setStaffingDraftOrders,
  addDraftOrders: addStaffingDraftOrders,
} = staffingDraftOrdersSlice.actions;

export const {
  resetDraftOrder: resetRecommendedCustomerDraftOrder,
  setDraftOrders: setRecommendedCustomerDraftOrders,
  addDraftOrders: addRecommendedCustomerDraftOrders,
} = recommendedCustomerDraftOrdersSlice.actions;

export const {
  resetDraftOrder: resetOrderCreatedDraftOrder,
  setDraftOrders: setOrderCreatedDraftOrders,
  addDraftOrders: addOrderCreatedDraftOrders,
} = orderCreatedDraftOrdersSlice.actions;

export const {
  resetDraftOrder: resetActiveDraftOrder,
  setDraftOrders: setActiveDraftOrders,
  addDraftOrders: addActiveDraftOrders,
} = activeDraftOrdersSlice.actions;

export const {
  resetDraftOrder: resetCompletedDraftOrder,
  setDraftOrders: setCompletedDraftOrders,
  addDraftOrders: addCompletedDraftOrders,
} = completedDraftOrdersSlice.actions;

export const {
  resetDraftOrder: resetOnHoldDraftOrder,
  setDraftOrders: setOnHoldDraftOrders,
  addDraftOrders: addOnHoldDraftOrders,
} = onHoldDraftOrdersSlice.actions;

const staffingDraftOrdersReducer = staffingDraftOrdersSlice.reducer;
const recommendedCustomerDraftOrdersReducer = recommendedCustomerDraftOrdersSlice.reducer;
const orderCreatedDraftOrdersReducer = orderCreatedDraftOrdersSlice.reducer;
const activeDraftOrdersReducer = activeDraftOrdersSlice.reducer;
const completedDraftOrdersReducer = completedDraftOrdersSlice.reducer;
const onHoldDraftOrdersReducer = onHoldDraftOrdersSlice.reducer;

const supportInitialState: DraftOrderSupportState = {
  countStatus: 'init',
  dealOwners: null,
  dealOwnersStatus: 'init',
  dealCustomers: null,
  dealCustomersStatus: 'init',
};

const supportSlice = createSlice({
  name: 'support',
  initialState: supportInitialState,
  reducers: {
    setDraftOrderCountLoaded: (state, action: PayloadAction<string>) => {
      state.countStatus = action.payload as LoadingStatus;
    },
  },
  extraReducers: builder => {
    builder.addCase(draftOrdersCount.pending, state => ({
      ...state,
      countStatus: 'loading',
    }));
    builder.addCase(draftOrdersCount.fulfilled, state => ({
      ...state,
      countStatus: 'loaded',
    }));
    builder.addCase(draftOrdersCount.rejected, state => ({
      ...state,
      countStatus: 'error',
    }));
    builder.addCase(getDealOwners.pending, (state, action) => ({
      ...state,
      dealOwnersStatus: 'loading',
    }));
    builder.addCase(getDealOwners.fulfilled, (state, action) => {
      state.dealOwners = action.payload.data;
      state.dealOwnersStatus = 'loaded';
    });
    builder.addCase(getDealOwners.rejected, (state, action) => ({
      ...state,
      dealOwnersStatus: 'error',
    }));
    builder.addCase(getDealCustomers.pending, (state, action) => ({
      ...state,
      dealCustomersStatus: 'loading',
    }));
    builder.addCase(getDealCustomers.fulfilled, (state, action) => ({
      ...state,
      dealCustomers: action.payload.data,
      dealCustomersStatus: 'loaded',
    }));
    builder.addCase(getDealCustomers.rejected, (state, action) => ({
      ...state,
      dealCustomersStatus: 'error',
    }));
  },
});

export default combineReducers({
  support: supportSlice.reducer,
  tabs: combineReducers({
    staffing: staffingDraftOrdersReducer,
    recommended_customer: recommendedCustomerDraftOrdersReducer,
    order_created: orderCreatedDraftOrdersReducer,
    active: activeDraftOrdersReducer,
    completed: completedDraftOrdersReducer,
    on_hold: onHoldDraftOrdersReducer,
  }),
});
