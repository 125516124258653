import React, { FC, useEffect, useState } from 'react';
import dayjs from 'utils/dayjs';
import { useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { IWorker } from 'store/types/consultant';
import { IExtendUserCustomer } from 'store/types/customer';
import { IBasicOrders, IWeeklyBillings } from 'store/types/order';

import fetchOrderBalance from 'store/query/order/fetchOrderBalance';
import fetchWeeklyBillings from 'store/query/order/fetchWeeklyBillings';

import { getMeRoleSelector } from 'store/selectors/getUserSelector';
import {
  currentOrderBalanceSelector,
  currentWeeklyBillingsLoadingSelector,
  currentWeeklyBillingsOrderSelector,
} from 'store/selectors/getOrderSelector';

import BillingModal from 'components/Modal/BillingModal';
import Loader from 'components/Loader/Loader';
import { ERole, WeeklyBillingOrderStatus } from 'models/consts';
import { Box } from '@mui/material';

interface IWeeklyBillingsProps {
  isConsultantOrAgency: boolean;
  order: IBasicOrders;
  profile: IWorker | IExtendUserCustomer;
}

interface IModalState {
  open: boolean;
  orderId: number | null;
  index: number | null;
  isPurchase?: boolean;
}

const WeeklyBillings: FC<IWeeklyBillingsProps> = ({ isConsultantOrAgency, order, profile }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const weekly_id = searchParams.get('weekly_id');

  const dispatch = useAppDispatch();

  const [modal, setModal] = useState<IModalState>({
    open: false,
    orderId: null,
    index: null,
  });

  const role = useAppSelector(getMeRoleSelector);

  const weeklyBillings = useAppSelector(currentWeeklyBillingsOrderSelector(order.id));
  const weeklyBillingsLoading = useAppSelector(currentWeeklyBillingsLoadingSelector(order.id));

  const orderBalance = useAppSelector(currentOrderBalanceSelector(order.id));

  useEffect(() => {
    dispatch(
      fetchWeeklyBillings({
        orderId: order.id,
        role,
      }),
    );
  }, [dispatch, order.id, role]);

  useEffect(() => {
    if (weeklyBillings?.length && weekly_id && order) {
      const index = weeklyBillings.findIndex(item => item.id === parseInt(weekly_id));
      if (index !== -1) {
        // Ensure the index is valid
        setModal({
          open: true,
          orderId: order.id,
          index: index,
          isPurchase: !!order.purchaseOrder,
        });
      }
    }
  }, [weeklyBillings, weekly_id, order]);

  useEffect(() => {
    if (order.id) {
      dispatch(
        fetchOrderBalance({
          id: order.id,
          role,
          isPurchase: !!order.purchaseOrder,
        }),
      );
    }
  }, [dispatch, order.id, role, order.purchaseOrder]);

  const openModal = (data: IWeeklyBillings, index: number) => {
    setModal({
      open: true,
      orderId: data.orderId,
      index,
      isPurchase: !!data.order?.purchaseOrder,
    });
  };

  const closeModal = () => {
    setModal({
      open: false,
      orderId: null,
      index: null,
    });
  };

  const getButtonColor = (row: IWeeklyBillings) => {
    if (
      row?.status === WeeklyBillingOrderStatus.PENDING ||
      (row?.status === WeeklyBillingOrderStatus.SUBMITTED && !isConsultantOrAgency) ||
      (row?.status === WeeklyBillingOrderStatus.ADJUSTED && !isConsultantOrAgency) ||
      (row?.status === WeeklyBillingOrderStatus.DISPUTED && isConsultantOrAgency)
    ) {
      return 'secondary';
    }
    return 'tertiary';
  };

  const getButtonTitle = (row: IWeeklyBillings) => {
    if (
      row?.status === WeeklyBillingOrderStatus.PENDING ||
      (row?.status === WeeklyBillingOrderStatus.SUBMITTED && !isConsultantOrAgency) ||
      (row?.status === WeeklyBillingOrderStatus.ADJUSTED && !isConsultantOrAgency)
    ) {
      return 'Review';
    }

    if (row?.status === WeeklyBillingOrderStatus.DISPUTED && isConsultantOrAgency) {
      return 'Adjust';
    }

    return 'Detail';
  };

  return (
    <Box mx={1}>
      <Typography variant="h4" mb={1}>
        Weekly Billings
      </Typography>
      {weeklyBillingsLoading ? (
        <Loader />
      ) : (
        <>
          {!weeklyBillings?.length && (
            <>
              <Typography variant="h5" gutterBottom={true} mt={2.5} color="text.secondary">
                No billings to review yet
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {([
                  ERole.CUSTOMER,
                  ERole.CUSTOMER_ADMIN,
                  ERole.SELF_CUSTOMER,
                  ERole.CUSTOMER_TEAM_ADMIN,
                ].includes(role.name) && (
                  <>
                    This is where you will review specialists&apos; weekly billings when they’re
                    submitted.
                  </>
                )) || <>This is where you will submit your weekly billings.</>}
              </Typography>
            </>
          )}
          {!!weeklyBillings?.length && (
            <TableContainer data-test="weekly-billings-table">
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ pl: 0 }}>Week</TableCell>
                    <TableCell>Dates</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {weeklyBillings?.map((row: IWeeklyBillings, index: number) => (
                    <TableRow
                      key={row?.id}
                      sx={{ '& td, &:last-child th': { border: 0 } }}
                      data-test={`weekly-row-${index}`}
                    >
                      <TableCell sx={{ pl: 0 }}>Week {row?.week}</TableCell>
                      <TableCell data-test="weekly-dates">
                        {`${dayjs.utc(row?.startDate).format('MM/DD/YYYY')}-${dayjs.utc(row?.endDate).format('MM/DD/YYYY')}`}
                      </TableCell>
                      <TableCell data-test="weekly-hours">
                        {row?.status === 'pending' ? '' : row?.hours + 'h'}
                      </TableCell>
                      <TableCell data-test="weekly-status" sx={{ textTransform: 'capitalize' }}>
                        {row?.status}
                      </TableCell>
                      <TableCell sx={{ pr: 0 }}>
                        <Button
                          color={getButtonColor(row)}
                          onClick={() => openModal(row, index)}
                          fullWidth
                          variant="contained"
                          disabled={row?.status === 'pending' && !isConsultantOrAgency}
                          data-test="weekly-button"
                        >
                          {getButtonTitle(row)}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
      {weeklyBillings && weeklyBillings.length > 0 && modal && modal.index !== null && (
        <BillingModal
          isConsultantOrAgency={isConsultantOrAgency}
          modal={modal}
          close={closeModal}
          orderBalance={orderBalance}
          profile={
            profile && profile?.firstName
              ? profile
              : role?.id == 2
                ? weeklyBillings[modal.index].order?.customer
                : weeklyBillings[modal.index].order?.worker
          }
        />
      )}
    </Box>
  );
};

export default WeeklyBillings;
