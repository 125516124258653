import React, { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

import DealsListTabs from './DealsListTabs';

const DealsList: FunctionComponent = () => {
  return (
    <Box
      display="flex"
      flexDirection={'column'}
      mt={{ md: 4, xs: 2 }}
      mb={{ sm: 3 }}
      data-test="deals-list-page"
    >
      <Typography sx={{ textTransform: 'capitalize' }} variant="h1">
        Deals
      </Typography>

      <DealsListTabs />
    </Box>
  );
};

export default DealsList;
