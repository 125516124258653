import React from 'react';
import { useStateMachine } from 'little-state-machine';
import dayjs from 'dayjs';

import { EMatchingResultType } from 'models/consts';
import { IMatchedCard } from 'store/types/consultant';

import updateAction from 'store/actions/updateAction';

import ConsultantPreview from 'components/ConsultantComponents/ConsultantPreview';
import AgencyCard from '../../AgencyComponents/AgencyCard';
import { chatAssistantFilterSelector } from 'store/selectors/getChatAssistantSelector';
import { useAppSelector } from 'store/hooks';

import { calcWeeks, getNextMonday } from 'utils/dateFormatter';
import useMatchingParams from '../../../hooks/useMatchingParams';

interface IRecommendationCard {
  matchedCard: IMatchedCard;
  onOpenPreview: () => void;
  onSelectPreview: (worker: any) => void;
  isAIEnabled?: boolean;
  isCommunitySearch?: boolean;
  onAnonymousMode?: boolean;
}

const RecommendationCard: React.FunctionComponent<IRecommendationCard> = ({
  matchedCard,
  onOpenPreview,
  onSelectPreview,
  isAIEnabled,
  isCommunitySearch,
  onAnonymousMode,
}) => {
  const {
    state: { worker },
  } = useStateMachine({ updateAction });
  const {
    matchingParams,
    order: { allHours, levelId },
  } = useMatchingParams();
  const filter = useAppSelector(chatAssistantFilterSelector);

  const order = {
    timeLine: {
      // allHours: !isAIEnabled ? searchParams.allHours : filter?.allHours ? filter?.allHours : 20,
      allHours: !isAIEnabled ? allHours : filter?.allHours ? filter?.allHours : 20,
      startDate: !isAIEnabled
        ? matchingParams.startDate
        : filter?.startDateOrder
          ? filter?.startDateOrder
          : getNextMonday(),
      endDate: !isAIEnabled
        ? matchingParams.endDate
        : filter?.endDateOrder
          ? filter?.endDateOrder
          : dayjs(getNextMonday()).add(11, 'day').format('YYYY-MM-DD'),
    },
    levelId: !isAIEnabled ? levelId : filter?.levelId ? filter?.levelId : 2,
    worker,
    expertise: {
      product: {
        id: !isAIEnabled ? matchingParams.productId : filter?.productId,
      },
    },
  };

  return (
    <>
      {(matchedCard.cardType === EMatchingResultType.AGENCY && (
        <AgencyCard
          agency={matchedCard.agency}
          onOpenPreview={onOpenPreview}
          onSelectPreview={onSelectPreview}
          order={{ ...order, customPrice: { priceCents: matchedCard?.price?.priceCents } }}
          isSearchView
          isSelectedExpert={worker?.agency?.id === matchedCard.agency.id}
          workerMatching={matchedCard.matching}
          isCommunitySearch={isCommunitySearch}
          onAnonymousMode={onAnonymousMode}
        />
      )) || (
        <ConsultantPreview
          isSearchView
          workerMatching={matchedCard.matching}
          onOpenPreview={onOpenPreview}
          onSelectPreview={onSelectPreview}
          order={order}
          isSelectedExpert={worker?.specialist?.id === matchedCard.specialist.id}
          worker={matchedCard.specialist}
          isBlur
          isCommunitySearch={isCommunitySearch}
          onAnonymousMode={onAnonymousMode}
        />
      )}
    </>
  );
};

export default RecommendationCard;
