import React, {
  FC,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Capacitor } from '@capacitor/core';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Popper from '@mui/material/Popper';
import useMediaQuery from '@mui/material/useMediaQuery';
import Backdrop from '@mui/material/Backdrop';
import { useTheme } from '@mui/material/styles';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

import { IExpertiseBasis } from 'models/inner-models';

import MobileSelect from './MobileSelect';
import { IConsultantExpertises } from '../../../store/types/expertise';
import { InputProps as MuiInputProps } from '@mui/material/Input';
import { StyledPaperBox } from './styled';
import MobileNativePopper from '../MobileNativeTopPopper';
import { EProductFilters } from 'models/consts';
import { IProduct } from 'store/types/categoryProducts';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import BootstrapInput from '../BootstrapInput';
import CloseIcon from 'styles/icons/CloseIcon';

const filter = createFilterOptions<any>({
  matchFrom: 'any',
});

export type FormValues = {
  name: any;
  onChange: (value: any) => void;
  value: any;
};

interface AutocompleteProps {
  autoHighlight?: boolean;
  classes?: object;
  disabled?: boolean;
  groupBy?: (option: any) => string;
  getOptionDisabled?: (option: any) => boolean;
  ListboxComponent?: any;
  multiple: true | undefined;
  onInputChange?: (event: SyntheticEvent, value: string) => void;
  renderGroup?: (params: any) => ReactNode;
}

interface IInputProps extends MuiInputProps {
  dataTest?: string;
  disabled?: boolean;
  error?: boolean | undefined;
  readOnly?: true | undefined;
  helperText?: string | false;
  sx?: any;
}

interface IProductAutocomplete {
  autocompleteProps?: AutocompleteProps;
  field: FormValues;
  options: any[];
  inputProps?: IInputProps;
  customFilter?: (options: any, inputValue: string, type: EProductFilters) => any[];
  handleChange?: (event: any, fieldName: keyof (IExpertiseBasis | IConsultantExpertises)) => void;
  placeholder?: string;
  mobilesx?: object;
  disabled?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  CustomBannerView?: any;
  onRequestProduct: () => void;
  successProductRequest: boolean;
  setIsProductReqSuccess: (state: boolean) => void;
  resetDefault?: () => void;
  clerable?: boolean;
}

interface IEmptyBlockProps {
  onRequestProduct: () => void;
}

const EmptyBlock: FC<IEmptyBlockProps> = ({ onRequestProduct }) => {
  return (
    <Box mt={2} width="100%" display="flex" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ borderRadius: '50%', width: 48, height: 48, border: 'solid 1px #D4D4D4' }}
      >
        <SearchOutlined />
      </Box>
      <Typography fontWeight={500} fontSize={18} lineHeight={2.75}>
        No products or roles found
      </Typography>
      <Typography
        component="div"
        textAlign="center"
        width="70%"
        fontSize={18}
        lineHeight={2.75}
        mb={2}
      >
        You can{' '}
        <Link
          data-test="request-product-modal-button-xs"
          color="secondary"
          onClick={onRequestProduct}
        >
          Contact our Support Team
        </Link>{' '}
        to request a New Product or a General Role.
      </Typography>
    </Box>
  );
};

const ProductAutocomplete: FC<IProductAutocomplete> = ({
  autocompleteProps,
  customFilter,
  inputProps: inputCustomProps,
  field,
  options = [],
  handleChange,
  placeholder,
  isLoading,
  disabled,
  mobilesx,
  onClose,
  onOpen,
  CustomBannerView,
  onRequestProduct,
  successProductRequest = false,
  setIsProductReqSuccess,
  clerable,
  resetDefault,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [filterType, setFilter] = useState(EProductFilters.ALL);
  const [searchParams, setSearchParams] = useState<string | IProduct>(field.value);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileNative = Capacitor.isNativePlatform();

  const isSearchable = !!autocompleteProps?.onInputChange;
  const isMultiple = !!autocompleteProps?.multiple;
  const inputRef = useRef<any>();

  const handleOpen = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, [onClose]);

  const changeFilter = (value: EProductFilters, event: any) => {
    event.preventDefault();
    const searchValue = typeof searchParams === 'string' ? searchParams : '';
    setFilter(value);
    customFilter && customFilter(options, searchValue, value);
  };

  const handleRequestProduct = () => {
    onRequestProduct();
    handleClose();
  };

  useEffect(() => {
    const modalBackdrop = document.getElementsByClassName('MuiModal-backdrop');
    if (successProductRequest && !modalBackdrop?.item(0)?.childNodes?.length) {
      inputRef.current = {
        ...inputRef.current,
        value: '',
      };
      setIsProductReqSuccess(false);
    }
  }, [successProductRequest, handleClose, handleChange, field, setIsProductReqSuccess]);

  return (
    <>
      <Backdrop
        open
        style={{ zIndex: isOpen ? '999' : '-1', background: 'transparent' }}
        onClick={() => handleClose()}
      />
      <Autocomplete
        getOptionDisabled={(option: any) => option.isActive === false || option.disabled}
        {...autocompleteProps}
        value={field.value}
        key={`${field.name}-autocomplete`}
        open={isOpen}
        onOpen={() => handleOpen()}
        id={`${field.name}-autocomplete`}
        options={options}
        disableClearable={!clerable}
        getOptionLabel={(option: any) => {
          return option?.name || option?.email || option;
        }}
        clearIcon={clerable ? <CloseIcon /> : <Box display="none" />}
        fullWidth={true}
        renderInput={({
          InputLabelProps,
          inputProps: { onChange, ...inputProps },
          ...params
        }: any) => {
          inputRef.current = inputProps.ref.current;
          return (
            <BootstrapInput
              {...params}
              inputProps={{
                ...inputProps,
                onBlur: () => {
                  successProductRequest && inputProps.onBlur();
                },
              }}
              sx={{ zIndex: isOpen && !mobile ? '3000' : '1' }}
              readOnly={isMobileNative && !isSearchable}
              {...inputCustomProps}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (/^(?!.* {2,})[\S ]*$/.test(event.target.value)) {
                  onChange(
                    {
                      ...event,
                      target: {
                        ...event.target,
                        value: event.target.value.trimStart(),
                      },
                    },
                    field,
                  );
                  if (
                    !!field.value &&
                    typeof field.value === 'object' &&
                    event.target.value !== field.value.name
                  ) {
                    handleChange && handleChange(undefined, field.name);
                    field.onChange(undefined);
                    setSearchParams(event.target.value.trimStart());
                  }
                }
              }}
              placeholder={(!field?.value?.length && placeholder) || undefined}
            />
          );
        }}
        onChange={(event: any, newValue: any) => {
          handleChange && handleChange(newValue, field.name);
          field.onChange(newValue);
          setSearchParams(newValue);
          newValue && handleClose();
          event.target.value = undefined;
        }}
        filterOptions={(options: any, params: any) => {
          const filtered = filter(options, params);

          const { inputValue } = params;

          if (customFilter && !isLoading) {
            const value = inputValue || (typeof searchParams === 'string' ? searchParams : '');
            return customFilter(options, value, filterType);
          }
          return filtered;
        }}
        renderOption={({ id, ...props }: any, option: any, { inputValue }) => {
          const matches = match(option.name, inputValue, { insideWords: true });
          const parts = parse(option.name, matches);

          return (
            <Box
              {...props}
              disabled={props['aria-disabled']}
              key={id}
              component="li"
              sx={{ justifyContent: 'flex-start !important' }}
              id={`autocomplete-${option.id}`}
            >
              <Box display="flex">
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      whiteSpace: 'pre',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                {option.isActive === false && (
                  <Chip sx={{ ml: 1.5, height: 30 }} label="Coming soon" />
                )}
              </Box>
            </Box>
          );
        }}
        isOptionEqualToValue={(options: any, value: any) => options.id === value.id}
        loading={isLoading}
        noOptionsText={<EmptyBlock onRequestProduct={handleRequestProduct} />}
        PopperComponent={
          !mobile || isSearchable
            ? isMobileNative
              ? MobileNativePopper
              : ({ children, ...props }: any) => (
                  <Popper {...props} sx={{ zIndex: `3000 !important` }}>
                    {children}
                  </Popper>
                )
            : ({ open, children, className }: any) => (
                <MobileSelect
                  className={className}
                  isOpen={open}
                  multiple={isMultiple}
                  placeholder={placeholder}
                  setOpen={setOpen}
                  mobilesx={mobilesx}
                  id={`${field.name}-autocomplete-listbox`}
                  dataTest={field.name}
                >
                  {children}
                </MobileSelect>
              )
        }
        PaperComponent={
          !mobile || isSearchable
            ? ({ children }: any) => {
                return (
                  <StyledPaperBox
                    sx={{
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.18)',
                      maxHeight: '40vh',
                      overflowY: 'auto',
                    }}
                  >
                    {CustomBannerView && CustomBannerView({ handleRequestProduct })}
                    <Box display="flex" sx={{ px: 2, mt: 1.6 }}>
                      <Chip
                        label="All"
                        onClick={e => changeFilter(EProductFilters.ALL, e)}
                        variant={filterType === EProductFilters.ALL ? 'filled' : 'outlined'}
                        color="secondary"
                        sx={{ mr: 1 }}
                      />
                      <Chip
                        label="Software Products"
                        onClick={e => changeFilter(EProductFilters.SOFTWARE, e)}
                        variant={filterType === EProductFilters.SOFTWARE ? 'filled' : 'outlined'}
                        color="secondary"
                        sx={{ mr: 1 }}
                      />
                      <Chip
                        label="General Roles"
                        onClick={e => changeFilter(EProductFilters.ROLE, e)}
                        variant={filterType === EProductFilters.ROLE ? 'filled' : 'outlined'}
                        color="secondary"
                        sx={{ mr: 1 }}
                      />
                    </Box>
                    {children}
                  </StyledPaperBox>
                );
              }
            : (props: any) => (
                <StyledPaperBox sx={{ overflowY: 'auto' }}>
                  {CustomBannerView && CustomBannerView({ setOpen })}
                  {props.children}
                </StyledPaperBox>
              )
        }
        disabled={disabled}
      />
    </>
  );
};

export default ProductAutocomplete;
