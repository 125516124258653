import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { UploadIcon } from 'styles/icons/UploadIcon';

import { EConsultantEditingModals, EConsultantRegStep } from 'models/consts';

import Availability from 'views/Auth/ConsultantRegister/Availability/Availability';

import {
  ProductsExperienses,
  SkillsForm,
  CertificatesListEditing,
  CertificateForm,
} from 'components/ConsultantComponents';
import ProductsExpertises from 'components/ConsultantComponents/Products/ProductsExpertises';
import ModalWrapper from '../Modal';
import ConsultantFullProfile from 'views/Auth/ConsultantRegister/Profile/ConsultantFullProfile';
import isEqual from 'lodash/fp/isEqual';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../../../store/actions/updateAction';
import useSafeAreaView from 'components/SafeAreaView';

interface IEditConsultantModal {
  consultantPage: EConsultantEditingModals;
  close: (open: { type: EConsultantEditingModals; open: boolean; showIntro?: boolean }) => void;
  visible: boolean;
  children?: React.ReactNode;
  showIntro?: boolean;
  size?: 'sm' | 'lg' | 'xl' | 'md';
  dialogClassName?: string;
  fullscreen?: true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down' | undefined;
}

const ModalEditConsultantWrapper: FunctionComponent<IEditConsultantModal> = ({
  visible,
  close,
  showIntro,
  consultantPage,
}) => {
  const { safeAreaPadding } = useSafeAreaView();

  const methods = useForm<any>({
    mode: 'onChange',
  });

  const {
    state: { experiences, expertise },
  } = useStateMachine({ updateAction });

  const handleClose = useCallback(
    (value: any) => {
      close(value);
      methods.clearErrors();
      methods.reset();
    },
    [close, methods],
  );

  const renderForm = useCallback(() => {
    switch (+consultantPage) {
      case EConsultantEditingModals.PRODUCTS:
        return (
          <ProductsExperienses
            isModal={true}
            showIntro={showIntro}
            onSubmitted={() =>
              handleClose({
                open: true,
                type: EConsultantEditingModals.PROFICIENCY,
                showIntro: showIntro ? true : undefined,
              })
            }
          />
        );
      case EConsultantEditingModals.PROFICIENCY:
        return (
          <ProductsExpertises
            isModal={true}
            showIntro={showIntro}
            onSubmitted={() =>
              handleClose({ open: false, type: EConsultantEditingModals.NO_MODAL })
            }
          />
        );
      case EConsultantEditingModals.AVAILABILITY:
        return (
          <Availability
            isModal={true}
            showIntro={showIntro}
            onSubmitted={() =>
              handleClose({ open: false, type: EConsultantEditingModals.NO_MODAL })
            }
          />
        );
      case EConsultantEditingModals.ADDITIONAL_SKILLS:
        return (
          <SkillsForm
            isModal={true}
            showIntro={showIntro}
            onClose={() => handleClose({ open: false, type: +consultantPage })}
          />
        );
      case EConsultantEditingModals.CERTIFICATIONS:
        return (
          <CertificatesListEditing
            onSubmitted={() =>
              handleClose({ open: true, type: EConsultantEditingModals.CERTIFICATIONS })
            }
          />
        );
      case EConsultantEditingModals.CERTIFICATE_CREATION_FORM:
        return (
          <CertificateForm
            showIntro={showIntro}
            onClose={() =>
              handleClose({ open: false, type: EConsultantEditingModals.CERTIFICATE_CREATION_FORM })
            }
          />
        );
      case EConsultantEditingModals.PROFILE:
        return (
          <ConsultantFullProfile
            isModal={true}
            showIntro={showIntro}
            onSubmitted={() =>
              handleClose({ open: false, type: EConsultantEditingModals.NO_MODAL })
            }
          />
        );
      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantPage, handleClose]);

  const modalTitle = useMemo(() => {
    switch (+consultantPage) {
      case EConsultantEditingModals.PRODUCTS:
        return {
          title: 'Share your work and project history to determine your seniority and hourly rate',
          subTitle:
            'Your work history impacts your pay and appeals to clients. This helps us match you with suitable opportunities and ensure fair compensation. Be specific, including:',
        };
      case EConsultantEditingModals.PROFICIENCY:
        return {
          title: 'Select your preferred modules and expertise level',
          subTitle:
            'To match you with relevant projects, please indicate your interest and proficiency in each module. By clearly communicating your preferences and expertise, we can better align you with projects that suit your skills and interests.',
        };
      case EConsultantEditingModals.AVAILABILITY:
        return {
          title: 'Manage Availability:',
          subTitle: '',
        };
      case EConsultantEditingModals.ADDITIONAL_SKILLS:
        return {
          title: 'What additional skills would you like to point out to potential customers?',
          subTitle: 'Add existing or create new skill tags and sort by importance',
        };
      case EConsultantEditingModals.CERTIFICATIONS:
        return {
          title: 'Edit Certifications',
        };
      case EConsultantEditingModals.CERTIFICATE_CREATION_FORM:
        return {
          title: 'Upload New Certification',
        };
      case EConsultantEditingModals.PROFILE:
        return {
          title: 'Tell us about yourself',
          subTitle: 'What type of work do you want to deliver on workerbee?',
        };
      default:
        return {
          title: '',
          subTitle: '',
        };
    }
  }, [consultantPage]);

  const getModalSafeAreaPadding = useCallback(() => {
    switch (+consultantPage) {
      case EConsultantEditingModals.PROFILE:
      case EConsultantEditingModals.AVAILABILITY:
      case EConsultantEditingModals.PRODUCTS:
        return safeAreaPadding.top;
      default:
        0;
    }
  }, [consultantPage, safeAreaPadding]);

  const buttonText = useMemo(() => {
    switch (+consultantPage) {
      case EConsultantEditingModals.PRODUCTS:
        return 'Next page';
      case EConsultantEditingModals.CERTIFICATIONS:
        return 'Upload New Certification';

      case EConsultantEditingModals.CERTIFICATE_CREATION_FORM:
        return 'Add to profile';

      default:
        return 'Save';
    }
  }, [consultantPage]);

  const modalWidth = useMemo(() => {
    switch (+consultantPage) {
      case EConsultantEditingModals.PRODUCTS:
      case EConsultantEditingModals.PROFICIENCY:
      case EConsultantEditingModals.CERTIFICATIONS:
      case EConsultantEditingModals.CERTIFICATE_CREATION_FORM:
        return 'xl';

      default:
        return undefined;
    }
  }, [consultantPage]);

  return (
    <ModalWrapper
      dialogProps={{
        'data-test': 'edit-consultant-profile',
        onClose: () => handleClose({ open: false, type: consultantPage }),
        open: visible,
        maxWidth: modalWidth,
        PaperProps: {
          sx: {
            overscrollBehavior: 'auto',
            overflow: 'scroll',
            paddingTop: getModalSafeAreaPadding(),
          },
        },
      }}
      title={modalTitle}
    >
      <>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <FormProvider {...methods}>{renderForm()}</FormProvider>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            color="secondary"
            className="submit-button"
            loading={
              methods.formState.isSubmitting &&
              consultantPage !== EConsultantEditingModals.CERTIFICATIONS
            }
            startIcon={consultantPage === EConsultantEditingModals.CERTIFICATIONS && <UploadIcon />}
            onClick={() =>
              consultantPage === EConsultantEditingModals.CERTIFICATIONS &&
              handleClose({ open: true, type: EConsultantEditingModals.CERTIFICATE_CREATION_FORM })
            }
            form="hook-form"
            type="submit"
            fullWidth
            disabled={
              (!methods.formState.isValid ||
                methods.formState.isSubmitting ||
                Object.keys(methods.formState.errors).length > 0 ||
                (+consultantPage === EConsultantRegStep.PRODUCTS &&
                  !isEqual(experiences, methods.getValues().experiences || null)) ||
                (+consultantPage === EConsultantRegStep.PROFICIENCY &&
                  !isEqual(expertise, methods.getValues().expertise || null))) &&
              consultantPage !== EConsultantEditingModals.CERTIFICATIONS
            }
            variant="contained"
            data-test="save-edit-button"
          >
            {buttonText}
          </LoadingButton>
        </DialogActions>
      </>
    </ModalWrapper>
  );
};

ModalEditConsultantWrapper.displayName = 'ModalEditConsultantWrapper';
ModalEditConsultantWrapper.whyDidYouRender = true;

export default ModalEditConsultantWrapper;
