import React, { FunctionComponent, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import ShadowBox from '../ShadowBox';
import { DealBadge } from 'components/Badge/DealBadge';
import UserRow from 'components/Table/UserCell';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDraftOrderDetailsSelector } from 'store/selectors/getDraftOrdersSelector';
import { getNameByRoleId } from 'models/consts';
import { outputQuery } from 'helpers/decorators';
import fetchUsersList from 'store/query/user/fetchUsersList';

const SalesSection: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const { items, loading, loaded } = useAppSelector(getDraftOrderDetailsSelector);

  const [salesManagerData, setSalesManagerData] = useState<any[]>([]);

  useEffect(() => {
    dispatch(
      fetchUsersList({
        filter: outputQuery({
          filterByRole: getNameByRoleId.find(item => item.name === 'Sales')?.id,
        }),
        limit: 100,
      }),
    ).then((data: any) => {
      setSalesManagerData(data?.payload?.users || []);
    });
  }, [dispatch]);

  return (
    <>
      {items && (
        <ShadowBox>
          <Typography fontSize={24} fontWeight={700}>
            Sales
          </Typography>
          <Box mt={2}>
            <Box>
              <Typography fontSize={14} fontWeight={400} color={'#5D5D5E'}>
                Sales Stage
              </Typography>
              <DealBadge phase={items?.salesPhase} />
            </Box>
            <Box mt={'16px'}>
              <Typography fontSize={14} fontWeight={400} color={'#5D5D5E'}>
                Deal Owner
              </Typography>
              <Box>
                <UserRow
                  user={items.user}
                  usersData={salesManagerData}
                  isEditable={false}
                  nameDisplayLength={100}
                  cellsx={{ padding: '16px 2px', marginX: 0 }}
                />
              </Box>
            </Box>
          </Box>
        </ShadowBox>
      )}
    </>
  );
};

export default SalesSection;
