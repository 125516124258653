import React, { FunctionComponent } from 'react';

import { Badge } from 'components/Badge';
import CircleIcon from 'components/CircleIcon';

import { DraftOrderSalesPhase, DraftOrderTalentPhase } from 'store/types/draftOrder';
interface DealBadgeProps {
  phase: DraftOrderTalentPhase | DraftOrderSalesPhase;
}

const phaseMap: {
  [key in DraftOrderTalentPhase | DraftOrderSalesPhase]: [
    { background: string; color: string; border: string },
    string,
  ];
} = {
  [DraftOrderTalentPhase.INTERNAL_LIST]: [
    {
      background: '#FFFFFF',
      color: '#1F1F1F',
      border: '1px solid #E7E7E7',
    },
    'Internal List',
  ],
  [DraftOrderTalentPhase.SURGE]: [
    {
      background: '#CFF9FE',
      color: '#0E7090',
      border: 'none',
    },
    'Surge',
  ],
  [DraftOrderTalentPhase.RECOMMENDED]: [
    {
      background: '#FCCEEE',
      color: '#C11574',
      border: 'none',
    },
    'Recommended',
  ],
  [DraftOrderTalentPhase.PRESENT_TO_CLIENT]: [
    {
      background: '#FDE7DD',
      color: '#C4460D',
      border: 'none',
    },
    'Presented to Client',
  ],
  [DraftOrderTalentPhase.INTERVIEW]: [
    {
      background: '#D1E9FF',
      color: '#1849A9',
      border: 'none',
    },
    'Interview',
  ],
  [DraftOrderTalentPhase.ADDITIONAL_BGC]: [
    {
      background: '#CEEAB0',
      color: '#4F7A21',
      border: 'none',
    },
    'Additional BGC',
  ],
  [DraftOrderTalentPhase.ENGAGED]: [
    {
      background: '#CDF2CE',
      color: '#027805',
      border: 'none',
    },
    'Engaged',
  ],
  [DraftOrderTalentPhase.REJECTED]: [
    {
      background: '#FFDBE4',
      color: '#DB204D',
      border: 'none',
    },
    'Rejected - Recommended',
  ],
  [DraftOrderSalesPhase.TALENT_STAFFING_60]: [
    {
      background: '#FDE7DD',
      color: '#C4460D',
      border: 'none',
    },
    'Sales Stage 60%',
  ],
  [DraftOrderSalesPhase.LEAD]: [
    {
      background: '#FDE7DD',
      color: '#C4460D',
      border: 'none',
    },
    'Sales Stage Lead',
  ],
  [DraftOrderSalesPhase.RECOMMENDATION_SENT_70]: [
    {
      background: '#FDE7DD',
      color: '#C4460D',
      border: 'none',
    },
    'Sales Stage 70%',
  ],
  [DraftOrderSalesPhase.VERBAL_AGREEMENT_80]: [
    {
      background: '#FDE7DD',
      color: '#C4460D',
      border: 'none',
    },
    'Sales Stage 80%',
  ],
  [DraftOrderSalesPhase.CLOSED_WON_PAID_100]: [
    {
      background: '#FDE7DD',
      color: '#C4460D',
      border: 'none',
    },
    'Sales Stage 100%',
  ],
  [DraftOrderSalesPhase.CLOSED_LOST]: [
    {
      background: '#FDE7DD',
      color: '#C4460D',
      border: 'none',
    },
    'Sales Stage Closed Lost',
  ],
  [DraftOrderSalesPhase.ON_HOLD_15]: [
    {
      background: '#FDE7DD',
      color: '#C4460D',
      border: 'none',
    },
    'Sales Stage 15%',
  ],
};

export const DealBadge: FunctionComponent<DealBadgeProps> = ({ phase }) => {
  const variantStyle = phase && phaseMap[phase][0];
  const text = phase && phaseMap[phase][1];

  return (
    <>
      {variantStyle && (
        <Badge style={variantStyle}>
          <CircleIcon color={variantStyle.color} />
          {text}
        </Badge>
      )}
    </>
  );
};
