import { EPreorderChangeAction } from 'models/consts';
import { getEndDate } from './dateFormatter';
import dayjs from './dayjs';
import { snakeCaseToTextFormatter } from './textFormatter';

export const handlerTimeLineChange = (
  allHours: number,
  availabilityWeek: number,
  startDate: string,
  setValue: any,
  clearErrors: any,
) => {
  if (!!availabilityWeek && !!startDate && !!allHours) {
    const end = getEndDate(startDate, allHours, availabilityWeek);
    setValue('endDate', end || '');
    clearErrors('endDate');
    clearErrors('startDate');
  }
};

export const onTypeaheadItemChange = (event: any, fieldName: string, setValue: any) => {
  if (event.length !== 1) {
    return;
  }
  if (fieldName === 'modules') {
    setValue('primaryModuleId', event[0].id);
  }
  if (fieldName === 'works') {
    setValue('primaryWorkId', event[0].id);
  }
};

export const setDate = (value: Date | null, field: any) => {
  field?.onChange(value ? dayjs(value || null).format('YYYY-MM-DD') : undefined);
};

export const getOrderActionText = (type: string) => {
  switch (type) {
    // all EChangeAction actions the same as enum key
    // EPreorderChangeAction.WAITING_BILLING has same action as key
    case EPreorderChangeAction.CANCEL: {
      return 'Cancel order';
    }
    case EPreorderChangeAction.CREATE: {
      return 'Order created';
    }
    case EPreorderChangeAction.CHANGE_STATUS: {
      return 'Order status changed';
    }
    case EPreorderChangeAction.ORDER_PAYMENT: {
      return 'Order paid';
    }
    case EPreorderChangeAction.ORDER_INVOICE: {
      return 'Invoice created';
    }
    case EPreorderChangeAction.SUBMIT_EARLY_COMPLETE: {
      return 'Submit order completion';
    }
    case EPreorderChangeAction.APPROVE_EARLY_COMPLETE: {
      return 'Approve order completion';
    }
    case EPreorderChangeAction.DECLINE_EARLY_COMPLETE: {
      return 'Decline order completion';
    }
    case EPreorderChangeAction.LEAVE_REVIEW: {
      return 'Review was submitted';
    }
    case EPreorderChangeAction.APPROVE_REVIEW: {
      return 'Approved review';
    }
    case EPreorderChangeAction.DECLINE_REVIEW: {
      return 'Declined review';
    }
    case EPreorderChangeAction.CREATE_EXTEND_HOURS: {
      return 'Extend order';
    }
    case EPreorderChangeAction.ACCEPT_EXTEND_HOURS: {
      return 'Extend order accepted';
    }
    case EPreorderChangeAction.DECLINE_EXTEND_HOURS: {
      return 'Extend order declined';
    }
    case EPreorderChangeAction.SUCCESS_PAID_EXTEND_HOURS: {
      return 'Order paid';
    }
    case EPreorderChangeAction.CREATE_WEEKLY_BILLINGS: {
      return 'Weekly billings created';
    }
    case EPreorderChangeAction.SUBMIT_WEEKLY_BILLINGS: {
      return 'Timesheets submitted';
    }
    case EPreorderChangeAction.APPROVE_WEEKLY_BILLINGS: {
      return 'Timesheets confirmed';
    }
    case EPreorderChangeAction.DISPUT_WEEKLY_BILLINGS: {
      return 'Timesheets disputed';
    }
    case EPreorderChangeAction.COMPLETE_ORDER_AUTOMATICALLY: {
      return 'Order completed';
    }
    case EPreorderChangeAction.COMPLETE_ORDER: {
      return 'Order completed';
    }
    case EPreorderChangeAction.ON_HOLD_ORDER: {
      return 'Order On Hold';
    }
    case EPreorderChangeAction.EXPIRED_ORDER: {
      return 'Order Expired';
    }
    case EPreorderChangeAction.READY_TO_START_ORDER: {
      return 'Order Ready To Start';
    }
    case EPreorderChangeAction.TERMINATED_ORDER: {
      return 'Order Terminated';
    }
    case EPreorderChangeAction.TRIAL_TERMINATED_ORDER: {
      return 'Order Terminated During Trial';
    }
    case EPreorderChangeAction.ACTIVE_ORDER: {
      return 'Order Active';
    }
    case EPreorderChangeAction.WAIT_PAYMENT_ORDER: {
      return 'Order Waiting For Payment';
    }
    case EPreorderChangeAction.CREATED: {
      return 'Update timeline';
    }
    case EPreorderChangeAction.SET_PREORDER_BILLING: {
      return 'Billing company selected';
    }
    case EPreorderChangeAction.SENT_TO_AGENCY_REVIEW: {
      return 'Sent for Agency approval';
    }
    case EPreorderChangeAction.AGENCY_SET_READINESS_DATE: {
      return 'Key Dates update';
    }
    case EPreorderChangeAction.AGENCY_ACCEPTED_PREORDER: {
      return 'Preorder accepted';
    }
    case EPreorderChangeAction.WAITING_CUSTOMER_INVITATION: {
      return 'Waiting Introduction';
    }
    case EPreorderChangeAction.SENT_CUSTOMER_INVITATION:
    case EPreorderChangeAction.CUSTOMER_REGISTERED: {
      return 'Waiting Customer';
    }
    case EPreorderChangeAction.WAITING_CUSTOMER_APPROVAL: {
      return 'Waiting Approval';
    }
    case EPreorderChangeAction.AGENCY_UPDATED_TIMELINE: {
      return 'Timeline updated';
    }
    case EPreorderChangeAction.CUSTOMER_CONFIRMED:
    case EPreorderChangeAction.CUSTOMER_SELECTED_PURCHASE_ORDER:
    case EPreorderChangeAction.CUSTOMER_FINALIZED: {
      return 'Customer Approval';
    }
    case EPreorderChangeAction.LEAD_MANAGER_SET_PAID: {
      return 'Preorder Approval';
    }
    case EPreorderChangeAction.ORDER_CREATED: {
      return 'Preorder Confirmed';
    }
    case EPreorderChangeAction.AGENCY_DECLINED_PREORDER: {
      return 'Agency Decline';
    }
    case EPreorderChangeAction.ALL_AGENCIES_DECLINED_PREORDER: {
      return 'Preorder Declined';
    }
    case EPreorderChangeAction.CANCELLED: {
      return 'Preorder Cancelled';
    }
    case EPreorderChangeAction.UPDATE_TIMELINE: {
      return 'Invoice created';
    }
    default: {
      return snakeCaseToTextFormatter(type);
    }
  }
};
