import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EDraftOrder } from 'store/constants/draft-order';

const assignTalentToDeal = createAsyncThunk(
  EDraftOrder.assignTalentToDeal,
  async (payload: any) => {
    const { data } = await instance.post('draft-orders/assign-talent', payload);

    return data;
  },
);

export default assignTalentToDeal;
