import { EPrepaymentType, EPaymentsKey, TOrderStatus } from 'models/consts';
import { IPurchaseOrder } from './enterprise';
import { IBasicUser } from './user';
import { IAgency } from './agency';
import { CommonEntities, ILevel, ITimeLine } from './common';
import { IWorker } from './consultant';
import { IExtendUserCustomer } from './customer';

import { ICompany, IOrderPrice, IUser } from 'models';
import { IExpertise } from './expertise';
import { IError } from 'models/inner-models';

export interface IFullOrders extends IBasicOrders {
  orderBalance?: IOrderBalance;
  extendHour?: IOrderExtendHours;
  sharedLinks: IOrderSharedLink[];
}

export interface IExtendHours {
  hours: number;
  availability: number;
  endDate: string;
  price?: number;
}

export interface IMonthlyBilling {
  purchaseOrderId?: number;
  paymentTerm?: number;
  contactName?: string;
  contactEmail?: string;
  limit?: number;
  teamId?: number;
}

export interface IPrepayment {
  paymentForm: EPrepaymentType;
  isSubscriptionPaid: boolean;
}

export interface IBillingData {
  id?: number;
  isShare?: boolean;
  city?: string;
  countryIso?: string;
  line1?: string;
  line2?: string;
  postalCode?: string;
  state?: string;
  billingName?: string;
  discountCode?: string;
  monthlyBilling?: IMonthlyBilling;
  prepayment?: IPrepayment;
}

export enum SOURCE_TYPES {
  ORDER_CHARGE = 'order_charge',
  EXTEND_HOURS_CHARGE = 'extend_hours_charge',
  ORDER_UPDATE_CHARGE = 'order_update_charge',
  ORDER_BI_WEEKLY_CHARGE = 'order_bi_weekly_charge',
}

/**
 *   Refactor 12.23
 */
export interface IBasicOrders {
  sharedLinks: any;
  agency: IAgency;
  agencyId: number;
  chat: any;
  company: ICompany;
  companyId: number;
  customPrice: ICustomPrice;
  customPriceId: number;
  customer: IExtendUserCustomer;
  customerId: number;
  customerEmail: string;
  description: string;
  expertise: IExpertise;
  expertiseId: number;
  feedbacks: IOrdersFeedback[]; // Check
  hashCode: string;
  id: number;
  isPaid: boolean;
  isQuick: boolean;
  isSubscriptionPaid?: boolean;
  lastPaidDate: string;
  levels: ILevel;
  levelId: number;
  loading: boolean;
  loaded: boolean;
  name: string;
  orderPrice: IOrderPrice;
  paymentLink?: string;
  price: string;
  purchaseOrder: IPurchaseOrder;
  purchaseOrderId: number;
  status: TOrderStatus;
  taxes: number;
  timeLine: ITimeLine;
  timeLineId: number;
  worker: IWorker;
  workerId: number;
  workerMatching: number;
  customerRate?: number;
  specialistRate?: number;
  user: IBasicUser;
  userId: number;
}

export interface IDraftOrderMetadata {
  companyName: string;
  customerName?: string;
  customerPhone?: string;
  customerEmail?: string;
  durationType?: string;
  duration?: string;
  availabilityType?: string;
  interviewRequired?: boolean;
  backgroundCheckRequired?: boolean;
  timeZone?: string;
  scheduleType?: string;
  reasonForWork?: string;
  reasonSpecialistIsRequired?: string;
  industryRequired?: string;
  industry?: string;
}

export interface IDraftOrder {
  id: number;
  name: string;
  description: string;
  workerMatching: number;
  workerId: number;
  customerEmail: string;
  levelId: number;
  customerId: number;
  customerRate: number;
  specialistRate: number;
  hashCode: string;
  status: TOrderStatus;
  payoutTerms: number;
  addtionalRequirements: string;
  orderId: number;
  customer: IUser;
  user: IUser;
  worker: IUser;
  metadata: IDraftOrderMetadata;
}

export interface IFullOrder {
  extendHours: CommonEntities<IOrderExtendHours[]>;
  feedback: IOrdersFeedback;
  history: CommonEntities<IOrdersHistory[]>;
  order: IBasicOrders;
  orderBalance: IOrderBalance;
  sharedLinks: CommonEntities<IOrderSharedLink[]>;
  weeklyBillings: CommonEntities<IWeeklyBillings[]>;
}

export interface IOrderSharedLink {
  discountCode: number;
  extendHour: any;
  extendHourId: number;
  hashCode: string;
  id: number;
  orderId: number;
  paymentLink: string;
  sourceType: string;
  hours?: number;
  startPeriod?: string;
  endPeriod?: string;
  paymentType?: EPaymentsKey;
  paymentDetails: {
    isSubscriptionPaid: boolean;
    paymentForm: EPrepaymentType;
  };
}

export interface IOrdersFeedback {
  id: number;
  orderId: number;
  order?: IBasicOrders;
  workerId: number;
  feedbackText: string;
  expertiseRate: number;
  communicationRate: number;
  availabilityRate: number;
  deliveryRate: number;
  totalRate: number;
  createdAt: string;
  updatedAt: string;
  isApprove: boolean;
  loading: boolean;
  loaded: boolean;
}

export interface IOrderBalance {
  approvedHours: number;
  availableHours: number;
  balanceAmount: number;
  balanceHours: number;
  billedHours: number;
  discountAmount: number;
  orderId: number;
  payedAmount: number;
  payedHours: number;
  refundedAmount: number;
  refundedHours: number;
  serviceFeeAmount: number;
  transferredAmount: number;
  transferredHours: number;
  loading: boolean;
  loaded: boolean;
}

export interface ICustomPrice {
  id: number;
  priceCents: number;
  priceCurrency: string;
  stripeProductId: string;
  createdAt: string;
  updatedAt: string;
}

export interface ISharedLink {
  hashCode: string;
  id: number;
  orderId: number;
  paymentLink?: string;
}

export interface IOrdersHistory {
  id: number;
  changes: string;
  changesType: string;
  createdAt: string;
  message: string;
  orderId: number;
  updatedAt: string;
  user: IBasicUser;
  userId: number;
  metadata?: any;
}

export interface IWeeklyBillings {
  id: number;
  disputeText: string;
  createdAt: string;
  endDate: string;
  hours: number;
  orderId: number;
  startDate: string;
  status: string;
  updatedAt: string;
  week: number;
  order?: IBasicOrders;
  notes?: string;
}

export interface IOrderExtendHours {
  availability: number;
  createdAt: string;
  endDate: string;
  hours: number;
  id: number;
  isAccept: boolean;
  isNeedPaid: boolean;
  isPaid: boolean;
  isReject: boolean;
  orderId: number;
  price: string;
  updatedAt: string;
  extendHourId?: number;
}

export interface IDraftOrderCreation {
  actionError: IError | null;
  loaded: boolean;
  loading: boolean;
}

export const DRAFT_CREATION_DEFAULT: IDraftOrderCreation = {
  actionError: null,
  loaded: false,
  loading: false,
};

export interface IBillingContact {
  billingContactEmail: string;
  orderId: number;
}
