import React, { useCallback, useEffect, Suspense, lazy, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import { createSearchParams, useLocation, useParams } from 'react-router-dom';
import CustomScroll from 'react-custom-scroll';

import flow from 'lodash/fp/flow';
import split from 'lodash/fp/split';
import map from 'lodash/fp/map';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';

import { StyledStepContainer } from '../styled';

import { ECustomerRegisterModal, ECustomerRegStep } from 'models/consts';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import updateAction from 'store/actions/updateAction';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import { resetMatchedResults } from 'store/reducers/order-creation';

import {
  categoryProductsWithoutDeprecatedSelector,
  modulesByProductSelector,
  productByIdSelector,
  workTypesByProductSelector,
} from 'store/selectors/getCommonSelector';

import Expertise from 'components/OrderCreationComponents/Expertise';
const Timeline = lazy(() => import('components/OrderCreationComponents/Timeline'));
const Seniority = lazy(() => import('components/OrderCreationComponents/Seniority'));
const Recommendation = lazy(() => import('components/OrderCreationComponents/Recommendation'));
const ConfirmOrder = lazy(() => import('views/Orders/ConfirmOrder'));

import Loader from 'components/Loader/Loader';
import ModalCustomerWrapper from 'components/Modal/ModalCustomerWrapper/ModalCustomerWrapper';
import useMatchingParams from '../../hooks/useMatchingParams';

const OrderFlow: React.FunctionComponent = () => {
  const { step = 0 } = useParams();
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  const categoriesWithProductsWithoutDeprecatedList = useAppSelector(
    categoryProductsWithoutDeprecatedSelector,
  );

  const { actions } = useStateMachine({ updateAction });
  const {
    matchingParams: { productId },
  } = useMatchingParams();

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [signupShow, setSignupShow] = useState<null | ECustomerRegisterModal>(null);

  const product = useAppSelector(productByIdSelector(productId));
  const modulesListByProduct = useAppSelector(modulesByProductSelector(productId));
  const worksListByProduct = useAppSelector(workTypesByProductSelector(productId));

  useEffect(() => {
    if (!categoriesWithProductsWithoutDeprecatedList?.length) {
      dispatch(fetchProductsWithModulesWorks());
    }
  }, [categoriesWithProductsWithoutDeprecatedList?.length, dispatch]);

  const generateStateFromParams = useCallback(() => {
    if (search) {
      const searchParams = createSearchParams(search),
        params: any = {};

      for (const [key, value] of searchParams.entries()) {
        if (key === 'workIds' || key === 'moduleIds') {
          // const parseNumbers = flow(split(','), map(Number));
          params[key] = value;
        } else if (+value) {
          params[key] = +value;
        } else if (value === 'false' || value === 'true') {
          params[key] = value === 'true';
        } else if (value !== 'undefined') {
          params[key] = value;
        }
      }
      actions.updateAction({ searchParams: params });
    }
  }, [actions, search]);

  useEffect(() => {
    generateStateFromParams();

    return () => {
      if (+step < ECustomerRegStep.RECOMMENDED_EXPERTS) {
        actions.updateAction({
          expertise: {},
          levelId: 0,
          timeLine: {},
          worker: {},
          searchParams: {},
        });
      } else {
        actions.updateAction({ expertise: {}, levelId: 0, timeLine: {}, searchParams: {} });
      }
      dispatch(resetMatchedResults());
    };
  }, [actions, dispatch, generateStateFromParams, step]);

  const onSignup = useCallback(() => {
    setSignupShow(ECustomerRegisterModal.SIGNUP);
  }, []);

  const renderStep = useCallback(() => {
    switch (+step) {
      case ECustomerRegStep.TIMELINE:
        return <Timeline handleSignup={onSignup} />;
      case ECustomerRegStep.SENIORITY:
        return <Seniority handleSignup={onSignup} product={product} />;
      case ECustomerRegStep.RECOMMENDED_EXPERTS:
        return (
          <Recommendation
            handleSignup={onSignup}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
          />
        );
      case ECustomerRegStep.CONFIRMATION:
        return (
          <ConfirmOrder
            handleSignup={onSignup}
            modulesList={modulesListByProduct}
            product={product}
            worksList={worksListByProduct}
          />
        );
      default:
        return (
          <Expertise
            handleSignup={onSignup}
            modulesList={modulesListByProduct}
            productList={categoriesWithProductsWithoutDeprecatedList}
            worksList={worksListByProduct}
          />
        );
    }
  }, [
    categoriesWithProductsWithoutDeprecatedList,
    modulesListByProduct,
    onSignup,
    openFilter,
    product,
    step,
    worksListByProduct,
  ]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CustomScroll heightRelativeToParent="100%" allowOuterScroll>
        <Container maxWidth="xxl" sx={{ height: '100%', overflowY: 'scroll' }}>
          <StyledStepContainer>
            <Grid container sx={{ justifyContent: 'center', height: '100%' }}>
              <Grid xs={12} display="flex" flexDirection="column">
                <Suspense fallback={<Loader />}>{renderStep()}</Suspense>
              </Grid>
            </Grid>
          </StyledStepContainer>
        </Container>
      </CustomScroll>
      <ModalCustomerWrapper visible={signupShow} close={() => setSignupShow(null)} />
    </Box>
  );
};

export default OrderFlow;
