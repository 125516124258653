import React, { FunctionComponent, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import debounce from 'lodash/debounce';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import acceptUsersOrders from 'store/query/enterprise/acceptUsersOrders';
import searchUsersTeam from 'store/query/enterprise/searchUsersTeam';
import teamUsersById from 'store/query/enterprise/teamUsersById';
import {
  getEnterpriseUsersByPageSelector,
  getSearchEnterpriseUsersSelector,
} from 'store/selectors/getEnterpriseSelector';
import { getMeFullSelector } from 'store/selectors/getUserSelector';

import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { IEnterpriseUser, IPurchaseOrder } from 'store/types/enterprise';
import { ERole } from 'models/consts';

interface IAddMember {
  onClose: () => void;
  order: null | IPurchaseOrder;
}

interface ISubmitForm {
  users: IEnterpriseUser[];
}

const AddMember: FunctionComponent<IAddMember> = ({ onClose, order }) => {
  const [search, setSearch] = useState<string>('');
  const dispatch = useAppDispatch();
  const enterpriseUsers = useAppSelector(getSearchEnterpriseUsersSelector(search));
  const teamUsers = useAppSelector(getEnterpriseUsersByPageSelector(1));
  const me = useAppSelector(getMeFullSelector);

  const users =
    me?.user?.role?.name &&
    [ERole.CUSTOMER_ADMIN, ERole.CUSTOMER_TEAM_ADMIN].includes(me.user.role.name)
      ? enterpriseUsers
      : search
        ? teamUsers
        : [];

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      users: order?.acceptedUsers,
    },
  });

  const onSearch = (searchTerm: any) => {
    dispatch(searchUsersTeam(searchTerm));
  };

  const debouncedSearch = debounce(onSearch, 500);

  const handleUsersSearch = (event: any, value: string) => {
    if (value) {
      let searchTerm: any = {
        filterByEmail: value,
        filterByStatus: true,
        filterByRole: 5,
      };
      if (me?.user?.role?.name === ERole.CUSTOMER_TEAM_ADMIN && !!me?.customer?.teams?.length) {
        searchTerm = {
          ...searchTerm,
          filterByTeam: me.customer.teams[0].id,
        };
      }
      debouncedSearch(searchTerm);
    }
    setSearch(value);
  };

  const onSubmit: SubmitHandler<any> = async (data: ISubmitForm) => {
    const users = data.users.map(({ id }) => ({ userId: id }));

    if (order?.id) {
      await dispatch(acceptUsersOrders([order?.id, users]))
        .unwrap()
        .then(() => onClose());
    }
  };

  return (
    <>
      <DialogContent>
        <form id="add-team-member" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <InputLabel>Email</InputLabel>
            <Controller
              name="users"
              control={control}
              render={({ field }) => (
                <CustomAutocomplete
                  autocompleteProps={{
                    multiple: true,
                    onInputChange: handleUsersSearch,
                    ListboxComponent: ({ id, children, ...props }: any) => (
                      <Typography
                        {...props}
                        key={id}
                        component="ul"
                        sx={{ justifyContent: 'space-between' }}
                      >
                        {children}
                      </Typography>
                    ),
                  }}
                  inputProps={{
                    error: errors.users as any,
                    helperText: (errors.users as any)?.message || 'Incorrect data',
                  }}
                  field={field}
                  options={users || []}
                  placeholder="Type a user email"
                  CustomOptionView={({ option }: any) => (
                    <Typography>
                      <Typography>
                        <b>
                          {option.firstName} {option.lastName}
                        </b>
                      </Typography>
                      <Typography>{option.email}</Typography>
                    </Typography>
                  )}
                />
              )}
            />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isValid || isSubmitting}
          color="secondary"
          form="add-team-member"
          fullWidth
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default AddMember;
