import React, { FunctionComponent } from 'react';

import { StyledBadge } from './styled';

// Generic Badge Component
interface BadgeProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Badge: FunctionComponent<BadgeProps> = ({ style, children }) => {
  return <StyledBadge style={style}>{children}</StyledBadge>;
};
