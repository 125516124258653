import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import instance from '../../middlewares/api';

import { RootState } from 'store/store';
import { EDraftOrder } from 'store/constants/draft-order';
import {
  DraftOrderSalesPhase,
  DraftOrderTalentPhase,
  IDraftOrdersAndCount,
  OrderStatus,
} from 'store/types/draftOrder';

export const DRAFT_ORDERS_LIST_PAGE_SIZE = 6;

const createDraftOrdersSearchQuery = (name: EDraftOrder, salesPhase: string) => {
  return createAsyncThunk<
    AxiosResponse<IDraftOrdersAndCount>,
    {
      talentPhases?: DraftOrderTalentPhase[];
      orderStatuses?: OrderStatus[];
      salesIds?: number[];
      talentUserIds?: number[];
      customerIds?: number[];
      pageAction?: 'next' | 'previous';
    }
  >(
    name,
    async (
      { talentPhases, orderStatuses, salesIds, talentUserIds, customerIds, pageAction },
      { getState },
    ) => {
      const stateName = name.split('/')[1];
      const dirSearchState = (getState() as RootState).draftOrders.tabs[
        stateName as keyof RootState['draftOrders']['tabs']
      ];

      const { page, count } = dirSearchState;

      let newPage = page;

      if (pageAction) {
        newPage += pageAction === 'next' ? 1 : -1;
      }

      const maxPage = Math.ceil(count / DRAFT_ORDERS_LIST_PAGE_SIZE);

      if (newPage > maxPage) {
        newPage = maxPage;
      }

      if (newPage < 1) {
        newPage = 1;
      }

      const offset = (newPage - 1) * DRAFT_ORDERS_LIST_PAGE_SIZE;

      let url: string = `draft-orders?salesPhase=${salesPhase}&limit=${DRAFT_ORDERS_LIST_PAGE_SIZE}&offset=${offset}`;

      if (talentPhases) {
        talentPhases.forEach(phase => {
          url += `&talentPhase=${phase}`;
        });
      }

      if (orderStatuses) {
        orderStatuses.forEach(status => {
          url += `&orderStatuses=${status}`;
        });
      }

      if (salesIds) {
        salesIds.forEach(salesId => {
          url += `&salesId=${salesId}`;
        });
      }

      if (talentUserIds) {
        talentUserIds.forEach(talentUserId => {
          url += `&talentUserId=${talentUserId}`;
        });
      }

      if (customerIds) {
        customerIds.forEach(customerId => {
          url += `&customerId=${customerId}`;
        });
      }

      const resp = await instance.get(url);

      return resp;
    },
  );
};

const staffingDraftOrdersSearchQuery = createDraftOrdersSearchQuery(
  EDraftOrder.staffing,
  DraftOrderSalesPhase.TALENT_STAFFING_60,
);

const recommendedCustomerDraftOrdersSearchQuery = createDraftOrdersSearchQuery(
  EDraftOrder.recommended_customer,
  DraftOrderSalesPhase.RECOMMENDATION_SENT_70,
);

const orderCreatedDraftOrdersSearchQuery = createDraftOrdersSearchQuery(
  EDraftOrder.order_created,
  DraftOrderSalesPhase.VERBAL_AGREEMENT_80,
);

const activeDraftOrdersSearchQuery = createDraftOrdersSearchQuery(
  EDraftOrder.active,
  DraftOrderSalesPhase.CLOSED_WON_PAID_100,
);

const completedDraftOrdersSearchQuery = createDraftOrdersSearchQuery(
  EDraftOrder.completed,
  DraftOrderSalesPhase.CLOSED_WON_PAID_100,
);

const onHoldDraftOrdersSearchQuery = createDraftOrdersSearchQuery(
  EDraftOrder.on_hold,
  DraftOrderSalesPhase.ON_HOLD_15,
);

export {
  staffingDraftOrdersSearchQuery,
  recommendedCustomerDraftOrdersSearchQuery,
  orderCreatedDraftOrdersSearchQuery,
  activeDraftOrdersSearchQuery,
  completedDraftOrdersSearchQuery,
  onHoldDraftOrdersSearchQuery,
};
