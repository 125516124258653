import { useMemo, useState } from 'react';
import dayjs from 'utils/dayjs';

import { calcWeeks } from 'utils/dateFormatter';

import { IBasicOrders } from 'store/types/order';

interface IExtendHoursProps {
  defaultHours?: number;
  defaultEndDate?: string;
  order?: IBasicOrders;
}

const useExtendHours = ({ defaultHours, defaultEndDate, order }: IExtendHoursProps) => {
  const [newAvailability, setNewAvailability] = useState<number>();
  const [newWeeks, setNewWeeks] = useState<number>(0);
  const [newHours, setNewHours] = useState<number>(0);

  const [totalWeeks] = useState<number>(
    calcWeeks(
      dayjs(order?.timeLine.endDate).format('MM/DD/YYYY'),
      dayjs(order?.timeLine.startDate).format('MM/DD/YYYY'),
    ),
  );

  const outstanding = useMemo(() => {
    const currentWeek = dayjs().isSameOrAfter(order?.timeLine.startDate)
      ? dayjs().format('MM/DD/YYYY')
      : order?.timeLine.startDate;
    const currentCountWeeks = calcWeeks(order?.timeLine.endDate, order?.timeLine.startDate);
    const outWeeks = calcWeeks(order?.timeLine.endDate, currentWeek);
    const outHours = outWeeks * ((order?.timeLine?.actualHours || 0) / currentCountWeeks);

    return {
      availability: outHours / outWeeks || 0,
      hours: Math.ceil(outHours),
      weeks: outWeeks,
    };
  }, [order?.timeLine?.actualHours, order?.timeLine.endDate, order?.timeLine.startDate]);

  const updated = useMemo(() => {
    let availability, hours, weeks;

    availability =
      newAvailability || outstanding.availability || order?.timeLine?.availabilityWeek || 0;
    weeks = outstanding.weeks + (newWeeks || 0);
    if (order?.timeLine.flexibleHours) {
      hours = outstanding.hours + (newHours || 0);
    } else {
      hours = newWeeks
        ? (outstanding.weeks + newWeeks) * availability
        : availability * outstanding.weeks;
    }

    return { availability, hours, weeks };
  }, [
    newAvailability,
    newWeeks,
    newHours,
    outstanding,
    order?.timeLine.flexibleHours,
    order?.timeLine.availabilityWeek,
  ]);

  return { totalWeeks, setNewAvailability, setNewWeeks, setNewHours, outstanding, updated };
};

export default useExtendHours;
