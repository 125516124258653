import { NavigateFunction } from 'react-router-dom';

import ROUTES from 'router/constants';

import { DraftOrderTalentPhase, OrderStatus } from 'store/types/draftOrder';
import { IBasicUser } from 'store/types/user';

/**
 * Checks the boolean values of an object and returns the keys with true values.
 * Used in the Deals list filter components to determine which filters are active.
 *
 * @param obj - An object where the keys are strings and the values are booleans.
 * @returns An array of keys with true values, or undefined if all values are true.
 */
export const checkFilterBooleanValues = <T extends string | number | symbol>(
  obj: Record<T, boolean>,
): T[] | undefined => {
  // Get all keys with a true value
  const trueKeys = (Object.keys(obj) as T[]).filter(key => obj[key] === true);

  // If all values are true, return undefined
  if (trueKeys.length === Object.keys(obj).length) {
    return undefined;
  }

  // Otherwise, return the array of keys with true values
  return trueKeys;
};

export const talentPhaseKeys = Object.keys(DraftOrderTalentPhase);

export const orderStatusValues = Object.values(OrderStatus);

//@ts-ignore - API is returning DB column names for these users
export const getFullName = (user: IBasicUser): string => `${user.first_name} ${user.last_name}`;

export const getUserFilterStr = (users: IBasicUser[] | null): Record<string, boolean> => {
  if (!users) {
    return {};
  }

  return users.reduce(
    (obj, user) => {
      obj[`${getFullName(user)}|${user.id}`] = true;
      return obj;
    },
    {} as Record<string, boolean>,
  );
};

export const navigateToDealDetails = (navigate: NavigateFunction, dealId: number) => {
  navigate(ROUTES.DEAL_DETAILS, {
    state: {
      dealId,
    },
  });
};
