import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EProducts } from 'store/constants';
import { ICompany } from 'store/types/company';
import { request } from 'http';

interface RequestProductProps {
  name: string;
  modules: string;
  typesOfWork: string;
  notes: string;
  category: string;
  company: ICompany;
  requestorsName: string;
  requestorsEmail: string;
}

const requestNewProduct = createAsyncThunk(
  EProducts.RequestNewProduct,
  async (props: RequestProductProps, { rejectWithValue }) => {
    try {
      const payload = {
        name: props.name,
        modules: props.modules ?? null,
        typesOfWork: props.typesOfWork ?? null,
        notes: props.notes,
        category: props.category ?? null,
        vendor: props.company ? props.company.name : null,
        requestorsName: props.requestorsName,
        requestorsEmail: props.requestorsEmail,
      };

      const { data } = await instance.post('supports/request-new-product', payload);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.response ? err.response.data : err.message);
    }
  },
);

export default requestNewProduct;
