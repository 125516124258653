import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const StyledRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: 'white',
  flex: '0 1 21%',
  backgroundColor: theme.palette.secondary.main,

  minWidth: 191,
  maxWidth: 191,
  paddingLeft: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    flex: '0 1 auto',
    alignItems: 'center',
    minWidth: 190,
    maxWidth: 'none',
    paddingLeft: theme.spacing(2),
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 296,
    maxWidth: 296,
    paddingLeft: theme.spacing(6),
  },
}));

const StyledButtonWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    my: {
      md: 6,
    },
    minHeight: {
      xs: 48,
      md: 85,
    },
    minWidth: 32,
    ml: {
      xs: -1,
      md: 0,
    },
  }),
);

const StyledIconButton = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    display: {
      md: 'none',
    },
  }),
);

const StyledButton = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    display: {
      xs: 'none',
      md: 'flex',
    },
  }),
);

export { StyledButton, StyledButtonWrap, StyledIconButton, StyledRoot };
