import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EChat } from '../../constants';
import { ERole } from '../../../models/consts';

import { IRole } from '../../types/common';
import { IChatResponse } from '../../types/chat';

interface IParamsProps {
  id?: number | string;
  role: IRole;
}

const fetchChatById = createAsyncThunk<IChatResponse, IParamsProps>(
  EChat.getChatById,
  async ({ id, role }) => {
    let url = `chats/${id}`;

    if ([ERole.AGENCY_ADMIN, ERole.RESOURCE_MANAGER].includes(role.name)) {
      url = `/agency/${url}`;
    } else if (
      [ERole.CUSTOMER_ADMIN, ERole.CUSTOMER, ERole.CUSTOMER_TEAM_ADMIN].includes(role.name)
    ) {
      url = `/enterprise/companies/${url}`;
    }

    const { data } = await instance.get(url);
    return data;
  },
);

export default fetchChatById;
