import { createSlice } from '@reduxjs/toolkit';
import directorySearchQuery from 'store/query/directory-search/directory-flexsearch';

import { CommonEntities } from 'store/types/common';
import { DirectorySearchResult } from 'store/types/directorySearch';

export interface DirectorySearchState {
  directorySearch: CommonEntities<DirectorySearchResult[]>;
  offset: number;
  limit: number;
  countAll?: number;
}

const initialSearch = {
  items: [],
  loaded: false,
  loading: false,
};

const initialState: DirectorySearchState = {
  directorySearch: initialSearch,
  offset: 0,
  limit: 10,
};

const { reducer, actions } = createSlice({
  name: 'directory-search',
  initialState,
  reducers: {
    resetDirectorySearch: state => {
      state.directorySearch = initialSearch;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(directorySearchQuery.pending, state => {
        state.directorySearch = {
          ...state.directorySearch,
          loading: true,
          loaded: false,
        };
      })
      .addCase(directorySearchQuery.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;

        state.directorySearch = {
          items: [...state.directorySearch.items, ...data.results],
          countAll: data.countAll,
          hasMore: data.hasMore,
          loading: false,
          loaded: true,
        };

        state.offset = data.newOffset || 0;
      })
      .addCase(directorySearchQuery.rejected, state => {
        state.directorySearch = {
          ...state.directorySearch,
          loading: false,
          loaded: true,
        };
      }),
});

export const { resetDirectorySearch } = actions;

export default reducer;
