import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const LightBulbIcon: FunctionComponent<ISvgProps> = ({ className }) => {
  return (
    <svg width="50" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 18.8332C9.54167 18.8332 9.14931 18.67 8.82292 18.3436C8.49653 18.0172 8.33333 17.6248 8.33333 17.1665H11.6667C11.6667 17.6248 11.5035 18.0172 11.1771 18.3436C10.8507 18.67 10.4583 18.8332 10 18.8332ZM6.66667 16.3332V14.6665H13.3333V16.3332H6.66667ZM6.875 13.8332C5.91667 13.2637 5.15625 12.4998 4.59375 11.5415C4.03125 10.5832 3.75 9.5415 3.75 8.4165C3.75 6.68039 4.35764 5.2047 5.57292 3.98942C6.78819 2.77414 8.26389 2.1665 10 2.1665C11.7361 2.1665 13.2118 2.77414 14.4271 3.98942C15.6424 5.2047 16.25 6.68039 16.25 8.4165C16.25 9.5415 15.9688 10.5832 15.4062 11.5415C14.8438 12.4998 14.0833 13.2637 13.125 13.8332H6.875ZM7.375 12.1665H12.625C13.25 11.7221 13.7326 11.1734 14.0729 10.5207C14.4132 9.86789 14.5833 9.1665 14.5833 8.4165C14.5833 7.13873 14.1389 6.05539 13.25 5.1665C12.3611 4.27762 11.2778 3.83317 10 3.83317C8.72222 3.83317 7.63889 4.27762 6.75 5.1665C5.86111 6.05539 5.41667 7.13873 5.41667 8.4165C5.41667 9.1665 5.58681 9.86789 5.92708 10.5207C6.26736 11.1734 6.75 11.7221 7.375 12.1665Z"
        fill="#F0B500"
      />
    </svg>
  );
};
