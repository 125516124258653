import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import CloseIcon from 'styles/icons/CloseIcon';
import { ErrorNotificationIcon } from 'styles/icons/ErrorNotificationIcon';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface ICreateDealErrorState {
  title: string;
  errorData?: string[];
  handleClose: () => void;
}

const CreateDealErrorState: FunctionComponent<ICreateDealErrorState> = ({
  title,
  handleClose,
  errorData,
}) => {
  return (
    <Box
      width={'100%'}
      height={'auto'}
      border={1}
      borderLeft={4}
      borderColor={'#F58857'}
      sx={{ backgroundColor: '#FFF2F5', marginTop: '24px' }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        ml={'16px'}
        mr={'16px'}
        mt={'16px'}
      >
        <Box display={'flex'} flexDirection={'row'}>
          <ErrorNotificationIcon />
          <Typography fontSize={'16px'} fontWeight={500} ml={'16px'}>
            {title}
          </Typography>
        </Box>
        <IconButton color="tertiary" size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box ml={'55px'} mb={'16px'}>
        {errorData && (
          <List dense sx={{ padding: 0 }}>
            {errorData?.map((param, index) => (
              <ListItem key={index} sx={{ padding: '2px 0', height: '18px' }}>
                <ListItemIcon sx={{ minWidth: '16px' }}>
                  <FiberManualRecordIcon sx={{ fontSize: '6px', color: '#1F1F1F' }} />
                </ListItemIcon>
                <Typography fontSize={'16px'} fontWeight={400} color={'#1F1F1F'}>
                  {param}
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Box>
  );
};

export default CreateDealErrorState;
