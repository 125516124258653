import { EPreOrderStatus } from 'models/consts';

import { CommonEntities } from './common';
import { IBasicCustomer, IExtendUserCustomer } from './customer';
import { IFullOrders, IOrdersHistory } from './order';
import { IWorker } from './consultant';
import { IBasicUser } from './user';
import { IModuleOfProduct, IProduct, IWorkTypesOfProduct } from './categoryProducts';
import { IChat } from 'models';

export interface IBasicDraftOrder {
  agency: any;
  additionalRequirements: string;
  chat: any;
  companyName: string;
  customer: IExtendUserCustomer;
  customerEmail: string;
  customerId: number;
  customerRate: number;
  description: string;
  hashCode: string;
  id: number;
  levelId: number;
  name: string;
  orderId: number;
  payoutTerms: number;
  specialistRate: number;
  status: EPreOrderStatus;
  user: IBasicUser;
  userId: number;
  worker: IWorker;
  workerId: number;
  workerMatching: number;
}

export interface IFullDraftOrder {
  order: IBasicDraftOrder;
  history: CommonEntities<IOrdersHistory[]>;
}

export interface IDraftOrdersAndCount {
  count: number;
  draftOrders: IDraftOrder[];
  page: number;
}

export interface SalesPhaseCount {
  salesPhase: string | null;
  status?: string;
  count: number;
}

export enum OrderStatus {
  CREATED = 'created',
  EXPIRED = 'expired',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  CANCELED = 'canceled',
  READY_TO_START = 'ready_to_start',
  ACTIVE = 'active',
  ON_HOLD = 'on_hold',
  COMPLETED = 'completed',
  TERMINATED = 'terminated',
  PENDING_CONFIRMATION = 'pending_confirmation',
}

export enum DraftOrderStatus {
  DRAFT = 'DRAFT',
  READY_TO_FINALIZE = 'READY_TO_FINALIZE',
  AWAITING_CUSTOMER_SIGNUP = 'AWAITING_CUSTOMER_SIGNUP',
  ACTIVE = 'ACTIVE',
  ON_HOLD = 'ON_HOLD',
}

export enum DraftOrderSalesPhase {
  LEAD = 'LEAD',
  TALENT_STAFFING_60 = 'TALENT_STAFFING_60',
  RECOMMENDATION_SENT_70 = 'RECOMMENDATION_SENT_70',
  VERBAL_AGREEMENT_80 = 'VERBAL_AGREEMENT_80',
  CLOSED_WON_PAID_100 = 'CLOSED_WON_PAID_100',
  CLOSED_LOST = 'CLOSED_LOST',
  ON_HOLD_15 = 'ON_HOLD_15',
}

export enum DraftOrderTalentPhase {
  INTERNAL_LIST = 'INTERNAL_LIST',
  SURGE = 'SURGE',
  RECOMMENDED = 'RECOMMENDED',
  PRESENT_TO_CLIENT = 'PRESENT_TO_CLIENT',
  ADDITIONAL_BGC = 'ADDITIONAL_BGC',
  INTERVIEW = 'INTERVIEW',
  ENGAGED = 'ENGAGED',
  REJECTED = 'REJECTED',
}

export const DraftOrderTalentPhaseLabels = {
  [DraftOrderTalentPhase.INTERNAL_LIST]: 'Internal List',
  [DraftOrderTalentPhase.SURGE]: 'Surge',
  [DraftOrderTalentPhase.RECOMMENDED]: 'Recommended',
  [DraftOrderTalentPhase.PRESENT_TO_CLIENT]: 'Present to Client',
  [DraftOrderTalentPhase.ADDITIONAL_BGC]: 'Additional BGC',
  [DraftOrderTalentPhase.INTERVIEW]: 'Interview',
  [DraftOrderTalentPhase.ENGAGED]: 'Engaged',
  [DraftOrderTalentPhase.REJECTED]: 'Rejected',
};

export const OrderStatusLabels = {
  [OrderStatus.CREATED]: 'Created',
  [OrderStatus.EXPIRED]: 'Expired',
  [OrderStatus.WAITING_FOR_PAYMENT]: 'Waiting for Payment',
  [OrderStatus.CANCELED]: 'Canceled',
  [OrderStatus.READY_TO_START]: 'Ready to Start',
  [OrderStatus.ACTIVE]: 'Active',
  [OrderStatus.ON_HOLD]: 'On Hold',
  [OrderStatus.COMPLETED]: 'Completed',
  [OrderStatus.TERMINATED]: 'Terminated',
  [OrderStatus.PENDING_CONFIRMATION]: 'Pending Confirmation',
};

export interface IDraftOrder {
  readonly id: number;
  hashCode?: number;
  status?: DraftOrderStatus;
  name: string;
  description?: string;
  workerMatching?: number;
  salesPhase?: string;
  talentPhase?: DraftOrderTalentPhase;
  workerId?: number;
  customerId?: number;
  levelId?: number;
  customerRate?: number;
  specialistRate?: number;
  payoutTerms?: number;
  userId: number;
  talentUserId?: number;
  additionalRequirements?: string;
  orderId?: number;
  hubspotDealId?: string;
  metadata?: IDraftOrdersMetadata;
  createdAt: Date;
  updatedAt?: Date;

  // Relationships
  worker?: IWorker;
  user: IBasicUser;
  customer?: IBasicCustomer;
  talentUser?: IBasicUser;
  expertise?: IPreOrdersExpertise;
  timeLine?: IPreOrdersTimeLines;
  levels?: ILevels;
  chat: IChat;
  order?: IFullOrders;
}

export class IDraftOrdersMetadata {
  companyName?: string;
  customerName?: string;
  customerPhone?: string;
  customerEmail?: string;
  durationType?: string;
  duration?: string;
  availabilityType?: string;
  interviewRequired?: boolean;
  backgroundCheckRequired?: boolean;
  timeZone?: string;
  scheduleType?: string;
  reasonForWork?: string;
  reasonSpecialistIsRequired?: string;
  industryRequired?: string;
  industry?: string;
  customRate?: boolean;
}

export interface IPreOrdersExpertise {
  readonly id: number;
  productId: number;
  preOrdersId?: number;
  draftOrdersId?: number;

  // Relationships
  product: IProduct;
  modules: IModuleOfProduct[];
  works: IWorkTypesOfProduct[];
}

export interface IPreOrdersTimeLines {
  readonly id: number;
  allHours?: number;
  startDate?: Date;
  endDate?: Date;
  availabilityWeek?: number;
  flexibleHours?: boolean;
  preOrdersId?: number;
  draftOrdersId?: number;
}

export interface ILevels {
  readonly id: number;
  name: string;
  description?: string;
  shortDescription: string;
  cost: number;
  minMonth: number;
  maxMonth: number;
}
