import React, { FunctionComponent, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import Grid from '@mui/material/Unstable_Grid2';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import { useAppSelector, useAppDispatch } from 'store/hooks';

import { EOrderFilter, ERole, ESorting, LIMITS } from 'models/consts';
import { purchaseOrderSelector } from 'store/selectors/getEnterpriseSelector';
import { orderListSelector, isOrdersLoadingSelector } from 'store/selectors/getOrderSelector';

import { useParams } from 'react-router-dom';
import fetchPurchaseOrder from 'store/query/enterprise/fetchPurchaseOrder';
import Loader from 'components/Loader/Loader';
import { OrderItem } from 'components/Order';
import { IBasicOrders } from 'store/types/order';
import dayjs from 'dayjs';
import { AddButton } from 'views/Auth/ConsultantRegister/Profile/styled';
import ModalWrapper from 'components/Modal/Modal';
import AddMember from 'components/PurchaseManageComponents/AddMember';
import { dollarFormat } from 'utils/textFormatter';
import fetchOrderList from 'store/query/order/fetchOrderList';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';
import fetchPurchaseOrderArchive from 'store/query/order/fetchPurchaseOrderArchieve';
import { Button } from '@mui/material';
import ManageInvoicesTable from 'components/PurchaseManageComponents/ManageInvoicesTable';
import { FormProvider, useForm } from 'react-hook-form';
import getInvoices from 'store/query/enterprise/getInvoices';

const PurchaseDetails: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const ordersListKey = `purchaseDetail:${id}:${ESorting.ASC}`;

  const orderList = useAppSelector(orderListSelector(ordersListKey));
  const orderListLoading = useAppSelector(isOrdersLoadingSelector(ordersListKey));

  const purchaseOrder = useAppSelector(purchaseOrderSelector(id));
  const role = useAppSelector(getMeRoleSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      property: undefined,
      type: 'asc',
    },
  });

  useEffect(() => {
    if (!!id && !purchaseOrder) {
      dispatch(fetchPurchaseOrder(+id))
        .unwrap()
        .finally(() => setLoading(false));
    }
  }, [dispatch, id, orderList?.length, ordersListKey, purchaseOrder, role]);

  useEffect(() => {
    if (purchaseOrder?.id) {
      dispatch(
        fetchOrderList({
          params: {
            limit: LIMITS.order,
            offset: 0,
            sortStartDate: ESorting.ASC,
            addStatuses: [
              'completed',
              'terminated',
              'terminated_during_trial',
              'expired',
              'canceled',
            ],
          },
          id: purchaseOrder?.id,
          filter: EOrderFilter.PURCHASE_ORDERS,
          key: ordersListKey,
          role,
        }),
      );
      dispatch(
        getInvoices({
          limit: LIMITS.order,
          offset: 0,
          id: purchaseOrder?.id,
          key: ordersListKey,
        }),
      );
    }
  }, [dispatch, ordersListKey, purchaseOrder?.id, role]);

  const manageUsers = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDownloadFile = () => {
    if (purchaseOrder?.id) {
      dispatch(fetchPurchaseOrderArchive(purchaseOrder.id))
        .unwrap()
        .then(({ data }: any) => {
          if (data) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }));
            link.download = `payment-authorization-${purchaseOrder.id}.zip`;
            link.click();
          }
        })
        .catch(error => console.error('Error:', error));
    }
  };

  if (loading && !purchaseOrder?.id) {
    return <Loader />;
  }

  return (
    !!purchaseOrder?.id && (
      <Box>
        <Typography sx={{ mt: { xs: 2.5, md: 5 }, mb: 2.5 }} variant="h1">
          {purchaseOrder.name}
        </Typography>
        <Grid container>
          <Grid lg={4} sm={6} xxl={3} xs={12}>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
              <Typography component="span">Status</Typography>
              <Chip
                className={`${purchaseOrder.status}-enterprise`}
                label={purchaseOrder.status}
                sx={{ textTransform: 'capitalize' }}
                variant="filled"
              />
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
              <Typography component="span">Total</Typography>
              <Typography component="span">
                <Tooltip title="USD">
                  <span>$</span>
                </Tooltip>
                {dollarFormat(purchaseOrder.amountCents / 100)}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
              <Typography component="span">Allocated</Typography>
              <Typography component="span">
                <Tooltip title="USD">
                  <span>$</span>
                </Tooltip>
                {dollarFormat(purchaseOrder.allocateBalanceCents / 100)}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
              <Typography component="span">Unallocated</Typography>
              <Typography component="span">
                <Tooltip title="USD">
                  <span>$</span>
                </Tooltip>
                {dollarFormat(purchaseOrder.balanceCents / 100)}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
              <Typography component="span">Invoiced-to-date</Typography>
              <Typography component="span">
                <Typography component="span">
                  <Tooltip title="USD">
                    <span>$</span>
                  </Tooltip>
                  {dollarFormat(purchaseOrder.invoicedTotalAmountCents / 100)}
                </Typography>
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
              <Typography component="span">Dates</Typography>
              <Typography component="span">
                {`${dayjs(purchaseOrder.startDate).format('MM/DD/YYYY')} - `}
                {dayjs(purchaseOrder.endDate).format('MM/DD/YYYY')}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
              <Typography component="span">Users</Typography>
              <Box>
                <Chip
                  color="secondary"
                  label={purchaseOrder.acceptedUsers?.length}
                  variant="outlined"
                />
                {[ERole.CUSTOMER_ADMIN, ERole.CUSTOMER_TEAM_ADMIN].includes(role.name) && (
                  <AddButton
                    variant="text"
                    color="secondary"
                    onClick={manageUsers}
                    disableRipple
                    startIcon={<AddIcon />}
                    sx={{ ml: 2 }}
                    size="small"
                  >
                    Manage users
                  </AddButton>
                )}
              </Box>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Typography component="span">Actions</Typography>
              <Box>
                {!!purchaseOrder.privateFiles?.length && (
                  <>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => handleDownloadFile()}
                      sx={{ padding: 0, minWidth: 'unset', height: 'auto' }}
                    >
                      <DownloadIcon />
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Typography sx={{ mt: { xs: 2.5, md: 2 }, mb: 2 }} variant="h1">
            Orders
          </Typography>
          {!orderListLoading ? (
            <>
              {!!orderList.length ? (
                <Grid container spacing={3} sx={{ mt: { xs: 0.5 } }}>
                  {orderList?.map(item => (
                    <Grid lg={4} sm={6} xxl={3} xs={12} key={`order-${item.id}`}>
                      <OrderItem order={item as IBasicOrders} filter={EOrderFilter.PURCHASE} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>No orders</Typography>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Box>
        <Box>
          <Typography sx={{ mt: { xs: 2.5, md: 4 }, mb: 2 }} variant="h1">
            Invoices
          </Typography>
          <FormProvider {...methods}>
            <ManageInvoicesTable />
          </FormProvider>
        </Box>
        <ModalWrapper
          dialogProps={{
            open: isOpen,
            onClose: handleClose,
          }}
          title={{ title: 'Add New Users' }}
        >
          <AddMember onClose={() => handleClose()} order={purchaseOrder} />
        </ModalWrapper>
      </Box>
    )
  );
};

export default PurchaseDetails;
