import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const ErrorNotificationIcon: FunctionComponent<ISvgProps> = ({ className }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83268 10.8333H14.166V9.16663H5.83268V10.8333ZM9.99935 18.3333C8.84657 18.3333 7.76324 18.1145 6.74935 17.677C5.73546 17.2395 4.85352 16.6458 4.10352 15.8958C3.35352 15.1458 2.75977 14.2638 2.32227 13.25C1.88477 12.2361 1.66602 11.1527 1.66602 9.99996C1.66602 8.84718 1.88477 7.76385 2.32227 6.74996C2.75977 5.73607 3.35352 4.85413 4.10352 4.10413C4.85352 3.35413 5.73546 2.76038 6.74935 2.32288C7.76324 1.88538 8.84657 1.66663 9.99935 1.66663C11.1521 1.66663 12.2355 1.88538 13.2493 2.32288C14.2632 2.76038 15.1452 3.35413 15.8952 4.10413C16.6452 4.85413 17.2389 5.73607 17.6764 6.74996C18.1139 7.76385 18.3327 8.84718 18.3327 9.99996C18.3327 11.1527 18.1139 12.2361 17.6764 13.25C17.2389 14.2638 16.6452 15.1458 15.8952 15.8958C15.1452 16.6458 14.2632 17.2395 13.2493 17.677C12.2355 18.1145 11.1521 18.3333 9.99935 18.3333Z"
        fill="#EB6C33"
      />
    </svg>
  );
};
