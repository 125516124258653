import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import ShadowBox from '../ShadowBox';
import { Select } from 'components/CustomFields';

import { Box, Typography } from '@mui/material';
import { ERole, getNameByRoleId, TALENT_PHASES } from 'models/consts';
import UserRow from 'components/Table/UserCell';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDraftOrderDetailsSelector } from 'store/selectors/getDraftOrdersSelector';
import fetchUsersList from 'store/query/user/fetchUsersList';
import { outputQuery } from 'helpers/decorators';
import assignTalentToDeal from 'store/query/draft-order/assignTalentToDeal';
import fetchDealDetailsById from 'store/query/draft-order/fetchDealDetailsById';

const TalentSection: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [talentUserData, setTalentUserData] = useState<any[]>([]);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { talentPhase } = watch();
  const { items, loading, loaded } = useAppSelector(getDraftOrderDetailsSelector);

  useEffect(() => {
    dispatch(
      fetchUsersList({
        filter: outputQuery({
          filterByRole: getNameByRoleId.find(item => item.name === 'Talent')?.id,
        }),
        limit: 100,
      }),
    ).then((data: any) => {
      setTalentUserData(data?.payload?.users || []);
    });
  }, [dispatch]);

  const handleAssignUser = (talentUserId: number | null) => {
    if (items?.id) {
      dispatch(assignTalentToDeal({ id: items.id, talentUserId: talentUserId })).then(response => {
        if (response) {
          dispatch(fetchDealDetailsById({ id: items?.id }));
        }
      });
    }
  };

  return (
    <>
      {items && (
        <ShadowBox>
          <Typography fontSize={24} fontWeight={700}>
            Talent
          </Typography>
          <Box display="flex" flexDirection={'column'} gap={1} mt={2}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Talent stage
            </Typography>
            <Controller
              control={control}
              name="talentPhase"
              render={({ field: { ref, value, onChange, ...field } }) => (
                <Select
                  {...field}
                  value={value}
                  inputRef={ref}
                  label="Select the deal phase"
                  options={TALENT_PHASES}
                  onChange={(event: any) => {
                    onChange(event.target.value);
                    setValue('talentPhase', event.target.value);
                  }}
                />
              )}
            />
          </Box>
          <Box display="flex" flexDirection={'column'} gap={1} mt={2}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Assignee
            </Typography>
            <Controller
              control={control}
              name="assignee"
              render={({ field: { ref, value, onChange, ...field } }) => (
                <Box
                  sx={{
                    overflow: 'visible',
                    borderColor: '#1F1F1F',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '48px',
                  }}
                >
                  <UserRow
                    user={items?.talentUser}
                    onAssignUser={handleAssignUser}
                    usersData={talentUserData}
                    nameDisplayLength={100}
                    cellsx={{ padding: '16px 2px', marginX: 0 }}
                  />
                </Box>
              )}
            />
          </Box>
        </ShadowBox>
      )}
    </>
  );
};

export default TalentSection;
