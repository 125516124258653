import { styled, Box } from '@mui/material';

const ListItem = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '13px 16px',
    fontSize: '16px',
    color: '#1f1f1f',
  }),
);

const Checkbox = styled(Box)<{ checked?: boolean }>(({ checked, theme }) =>
  theme.unstable_sx({
    width: '20px',
    height: '20px',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: checked ? '#1f1f1f' : 'transparent',
    border: checked ? 'none' : '1px solid #d4d4d4',
    color: '#fff',
  }),
);

export { ListItem, Checkbox };
