import React, { FunctionComponent, useState } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import { Alert, Box, Snackbar, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import ShadowBox from '../ShadowBox';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';

import { ContentCopyIcon } from 'styles/icons/ContentCopyIcon';

interface ICustomerInformation {
  DealDeatils: any;
}

const CustomerInformation: FunctionComponent<ICustomerInformation> = ({ DealDeatils }) => {
  const [openClipboard, setOpenClipboard] = useState(false);

  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isMobileNative = Capacitor.isNativePlatform();

  const customerInfo = [
    { label: 'Company Name', value: DealDeatils.companyName, clipboardEnabled: false },
    { label: 'Customer Name', value: DealDeatils.customerName, clipboardEnabled: true },
    { label: 'Phone Number', value: DealDeatils.phoneNumber, clipboardEnabled: false },
    { label: 'Email', value: DealDeatils.email, clipboardEnabled: true },
  ];

  const handleClick = async (clipboardText: string) => {
    try {
      if (isMobileNative) {
        await Clipboard.write({ string: clipboardText });
        setOpenClipboard(true);
      } else if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(clipboardText);
        setOpenClipboard(true);
      } else {
        throw new Error('Clipboard API is unavailable');
      }
    } catch (err) {
      ToastService.error(
        ToastMessage({
          title: 'Error',
          body: 'Unable to copy to clipboard. Please try again later!',
        }),
      );
      console.error('Failed to copy with clipboard:', err);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenClipboard(false);
  };

  return (
    <ShadowBox>
      <Typography fontSize={24} fontWeight={700}>
        Customer Information
      </Typography>
      <Box mt={2}>
        {customerInfo.map((info, index) => (
          <Box mb={'16px'} key={index}>
            <Typography fontSize={14} fontWeight={400} color={'#5D5D5E'}>
              {info.label}
            </Typography>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography fontSize={16} fontWeight={400} color={'#1F1F1F'} mr={'8px'}>
                {info.value ? info.value : '-'}
              </Typography>
              {info.clipboardEnabled && (
                <IconButton size="small" onClick={() => handleClick(info.value)}>
                  <ContentCopyIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        ))}
        <Snackbar
          open={openClipboard}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleClose}
            sx={{
              backgroundColor: '#FFCA28',
              width: '100%',
              color: '#000',
              marginTop: isiPadOrMobile ? 10 : undefined,
            }}
          >
            Copied to clipboard!
          </Alert>
        </Snackbar>
      </Box>
    </ShadowBox>
  );
};

export default CustomerInformation;
