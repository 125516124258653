import React, { FunctionComponent, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import { StyledHeaderWrap } from './styled';
import { Button } from '@mui/material';

import ArrowLeftIcon from 'styles/icons/ArrowLeft';
import ROUTES from 'router/constants';
import { useGoBack } from 'components/Sidebar/Components/navigationUtils';
import IconButton from '@mui/material/IconButton';

interface IHeaderFlow {
  id?: string;
  dataTest?: string;
  disableBtn?: boolean;
  formName?: string;
  filterBtn?: any;
  loading?: boolean;
  btn?: string;
  title: ReactNode | string;
  subTitle?: ReactNode | string;
  setOpenCancel: (open: boolean) => void;
}

const HeaderFlow: FunctionComponent<IHeaderFlow> = ({
  id,
  dataTest,
  disableBtn,
  filterBtn,
  formName,
  loading,
  btn,
  title,
  setOpenCancel,
  subTitle,
}) => {
  const navigate = useNavigate();
  const { step } = useParams();

  const isOrderCreationFlow = location.pathname?.includes(ROUTES.ORDER_CREATION);
  const isAgencyCreationFlow = location.pathname?.includes(ROUTES.AGENCY_CREATION);

  const goBack = useGoBack({
    step,
    isOrderCreationFlow,
  });

  const shouldShowBackButton = (isOrderCreationFlow && step && +step > 1) || isAgencyCreationFlow;

  const dataTestId = isOrderCreationFlow ? 'order-flow-back-button' : 'agency-flow-back-button';

  return (
    <StyledHeaderWrap container maxWidth={'auto'}>
      <Grid
        xs={12}
        justifyContent="space-between"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
      >
        {shouldShowBackButton && (
          <IconButton
            data-test={dataTestId}
            id={dataTestId}
            type="submit"
            onClick={goBack}
            color="primary"
            sx={{ position: 'relative', right: 16 }}
          >
            <ArrowLeftIcon />
          </IconButton>
        )}
        <Box display="flex" flex="1 0 0" flexDirection="column">
          {title && (
            <Typography id={id} data-test={dataTest} variant="h1">
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography component="div" sx={{ fontSize: '1.125rem' }}>
              {subTitle}
            </Typography>
          )}
        </Box>
        {filterBtn}
        <Box display={{ xs: 'none', xl: 'flex' }}>
          {isOrderCreationFlow && (
            <Button
              data-test="header-flow-button"
              variant="outlined"
              color="secondary"
              onClick={() => setOpenCancel(true)}
              sx={{ ml: 2 }}
            >
              Cancel
            </Button>
          )}
          {btn && (
            <LoadingButton
              data-test="header-flow-button"
              loading={loading}
              form={formName || 'hook-form'}
              type="submit"
              disabled={disableBtn}
              variant="contained"
              sx={{ ml: 2 }}
            >
              {btn}
            </LoadingButton>
          )}
        </Box>
      </Grid>
    </StyledHeaderWrap>
  );
};

export default HeaderFlow;
