import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled(MuiAccordion)({
  border: 'none',
  boxShadow: 'none',
  margin: 0,
  '&.Mui-expanded': {
    margin: 0,
  },
  // '&.Mui-expanded::before': {
  //   opacity: 1
  // },
});

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) =>
  theme.unstable_sx({
    padding: 0,
    paddingTop: 1.5,
    paddingBottom: 1.5,
    border: 'none',
    minHeight: 0,
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content': {
      // display: 'block',
      my: 0,
      [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
      },
      '&.Mui-expanded': {
        my: 0,
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      alignSelf: {
        xs: 'start',
        lg: 'center',
      },
      mt: 0,
    },
  }),
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) =>
  theme.unstable_sx({
    margin: 0,
    padding: 0,
    paddingTop: 1.5,
    paddingBottom: 1.5,
  }),
);

export { Accordion, AccordionSummary, AccordionDetails };
