import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import instance from 'store/middlewares/api';
import { EDraftOrder } from 'store/constants/draft-order';
import { SalesPhaseCount } from 'store/types/draftOrder';

export default createAsyncThunk<AxiosResponse<SalesPhaseCount[]>>(EDraftOrder.count, () =>
  instance.get('draft-orders/count'),
);
