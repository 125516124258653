import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const ContentCopyIcon: FunctionComponent<ISvgProps> = ({ className }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 12C5.63333 12 5.31944 11.8694 5.05833 11.6083C4.79722 11.3472 4.66667 11.0333 4.66667 10.6666V2.66665C4.66667 2.29998 4.79722 1.98609 5.05833 1.72498C5.31944 1.46387 5.63333 1.33331 6 1.33331H12C12.3667 1.33331 12.6806 1.46387 12.9417 1.72498C13.2028 1.98609 13.3333 2.29998 13.3333 2.66665V10.6666C13.3333 11.0333 13.2028 11.3472 12.9417 11.6083C12.6806 11.8694 12.3667 12 12 12H6ZM6 10.6666H12V2.66665H6V10.6666ZM3.33333 14.6666C2.96667 14.6666 2.65278 14.5361 2.39167 14.275C2.13056 14.0139 2 13.7 2 13.3333V3.99998H3.33333V13.3333H10.6667V14.6666H3.33333Z"
        fill="#5D5D5E"
      />
    </svg>
  );
};
