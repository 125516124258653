import { ERole } from 'models/consts';
import ROUTES from 'router/constants';

export const redirectBasedOnUserTypeStatus = (user: any, navigate: any, redirectPath?: string) => {
  if (redirectPath) {
    return navigate(redirectPath);
  }

  const roleName = user?.role?.name || user?.user?.role?.name;

  const isCustomer = [
    ERole.CUSTOMER,
    ERole.SELF_CUSTOMER,
    ERole.CUSTOMER_ADMIN,
    ERole.CUSTOMER_TEAM_ADMIN,
  ].includes(roleName);

  if (isCustomer && !user.customer?.approveAccess) {
    navigate(ROUTES.CUSTOMER_REGISTRATION);
    return;
  }

  if (isCustomer) {
    navigate(`${ROUTES.ORDER_CREATION}/1`);
    return;
  }

  const isSales = roleName === ERole.SALES;
  const isTalent = roleName === ERole.TALENT;

  if (isSales || isTalent) {
    navigate(ROUTES.TALENT_SEARCH_LANDING);
    return;
  }

  const isConsultant = roleName === ERole.CONSULTANT;

  if (!isConsultant) {
    navigate(ROUTES.ORDER_LIST);
    return;
  }

  const consultant = user?.consultant;
  const isApproved = consultant?.isApproved;
  const isConfirmed = consultant?.isConfirm;
  const callScheduled = consultant?.callScheduled;
  const registrationStep = consultant?.registrationStep;
  const agencyId = consultant?.agencyId || user?.agencyId;

  if (!isConfirmed) {
    navigate(ROUTES.SIGNUP_CODE);
  } else if (isApproved) {
    navigate(ROUTES.ORDER_LIST);
  } else if (!isApproved && (callScheduled || callScheduled === null)) {
    navigate(ROUTES.CONSULTANT);
  } else {
    const registrationPath = `${ROUTES.CONSULTANT_REGISTRATION}/${registrationStep + 1}`;
    const state = agencyId ? { state: { isAgencyAddingUser: true } } : {};
    navigate(registrationPath, state);
  }
};
