import { styled, Button, Box } from '@mui/material';
import { Row } from 'components/Table/styled';

const FooterBar = styled(Row)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

const PaginationButton = styled('button')(({ theme }) =>
  theme.unstable_sx({
    cursor: 'pointer',
    color: 'var(--Colors-Text-text-primary, #000)',
    backgroundColor: 'var(--Colors-Background-background-primary, #FFF)',
    border: '1px solid var(--Gray-600, #5D5D5E)',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: 'var(--Colors-Text-text-secondary, #3B3B3B)',
      color: 'var(--Colors-Background-background-primary, #FFF)',
    },
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    margin: '14px 24px',
  }),
);

const Caption = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    left: '47%',
    textAlign: 'center',
    fontSize: '14px',
    color: 'background: var(--Colors-Text-text-secondary, #3B3B3B)',
  }),
);

export { FooterBar, PaginationButton, Caption };
