import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { StyledRoot } from './styled';

import { LogoWhite } from 'styles/icons/LogoWhite';

import Stepper from './Components/Stepper';
import ROUTES from 'router/constants';
import { Box } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { StyledDiv } from 'components/Header/styled';

const Sidebar: FC<{ steps: any[] }> = ({ steps }) => {
  const location = useLocation();
  const isSpecialistRegistrationFlow = location.pathname?.includes(ROUTES.CONSULTANT_REGISTRATION);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledRoot sx={{ paddingLeft: 3 }}>
      {isSpecialistRegistrationFlow && !mobile && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '62px',
          }}
          ml={-3}
          mb={4}
        >
          <StyledDiv sx={{ justifyContent: 'center' }}>
            <LogoWhite />
          </StyledDiv>
        </Box>
      )}
      <Stepper steps={steps} />
    </StyledRoot>
  );
};

export default Sidebar;
