import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';

const ShadowBox: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        padding: '24px',
        border: '1px',
        borderColor: 'rgba(231, 231, 231, 0.7)',
        backgroundColor: 'background.paper',
      }}
    >
      {children}
    </Box>
  );
};

export default ShadowBox;
