import React from 'react';
import { Link } from 'react-router-dom';
import { Conversation } from '@twilio/conversations';

import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import MuiLink from '@mui/material/Link';

import { useAppSelector } from 'store/hooks';
import { IChatResponse } from 'store/types/chat';
import {
  isAgencyAdministratorsSelector,
  isConsultantSelector,
} from 'store/selectors/getUserSelector';

import ConversationsListItem from './ConversationsListItem';
import ROUTES from 'router/constants';

interface IConversationsList {
  chatList: IChatResponse[];
  conversations: Map<string, Conversation>;
}

const ConversationsList: React.FunctionComponent<IConversationsList> = ({
  chatList,
  conversations,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const isAgency = useAppSelector(isAgencyAdministratorsSelector);
  const isConsultant = useAppSelector(isConsultantSelector);

  return (
    <>
      {chatList.length ? (
        <List sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
          {chatList.map(item => (
            <ConversationsListItem
              key={item.chat.id}
              itemId={item.chat.id}
              order={item.chat.order || item.chat.preOrder || item.chat.draftOrder}
              conversationItem={conversations.get(item.chat.chatSId)}
            />
          ))}
        </List>
      ) : (
        <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '1rem', width: 220, textAlign: 'center' }}>
            Your list of messages will be displayed here.
          </Typography>
          {mobile && (
            <>
              {isConsultant ? (
                <Typography fontSize={'1rem'} width={300} textAlign="center">
                  {'To start a chat, go to '}
                  <MuiLink component={Link} to={ROUTES.ORDER_LIST} variant="body1">
                    My Orders
                  </MuiLink>
                  {' and select needed Customer.'}
                </Typography>
              ) : (
                <Typography fontSize={'1rem'} width={300} textAlign="center">
                  {'To be able to use Chat, '}
                  {isAgency ? (
                    'Create an Order'
                  ) : (
                    <MuiLink
                      id="create-an-order"
                      component={Link}
                      to={ROUTES.ORDER_CREATION + '/1'}
                      variant="body1"
                    >
                      Create an Order
                    </MuiLink>
                  )}
                  {' with a Specialist first.'}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ConversationsList;
