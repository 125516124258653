import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledStepContainer = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  maxWidth: 1076,
  height: '100%',
}));

export { StyledStepContainer };
