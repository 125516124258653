import React, { FunctionComponent } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { useAppSelector } from 'store/hooks';
import { getDraftOrderDetailsSelector } from 'store/selectors/getDraftOrdersSelector';
import { getReasonForWorkLabel, getReasonSpecialistIsRequiredeLabel } from '../../util';

const AdditionalRequirementsSection: FunctionComponent = () => {
  const { items, loading, loaded } = useAppSelector(getDraftOrderDetailsSelector);

  return (
    <>
      {items && (
        <Box paddingLeft={'24px'} paddingRight={'24px'} paddingBottom={'16px'}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Reason for Work
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.metadata?.reasonForWork
                  ? getReasonForWorkLabel(items?.metadata?.reasonForWork)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Industry
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.metadata?.industry ? items?.metadata?.industry : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Reason Specialist is Required
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.metadata?.reasonSpecialistIsRequired
                  ? getReasonSpecialistIsRequiredeLabel(items?.metadata?.reasonSpecialistIsRequired)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Interview Required
              </Typography>
              <Typography fontSize="16px" fontWeight={400} color="#1F1F1F">
                {items?.metadata?.interviewRequired === true
                  ? 'Yes'
                  : items?.metadata?.interviewRequired === false
                    ? 'No'
                    : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Industry Expertise Required
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.metadata?.industryRequired ? items?.metadata?.industryRequired : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={'14px'} fontWeight={400} color="#5D5D5E">
                Background Check Required
              </Typography>
              <Typography fontSize={'16px'} fontWeight={400} color="#1F1F1F">
                {items?.metadata?.backgroundCheckRequired === true
                  ? 'Yes'
                  : items?.metadata?.backgroundCheckRequired === false
                    ? 'No'
                    : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AdditionalRequirementsSection;
