import React, {
  FunctionComponent,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';

import isEqual from 'lodash/fp/isEqual';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppSelector } from 'store/hooks';
import {
  getSpecialistSelector,
  getMeSelector,
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
} from 'store/selectors/getUserSelector';
import updateAction from 'store/actions/updateAction';

import { StyledStepContainer } from '../../styled';
import ArrowLeftIcon from 'styles/icons/ArrowLeft';

const ConsultantProfile = lazy(() => import('./Profile/ConsultantProfile'));
const ConsultantFullProfile = lazy(() => import('./Profile/ConsultantFullProfile'));
const ProductsExperienses = lazy(
  () => import('../../../components/ConsultantComponents/Products/ProductsExperienses'),
);
const ProductsExpertises = lazy(
  () => import('../../../components/ConsultantComponents/Products/ProductsExpertises'),
);

import { EConsultantRegStep, ERole } from 'models/consts';
import { IBasicConsultant } from 'store/types/consultant';
import { IBasicUser } from 'store/types/user';
import { EWorkEligibilityType } from 'models/consts';

import Loader from 'components/Loader/Loader';
import { StyledCustomScroll } from './styled';
import ConnectWithARep from './ConnectWithARep';

import ROUTES from 'router/constants';
import Availability from './Availability/Availability';
import { useGoBack } from 'components/Sidebar/Components/navigationUtils';
import { StyledHeaderWrap } from 'components/Header/HeaderFlow/styled';
import { StyledAppBar } from 'components/OrderCreationComponents/BottomButton';

const ConsultantRegister: FunctionComponent = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const { step = 0 } = useParams();
  const navigate = useNavigate();
  const methods = useForm<any>({ mode: 'onChange' });
  const isOrderCreationFlow = location.pathname?.includes(ROUTES.ORDER_CREATION);

  const me = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getSpecialistSelector) as IBasicConsultant;
  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);

  const goBack = useGoBack({
    step,
    isOrderCreationFlow,
  });

  const {
    formState: { isValid, isSubmitting, errors, isDirty },
    getValues,
  } = methods;

  const {
    actions,
    state: { experiences, expertise },
  } = useStateMachine({ updateAction });

  const [bypassIsValid, setBypassIsValid] = useState(false);

  const isSkip =
    bypassIsValid &&
    getValues().experiences &&
    getValues().experiences.length === 1 &&
    !getValues().experiences[0].product;

  useEffect(() => {
    pageRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    if (+step && consultant?.registrationStep) {
      if (+step > consultant.registrationStep + 1) {
        navigate(`${ROUTES.CONSULTANT_REGISTRATION}/${consultant.registrationStep + 1}`);
      }
    }
  }, [step, consultant?.registrationStep, navigate]);

  useEffect(() => {
    if (consultant?.agencyId) {
      actions.updateAction({ experiences: [], expertise: [], worker: {} });
    }
  }, [actions, consultant?.agencyId]);

  const submit = useCallback(() => {
    navigate(`${ROUTES.CONSULTANT_REGISTRATION}/${+step + 1}`);
  }, [navigate, step]);

  const goHome = useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  const skip = useCallback(() => {
    if (consultant?.agencyId) {
      navigate(ROUTES.CONSULTANT, {
        state: {
          enableCongratsModal: false,
        },
      });
    } else {
      navigate(`${ROUTES.CONSULTANT_REGISTRATION}/4`);
    }
  }, [consultant?.agencyId, navigate]);

  useEffect(() => {
    (isAgencyAdmin || isResourceManager) && navigate(ROUTES.ORDER_LIST);
  }, [navigate, isAgencyAdmin, isResourceManager]);

  const renderStep = useCallback(() => {
    switch (+step) {
      case EConsultantRegStep.PROFILE:
        return !!consultant?.agencyId ? (
          <ConsultantFullProfile isModal={false} onSubmitted={submit} />
        ) : (
          <ConsultantProfile onSubmitted={submit} />
        );
      case EConsultantRegStep.PRODUCTS:
        return (
          <ProductsExperienses
            onSubmitted={submit}
            bypassIsValid={bypassIsValid}
            setBypassIsValid={setBypassIsValid}
          />
        );
      case EConsultantRegStep.PROFICIENCY:
        return (
          <ProductsExpertises
            onSubmitted={me.isVirtual || me?.role?.name === ERole.INFLUENCER ? goHome : submit}
          />
        );
      case EConsultantRegStep.AVAILABILITY:
        return <Availability onSubmitted={!consultant?.agencyId ? submit : goHome} />;
      default:
        return <ConnectWithARep />;
    }
  }, [bypassIsValid, consultant?.agencyId, me?.role, me?.isVirtual, step, submit, goHome]);

  const nextBtn = useCallback(() => {
    return (
      <LoadingButton
        data-test="next-button"
        loading={isSubmitting}
        form="hook-form"
        type="submit"
        sx={{ backgroundColor: mobile ? 'black' : undefined, color: mobile ? 'white' : undefined }}
        disabled={
          (+step === EConsultantRegStep.PROFILE &&
            ((!consultant?.agencyId &&
              getValues().businessSetup !== EWorkEligibilityType.ELIGIBLE) ||
              !getValues().firstName ||
              !getValues().lastName ||
              !getValues().location?.label ||
              (!getValues().mobilePhone?.nationalNumber && consultant?.agencyId) ||
              (!getValues().languages?.length && consultant?.agencyId) ||
              (getValues().languages?.length &&
                getValues().languages[0].level === '' &&
                consultant?.agencyId) ||
              errors.languages ||
              (!getValues().linkedInLink && !getValues().cv && !consultant?.agencyId))) ||
          (errors.nickName && !!errors.nickName.message) ||
          (!isValid && +step !== EConsultantRegStep.PROFILE) ||
          (+step === EConsultantRegStep.PRODUCTS &&
            !isEqual(experiences, getValues().experiences || null)) ||
          (+step === EConsultantRegStep.PROFICIENCY &&
            !isEqual(expertise, getValues().expertise || null))
        }
        variant="contained"
      >
        {+step === EConsultantRegStep.CONNECT ||
        (+step === EConsultantRegStep.AVAILABILITY && !!consultant?.agencyId) ||
        (+step === EConsultantRegStep.PROFICIENCY &&
          (me?.isVirtual || me?.role?.name === ERole.INFLUENCER))
          ? 'Submit'
          : 'Next Step'}
      </LoadingButton>
    );
  }, [
    isValid,
    isSubmitting,
    step,
    experiences,
    expertise,
    errors.nickName,
    mobile,
    getValues,
    consultant?.agencyId,
    me,
    errors.languages,
  ]);

  const skipButton = useMemo(
    () => (
      <LoadingButton
        id="skip-button"
        onClick={skip}
        sx={{ backgroundColor: mobile ? 'black' : undefined, color: mobile ? 'white' : undefined }}
        variant="contained"
      >
        Skip
      </LoadingButton>
    ),
    [mobile, skip],
  );

  const headerTitle = useMemo(() => {
    switch (+step) {
      case EConsultantRegStep.PRODUCTS:
        return {
          title: 'Share your work and project history to determine your seniority and hourly rate',
        };
      case EConsultantRegStep.PROFICIENCY:
        return {
          title: 'Select your preferred modules and expertise level',
        };
      case EConsultantRegStep.AVAILABILITY:
        return {
          title: 'Set Your Availability',
        };
      default:
        return {
          title: 'Tell us about yourself',
        };
    }
  }, [step]);

  const pageRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: {
          xs: `85% / cover no-repeat ${+step >= EConsultantRegStep.CONNECT ? '#FFCA28' : undefined}`,
          md: `center / cover no-repeat ${+step >= EConsultantRegStep.CONNECT ? '#FFCA28' : undefined}`,
        },
        backgroundImage: {
          xs: +step >= EConsultantRegStep.CONNECT ? 'url(/img/workerbee-video.png)' : undefined,
          md: +step >= EConsultantRegStep.CONNECT ? 'url(/img/workerbee-video.png)' : undefined,
        },
        position: 'relative',
      }}
    >
      <Container maxWidth="xxl">
        {+step < EConsultantRegStep.CONNECT && (
          <StyledHeaderWrap container maxWidth={'auto'}>
            <Grid
              xs={12}
              justifyContent="space-between"
              display="flex"
              flexWrap="wrap"
              alignItems="center"
            >
              <IconButton
                data-test="oder-flow-back-button"
                id="oder-flow-back-button"
                type="submit"
                onClick={goBack}
                color="primary"
                sx={{ position: 'relative', right: 16 }}
              >
                <ArrowLeftIcon />
              </IconButton>
              <Box display="flex" flex="1 0 0" flexDirection="column">
                {headerTitle.title && (
                  <Typography data-test={`consultant-register-header-${step}`} variant="h1">
                    {headerTitle.title}
                  </Typography>
                )}
              </Box>
              <Box display={{ xs: 'none', xl: 'flex' }}>{isSkip ? skipButton : nextBtn()}</Box>
            </Grid>
          </StyledHeaderWrap>
        )}
      </Container>
      <StyledCustomScroll allowOuterScroll flex="10">
        <Container
          ref={pageRef}
          maxWidth="xxl"
          sx={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}
        >
          <StyledStepContainer>
            <Grid container sx={{ justifyContent: 'center' }}>
              <Grid xs>
                <FormProvider {...methods}>
                  <Suspense fallback={<Loader />}>{(me && renderStep()) || <Loader />}</Suspense>
                </FormProvider>
                {+step < EConsultantRegStep.CONNECT && (
                  <StyledAppBar color="default" position="absolute" elevation={6}>
                    <Toolbar>
                      <Stack direction="row" flex="1 0 0" spacing={2} useFlexGap my={2}>
                        <Stack flex="1 0 0">{isSkip ? skipButton : nextBtn()}</Stack>
                      </Stack>
                    </Toolbar>
                  </StyledAppBar>
                )}
              </Grid>
            </Grid>
          </StyledStepContainer>
        </Container>
      </StyledCustomScroll>
    </Box>
  );
};

export default ConsultantRegister;
