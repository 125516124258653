import React, { FunctionComponent, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import ArrowDown from 'styles/icons/ArrowDown';
import ChevronDown from 'styles/icons/ChevronDown';
import ChevronUp from 'styles/icons/ChevronUp';

interface IDealCollapsableSection {
  sectionTitle?: string;
  children?: any;
  defaultExpand?: boolean;
}

const DealCollapsableSection: FunctionComponent<IDealCollapsableSection> = ({
  sectionTitle,
  children,
  defaultExpand,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpand ? defaultExpand : false);

  return (
    <Box
      mt={'24px'}
      width="100%"
      maxWidth="869px"
      sx={{
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05)',
        border: '1px',
        borderColor: 'rgba(231, 231, 231, 0.7)',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          paddingLeft: '24px',
          paddingTop: '15px',
          paddingBottom: '15px',
          backgroundColor: 'background.paper',
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Typography fontSize={24} fontWeight={700}>
          {sectionTitle}
        </Typography>
        <IconButton
          data-test="expand-button"
          disableRipple
          aria-label={isExpanded ? 'Collapse section' : 'Expand section'}
          onClick={e => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </IconButton>
      </Box>
      {isExpanded && <Box sx={{ backgroundColor: 'background.paper' }}>{children}</Box>}
    </Box>
  );
};

export default DealCollapsableSection;
