import React, { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  DialogActions,
  DialogContent,
  FormGroup,
  InputLabel,
  FormLabel,
  Box,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ModalWrapper from 'components/Modal/Modal';
import BootstrapInput from 'components/CustomFields/BootstrapInput';
import CompanySelect from 'components/CustomFields/CustomAutocomplete/CompanySelect';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import requestNewProduct from 'store/query/productAndExperience/requestProduct';
import { IModalProps } from 'store/types/common';
import { IBasicUser } from 'store/types/user';
import ROUTES from 'router/constants';

interface IDealUnSavedChangesModal extends IModalProps {
  visible: boolean;
  close: () => void;
  onSave: () => void;
  onDiscard?: () => void;
}

const DealUnSavedChangesModal: FunctionComponent<IDealUnSavedChangesModal> = props => {
  const { visible, close, onSave, onDiscard } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleDiscard = () => {
    if (onDiscard) {
      onDiscard();
    } else {
      navigate(ROUTES.DEALS_LIST);
    }
  };

  return (
    <ModalWrapper
      dialogProps={{
        open: visible,
        onClose: close,
      }}
      title={{ title: 'You have unsaved changes' }}
    >
      <DialogContent>
        <Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Typography fontSize={'16px'} fontWeight={400}>
              Would you like to save changes before leaving this page?
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <Button
            color="secondary"
            fullWidth
            type="submit"
            variant="outlined"
            onClick={handleDiscard}
            sx={{ marginRight: '16px' }}
          >
            Discard and Leave
          </Button>
          <Button color="primary" fullWidth type="submit" variant="contained" onClick={onSave}>
            Save Changes
          </Button>
        </Box>
      </DialogActions>
    </ModalWrapper>
  );
};

export default DealUnSavedChangesModal;
