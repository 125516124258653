import React, { useEffect } from 'react';
import { Outlet, useParams, Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { LIMITS } from 'models/consts';
import ROUTES from 'router/constants';

import fetchChatList from 'store/query/chat/fetchChatList';
import fetchChatById from 'store/query/chat/fetchChatById';

import {
  currentChatInfoSelector,
  isChatListLoading,
  serviceSIdSelector,
} from 'store/selectors/getChatListSelector';
import {
  getMeRoleSelector,
  isAgencyAdministratorsSelector,
  isConsultantSelector,
  isEnterpriseCustomerSelector,
} from 'store/selectors/getUserSelector';

import { StyledBoxFluid, StyledBoxRoot, StyledBoxWrap } from './styled';

import LeftBar from 'components/Chat/Sidebar';

const ChatPage = () => {
  const dispatch = useAppDispatch();
  const { room } = useParams();

  const serviceSId = useAppSelector(serviceSIdSelector);
  const currentChatInfo = useAppSelector(currentChatInfoSelector(room));
  const isLoading = useAppSelector(isChatListLoading);

  const role = useAppSelector(getMeRoleSelector);
  const isAgency = useAppSelector(isAgencyAdministratorsSelector);
  const isConsultant = useAppSelector(isConsultantSelector);
  const isEnterprise = useAppSelector(isEnterpriseCustomerSelector);

  useEffect(() => {
    if (!currentChatInfo && room) {
      dispatch(fetchChatById({ id: room, role }));
    }
  }, [currentChatInfo, dispatch, role, room]);

  useEffect(() => {
    if (!serviceSId) {
      dispatch(fetchChatList({ limit: LIMITS.chat, offset: 0, page: 0, role }));
    }
  }, [serviceSId, dispatch, role]);

  return (
    <StyledBoxRoot>
      <StyledBoxFluid>
        <StyledBoxWrap>
          <LeftBar serviceSId={serviceSId} room={room} />
          {currentChatInfo ? (
            <Outlet />
          ) : (
            <Box display="flex" width="100%" justifyContent="center" mt={14}>
              {isConsultant ? (
                <Typography width={300} textAlign="center">
                  {'To start a chat, go to '}
                  <MuiLink component={Link} to={ROUTES.ORDER_LIST} variant="body1">
                    My Orders
                  </MuiLink>
                  {' and select needed Customer'}
                </Typography>
              ) : (
                <Typography width={300} textAlign="center">
                  {'To be able to use Chat, '}
                  {isAgency ? (
                    'Create an Order'
                  ) : (
                    <MuiLink
                      id="create-an-order"
                      component={Link}
                      to={ROUTES.ORDER_CREATION + '/1'}
                      variant="body1"
                    >
                      Create an Order
                    </MuiLink>
                  )}
                  {' with a Specialist first.'}
                </Typography>
              )}
            </Box>
          )}
        </StyledBoxWrap>
      </StyledBoxFluid>
    </StyledBoxRoot>
  );
};

ChatPage.whyDidYouRender = true;

export default ChatPage;
