import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { DealBadge } from 'components/Badge/DealBadge';

import ROUTES from 'router/constants';
import ArrowLeftIcon from 'styles/icons/ArrowLeft';
import { useAppSelector } from 'store/hooks';
import { getDraftOrderDetailsSelector } from 'store/selectors/getDraftOrdersSelector';
import { LoadingButton } from '@mui/lab';
import { useFormContext } from 'react-hook-form';

interface IDealDetailHeader {
  onBackNavigate: () => void;
  onSave: () => void;
  onCreateOrderClicked: () => void;
  isButtonEnabled?: boolean;
  isSubmitting: boolean;
}

const DealDetailsHeader: FunctionComponent<IDealDetailHeader> = ({
  onBackNavigate,
  onSave,
  onCreateOrderClicked,
  isButtonEnabled,
  isSubmitting,
}) => {
  const navigate = useNavigate();

  const {
    formState: { isValid, errors },
  } = useFormContext();

  const { items, loading, loaded } = useAppSelector(getDraftOrderDetailsSelector);

  return (
    <>
      {items && (
        <Box mt={3} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            <Button
              data-test={'dealDetailBackArrow'}
              id={'dealDetailBackArrow'}
              type="submit"
              onClick={onBackNavigate}
              color="secondary"
              sx={{ width: '1%', marginLeft: -6 }}
            >
              <ArrowLeftIcon />
            </Button>
            <Typography fontSize={32} fontWeight={700}>
              {items.name}
              <Box display={'flex'} flexDirection={'row'} mt={1}>
                <Box mr={1}>
                  <DealBadge phase={items?.salesPhase} />
                </Box>

                {items.talentPhase && <DealBadge phase={items.talentPhase} />}
              </Box>
            </Typography>
          </Box>
          <Box display={'flex'} flexDirection={'row'}>
            {/* {items?.status !== 'draft' && ( */}
            <>
              <Box display="flex" mr={1} ml={1} width={{ xs: '43%', md: 'auto' }}>
                <Button
                  disabled={
                    (!isValid && Object.keys(errors).length > 0) ||
                    !isButtonEnabled ||
                    items?.status !== 'draft'
                  }
                  data-test="header-flow-button"
                  variant="outlined"
                  color="secondary"
                  onClick={onSave}
                  sx={{
                    fontSize: 16,
                    fontWeight: 500,
                    width: '153px',
                  }}
                >
                  Save Changes
                </Button>
              </Box>
              <Box display="flex" ml={1} width={{ xs: '43%', md: 'auto' }}>
                <LoadingButton
                  disabled={(!isValid && Object.keys(errors).length > 0) || items?.orderId}
                  loading={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={onCreateOrderClicked}
                  data-test="header-flow-button"
                  sx={{
                    fontSize: 16,
                    fontWeight: 500,
                    width: '146px',
                  }}
                >
                  Create Order
                </LoadingButton>
              </Box>
            </>
            {/* )} */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default DealDetailsHeader;
