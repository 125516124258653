import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';

import config from './app.config';

const firebaseConfigJson = config.FIREBASE_CONFIG;

let firebaseApp: FirebaseApp | null = null;
let firebaseAnalytics: Analytics | null = null;
let remoteConfig: any = {};

// Initialize Firebase
if (firebaseConfigJson) {
  try {
    const firebaseConfig = JSON.parse(firebaseConfigJson);

    // Initialize Firebase
    firebaseApp = initializeApp(firebaseConfig);

    // Initialize Analytics and get a reference to the service
    firebaseAnalytics = getAnalytics(firebaseApp);

    // Get Remote Config
    remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 60000,
      fetchTimeoutMillis: 60000,
    };
  } catch (e) {
    console.error('Error initializing Firebase:', e);
  }
}

export {
  firebaseApp, // Firebase app instance
  firebaseAnalytics, // Firebase Analytics service instance
  remoteConfig,
};
