import dayjs from 'utils/dayjs';

export const standartDateToMonth = (date: string) => {
  return dayjs(date).format('MMM YYYY');
};

export const calcWeekCount = (startDate: string, endDate?: string) => {
  const startMoment = dayjs(startDate);
  const endMoment = endDate ? dayjs(endDate) : dayjs();
  return dayjs.duration(endMoment.diff(startMoment)).asWeeks();
};

export const daysDiff = (startDate: string | Date, createdAt: string | Date) => {
  return dayjs(startDate).diff(createdAt, 'days');
};

export const getEndDate = (
  startDate: string,
  hoursCount: number,
  availability: number | undefined,
) => {
  if (availability && startDate) {
    const weeksCount = Math.ceil(hoursCount / +availability);
    return dayjs(startDate)
      .add(weeksCount - 1, 'weeks')
      .add(4, 'days')
      .format('YYYY-MM-DD');
  }
  return undefined;
};

export const getNextMonday = (date?: string | Date) => {
  const dateWeekMonday = dayjs(date).isoWeekday(1);

  if (dayjs(date).isAfter(dateWeekMonday)) {
    return dayjs(date).add(7, 'day').isoWeekday(1).format('YYYY-MM-DD');
  }
  return dateWeekMonday.format('YYYY-MM-DD');
};

export const isMonday = (date: Date) => {
  const day = dayjs(date).day();
  return day === 1;
};

export const isFriday = (date: Date) => {
  const day = dayjs(date).day();
  return day === 5;
};

export const experienceHumanized = (month: number) => {
  if (month < 12) {
    return `${month} month${month !== 1 ? 's' : ''}`;
  }
  return dayjs.duration(month, 'months').humanize();
};

export const calcWeeks = (endDate?: string | Date, startDate?: string | Date) => {
  if (endDate && startDate) {
    return Math.ceil(dayjs.duration(dayjs(endDate).diff(dayjs(startDate))).asWeeks());
  }
  return 0;
};
