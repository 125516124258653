import React, { FunctionComponent, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { IconButton, Stack, Typography, useMediaQuery } from '@mui/material';

import {
  StyledOGIcon,
  StyledVerifiedIcon,
} from 'components/ConsultantComponents/ConsultantPreview/styled';
import DirectorySearchConsultantNameView from './DirectorySearchConsultantNameView';
import Avatar from 'components/Avatar';
import DirectorySearchAboutMeView from './DirectorySearchAboutMeView';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import useDeviceInfo from 'hooks/useDeviceInfo';
import useConsultantProductExperience from 'hooks/useConsultantProductExperience';
import getConsultantProductsFull from 'store/query/consultant/getConsultantProductsFull';
import fetchConsultantCertificates from 'store/query/consultant/fetchConsultantCertificates';
import { productsSummarySelector } from 'store/selectors/getConsultantSelector';
import { consultantCertificatesSelector } from 'store/selectors/getConsultantCerеificates';
import { DirectorySearchResult } from 'store/types/directorySearch';

import ArrowRightIcon from 'styles/icons/ArrowRight';
import ArrowLeftBroad from 'styles/icons/ArrowLeftBroad';
import { StyledInfoFieldValue } from 'components/CustomFields/styled';
import ConsultantSummary from 'components/ConsultantComponents/ConsultantSummary';
import Certificates from 'components/ConsultantComponents/Certificates';
import Skills from 'components/ConsultantComponents/Skills';
import HighlightedView from '../HighlightedView';
import DirectorySearchNotes from './DirectorySearchNotes';

interface IDirectorySearchConsultantViewProps {
  consultant: DirectorySearchResult;
  onSelectPreview?: (worker: any) => void;
  isSelectedExpert?: boolean;
  onAnonymousMode?: boolean;
  currentIndex: number;
  totalResults: number;
  countAll?: number;
  hasMoreResults: boolean | undefined;
  onNext: () => void;
  onPrev: () => void;
}

const DirectorySearchConsultantView: FunctionComponent<IDirectorySearchConsultantViewProps> = ({
  consultant,
  onSelectPreview,
  isSelectedExpert,
  onAnonymousMode,
  currentIndex,
  totalResults,
  countAll,
  hasMoreResults,
  onNext,
  onPrev,
}) => {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { deviceType } = useDeviceInfo();

  // const { consultant, ...user } = specialist;

  const isMobile = deviceType === 'mobile' && mobileScreen;

  const expertiseSummary = useAppSelector(productsSummarySelector(consultant?.userId));
  const certificates = useAppSelector(consultantCertificatesSelector(consultant?.userId));

  useEffect(() => {
    if (consultant.userId && !expertiseSummary.length) {
      dispatch(getConsultantProductsFull(consultant?.userId));
    }
  }, [consultant.userId, dispatch, expertiseSummary.length]);

  useEffect(() => {
    if (consultant.userId && !certificates.length) {
      dispatch(
        fetchConsultantCertificates({ key: consultant?.userId, userId: consultant?.userId }),
      );
    }
  }, [consultant.userId, dispatch, certificates.length]);

  const { headLine, description } = useConsultantProductExperience(consultant, true);

  const tagsArray =
    consultant?.tags?.split(' ').map((tag: string, index: number) => ({
      id: index,
      name: tag.trim(),
    })) || [];

  return (
    <Box sx={{ mt: { xs: 3, sm: 5, md: 3, lg: 2 }, overflow: 'hidden' }}>
      {isMobile && (
        <Grid mt={-1} display={'flex'} justifyContent={'flex-end'}>
          <Grid width={150} height={50}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <IconButton onClick={onPrev} disabled={currentIndex === 0}>
                <ArrowLeftBroad color={currentIndex === 0 ? 'disabled' : 'secondary'} />
              </IconButton>
              <Typography variant="body2">
                {currentIndex + 1} of {countAll ? countAll : totalResults}
              </Typography>
              <IconButton
                onClick={onNext}
                disabled={currentIndex + 1 === totalResults && !hasMoreResults}
              >
                <ArrowRightIcon
                  color={
                    currentIndex + 1 === totalResults && !hasMoreResults ? 'disabled' : 'secondary'
                  }
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container rowSpacing={3} columnSpacing={{ xs: 4, sm: 5 }}>
        <Grid container xs={12}>
          <Grid xs="auto" display="flex" flexDirection="column" alignItems="center">
            <Avatar
              isBlur={onAnonymousMode ? true : undefined}
              image={consultant?.avatarUrl}
              firstName={consultant.firstName || consultant.initials || ''}
              size={(isMobile && 'medium') || 'large'}
            />
            {isMobile && (
              <Box display="flex" my={1.5}>
                {consultant?.isApproved && (
                  <Box>
                    <StyledVerifiedIcon />
                  </Box>
                )}
                <Box>
                  <StyledOGIcon />
                </Box>
              </Box>
            )}
          </Grid>
          <Grid xs>
            <DirectorySearchConsultantNameView
              consultant={consultant}
              isCurrentUser={false}
              expertiseSummary={expertiseSummary}
              onOpen={() => {}}
              onAnonymousMode={onAnonymousMode}
            />
          </Grid>
          <Grid xs="auto" display={{ xs: 'block', sm: 'block' }}>
            {!isMobile && (
              <Grid xs="auto" display={{ xs: 'block', sm: 'block' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <IconButton onClick={onPrev} disabled={currentIndex === 0}>
                    <ArrowLeftBroad color={currentIndex === 0 ? 'disabled' : 'secondary'} />
                  </IconButton>
                  <Typography variant="body2">
                    {currentIndex + 1} of {countAll ? countAll : totalResults}
                  </Typography>
                  <IconButton
                    onClick={onNext}
                    disabled={currentIndex + 1 === totalResults && !hasMoreResults}
                  >
                    <ArrowRightIcon
                      color={
                        currentIndex + 1 === totalResults && !hasMoreResults
                          ? 'disabled'
                          : 'secondary'
                      }
                    />
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid xs={12} spacing={0}>
            <Divider sx={{ my: 0 }} />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <DirectorySearchAboutMeView
            isCurrentUser={false}
            onOpen={() => ({})}
            consultant={{
              ...consultant,
              description: description,
            }}
          />
        </Grid>
        <Grid xs={12} spacing={0}>
          <Divider sx={{ my: 0 }} />
        </Grid>

        {consultant?.notes && (
          <>
            <Grid xs={12}>
              <DirectorySearchNotes notes={consultant?.notes} />
            </Grid>
            <Grid xs={12} spacing={0}>
              <Divider sx={{ my: 0 }} />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container xs={12} spacing={2} mb={2}>
        <Grid container xs={12} spacing={4} mt={1}>
          {consultant?.availabilities && consultant?.availabilities.length > 0 && (
            <Grid
              xs={12}
              sm={5}
              sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}
            >
              <Typography mb={2} variant="h3">
                Availability
              </Typography>
              <Stack direction="row" alignItems="center">
                <StyledInfoFieldValue sx={{ width: '206px' }}>
                  <Typography>{`${consultant?.availabilities[0]?.availabilityWeek} hours / week`}</Typography>
                </StyledInfoFieldValue>
              </Stack>
            </Grid>
          )}
          {consultant?.languages && consultant?.languages?.length > 0 && (
            <Grid container xs={12} md={7} spacing={2}>
              <Grid xs={12}>
                <Typography variant="h3">Languages</Typography>
              </Grid>
              <Grid xs={12}>
                <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap">
                  {consultant.languages.map(({ id, name, level }) => (
                    <Stack
                      key={`lang-${id}`}
                      sx={{ textTransform: 'capitalize' }}
                      direction="column"
                    >
                      <Typography variant="subtitle2">
                        {consultant?.highlights?.includes('languages') ? (
                          <HighlightedView text={(name && name.toLowerCase()) || ''} />
                        ) : (
                          (name && name.toLowerCase()) || ''
                        )}
                      </Typography>
                      <Divider sx={{ my: 0 }} />
                      <StyledInfoFieldValue sx={{ width: '206px', marginTop: 1 }}>
                        <Typography>{level}</Typography>
                      </StyledInfoFieldValue>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid xs={12} spacing={0}>
        <Divider sx={{ my: 0 }} />
      </Grid>
      <Grid xs={12}>
        <ConsultantSummary
          consultant={consultant}
          consultantKey={consultant?.userId}
          expertiseSummary={expertiseSummary}
          isCurrentUser={false}
          certificates={certificates}
        />
      </Grid>
      <Grid xs={12}>
        <Certificates isCurrentUser={false} certificates={certificates} />
      </Grid>
      {!!consultant?.tags?.length && (
        <Grid xs={12}>
          <Skills tags={tagsArray} consultant={consultant} onOpen={() => ({})} />
        </Grid>
      )}
    </Box>
  );
};

export default DirectorySearchConsultantView;
