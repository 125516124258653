import React from 'react';

interface CircleIconProps {
  color?: string; // Optional color prop, defaults to '#1F1F1F'
  size?: number; // Optional size prop, defaults to 6
}

const CircleIcon: React.FC<CircleIconProps> = ({ color = '#1F1F1F', size = 6 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 6 6"
      fill="none"
    >
      <circle cx="3" cy="3" r="3" fill={color} />
    </svg>
  );
};

export default CircleIcon;
