import React, { FunctionComponent, useEffect, useState, useRef, useCallback } from 'react';
import { Navigate, useLocation, useNavigate, useBeforeUnload, useBlocker } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import DealDetailsHeader from './DealDetailsHeader';
import DealCollapsableSection from './DealCollapsableSection';
import TalentSection from './TalentSection';
import CustomerInformation from './CustomerInformation';
import SalesSection from './SalesSection';
import ROUTES from 'router/constants';
import DealDeatilsSection from './DealCollapsableSection/DealDetailsSection';
import ExpertiseSection from './DealCollapsableSection/ExpertiseSection';
import AdditionalRequirementsSection from './DealCollapsableSection/AdditionalRequirementsSection';
import SpecialistSection from './DealCollapsableSection/SpecialistSection';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CustomerSection from './DealCollapsableSection/CustomerSection';
import { payoutTermsOptions, TALENT_PHASES } from 'models/consts';
import fetchDealDetailsById from 'store/query/draft-order/fetchDealDetailsById';
import { getDraftOrderDetailsSelector } from 'store/selectors/getDraftOrdersSelector';
import DealUnSavedChangesModal from './DealUnSavedChangesModal';
import { IProduct } from 'store/types/categoryProducts';
import updateCuratedOrderDraft from 'store/query/curated-order/updateCuratedOrderDraft';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';
import finalizeADeal from 'store/query/draft-order/finalizeADeal';
import CreateDealErrorState from './CreateDealErrorState';

// Define the type for form values
interface FormValues {
  talentPhase: string | number | undefined;
  assignee: string;
  modules: any;
  works: any;
  product?: IProduct;
  specialistRate: string | number | undefined;
  isSpecialistCustomRateSelected?: boolean;
  customerRate: string | number;
  payoutTerms: number | string | undefined;
  specialist: any;
  customer: any;
  companyName: string;
  customerEmail: string;
  customerType?: string;
}

const DealDeatils: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const dealId = location?.state?.dealId;

  const { items, loading, loaded } = useAppSelector(getDraftOrderDetailsSelector);

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      talentPhase: '',
      assignee: '',
      modules: items?.expertise?.modules ? items?.expertise?.modules : [],
      works: items?.expertise?.works ? items?.expertise?.works : [],
      product: items?.expertise?.product ? items?.expertise?.product : '',
      specialistRate: items?.specialistRate ? items?.specialistRate : '',
      isSpecialistCustomRateSelected: items?.specialistRate ? true : false,
      customerRate: items?.customerRate ? items?.customerRate : '',
      payoutTerms: '',
      specialist: items?.worker ? items.worker : undefined,
      customer: undefined,
      companyName: items?.metadata?.companyName ? items?.metadata?.companyName : undefined,
      customerEmail: items?.metadata?.customerEmail ? items?.metadata?.customerEmail : undefined,
      customerType: '',
    },
  });

  const {
    watch,
    setValue,
    getValues,
    reset,
    formState: { isValid, errors },
  } = methods;
  const watchedValues = watch();

  const initialValues = useRef<FormValues>({} as FormValues);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isErrorStateVisible, setIsErrorStateVisible] = useState(false);
  const [errorStateData, setErrorStateData] = useState([]);
  const [isUnsavedModalVisible, setIUnsavedModalVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [pendingNavigationPath, setPendingNavigationPath] = useState<string | null>(null);

  useEffect(() => {
    if (dealId) {
      reset();
      dispatch(fetchDealDetailsById({ id: dealId }));
    }
  }, [dealId, dispatch, reset]);

  useEffect(() => {
    if (items) {
      const apiValues: FormValues = {
        talentPhase: items?.talentPhase
          ? TALENT_PHASES.find(option => option.key === String(items.talentPhase))?.id
          : '',
        assignee: '',
        product: items?.expertise?.product ? items?.expertise?.product : '',
        modules: items?.expertise?.modules ? items?.expertise?.modules : [],
        works: items?.expertise?.works ? items?.expertise?.works : [],
        specialistRate: items?.specialistRate ? items?.specialistRate / 100 : '',
        isSpecialistCustomRateSelected: items?.specialistRate ? true : false,
        customerRate: items?.customerRate || '',
        payoutTerms: items?.payoutTerms
          ? payoutTermsOptions.find(option => option.name === String(items.payoutTerms))?.id
          : 0,
        specialist: items?.worker || undefined,
        customer: items?.customerId ? items?.customer : undefined,
        companyName: items?.metadata?.companyName || undefined,
        customerEmail: items?.metadata?.customerEmail || undefined,
      };

      initialValues.current = apiValues;
      reset(apiValues);
    }
  }, [items, reset]);

  useEffect(() => {
    const currentValues = watchedValues as FormValues;
    const hasChanges = Object.keys(initialValues.current).some(
      key =>
        JSON.stringify(currentValues[key as keyof FormValues]) !==
        JSON.stringify(initialValues.current[key as keyof FormValues]),
    );
    !items?.orderId ? setIsDirty(hasChanges) : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues]);

  const blocker = useBlocker(
    useCallback(
      ({
        currentLocation,
        nextLocation,
      }: {
        currentLocation: { pathname: string };
        nextLocation: { pathname: string };
      }) => isDirty && !isSaved && currentLocation.pathname !== nextLocation.pathname,
      [isDirty, isSaved],
    ),
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIUnsavedModalVisible(true);
      setPendingNavigationPath(blocker.location.pathname);
    }
  }, [blocker.state, blocker.location]);

  // Handle browser back button and page refresh
  useBeforeUnload(
    useCallback(
      event => {
        if (isDirty && !isSaved) {
          event.preventDefault();
          event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
          return event.returnValue;
        }
      },
      [isDirty, isSaved],
    ),
  );

  const handleBack = () => {
    if (isDirty && !isSaved) {
      setIUnsavedModalVisible(true);
      setPendingNavigationPath(ROUTES.DEALS_LIST);
      return;
    }
    navigate(ROUTES.DEALS_LIST);
  };

  const {
    customer,
    customerEmail,
    specialist,
    customerRate,
    specialistRate,
    modules,
    works,
    product,
    payoutTerms,
    companyName,
    talentPhase,
    isSpecialistCustomRateSelected,
    customerType,
  } = methods.watch();

  const prepPayload = () => {
    const existingCustomerId =
      customer && customerType !== 'Create a New Customer' ? customer?.id : null;
    const getPayoutTerms = payoutTermsOptions.find(option => option.id === payoutTerms)?.name;
    const payload: any = {
      metadata: {
        customerId: existingCustomerId,
        customerEmail: customerEmail,
        companyName: companyName,
        customRate: items?.metadata?.customRate,
        customerName: items?.metadata?.customerName,
        customerPhone: items?.metadata?.customerPhone,
        durationType: items?.metadata?.durationType,
        duration: items?.metadata?.duration,
        availabilityType: items?.metadata?.availabilityType,
        interviewRequired: items?.metadata?.interviewRequired,
        backgroundCheckRequired: items?.metadata?.custbackgroundCheckRequiredomerName,
        timezone: items?.metadata?.timezone,
        scheduleType: items?.metadata?.scheduleType,
        reasonForWork: items?.metadata?.reasonForWork,
        reasonSpecialistIsRequired: items?.metadata?.reasonSpecialistIsRequired,
        industryRequired: items?.metadata?.industryRequired,
        industry: items?.metadata?.customindustryerName,
      },
      name: items?.name,
      // userId: salesManager ? salesManager.id : undefined,
      companyId: items?.metadata?.companyId ? items?.metadata?.companyId : undefined,
      expertise: product
        ? {
            productId: product?.id,
            modules: modules.map((item: any) => ({
              moduleId: item.id,
              isPrimary: modules.primaryModule === item.id,
            })),
            works: works.map((item: any) => ({
              workId: item.id,
              isPrimary: works.primaryWorkType === item.id,
            })),
          }
        : undefined,
      workerId: specialist?.id || undefined,
      customerRate: customerRate ? Number(customerRate) : undefined,
      specialistRate:
        specialistRate && isSpecialistCustomRateSelected ? Number(specialistRate) * 100 : null,
      payoutTerms: Number(getPayoutTerms),
      talentPhase: TALENT_PHASES.find(option => option.id === talentPhase)?.key
        ? TALENT_PHASES.find(option => option.id === talentPhase)?.key
        : undefined,
      customerId: existingCustomerId,
    };
    return payload;
  };

  const onCreateOrderClicked = () => {
    setIsSubmitting(true);
    setIsErrorStateVisible(false);
    dispatch(
      updateCuratedOrderDraft({
        id: items?.id,
        ...prepPayload(),
      }),
    )
      .unwrap()
      .then(response => {
        if (response) {
          try {
            dispatch(
              finalizeADeal({
                id: items.id,
              }),
            )
              .unwrap()
              .then(data => {
                if (data?.noExistParameters) {
                  setIsSubmitting(false);
                  setErrorStateData(data?.noExistParameters);
                  setIsErrorStateVisible(true);
                } else {
                  if (data?.succes) {
                    setIsSubmitting(false);
                    ToastService.success(
                      ToastMessage({
                        title: 'Order is created successfully!',
                        body: 'We sent the order to the Customer.',
                      }),
                    );
                    navigate(ROUTES.DEALS_LIST);
                  } else {
                    setErrorStateData(
                      Array.isArray(data?.message)
                        ? data.message
                        : [data?.message || 'An unknown error occurred'],
                    );
                    setIsErrorStateVisible(true);
                    setIsSubmitting(false);
                  }
                }
              })
              .catch(error => {
                setIsSubmitting(false);
              });
          } catch (e) {
            setIsSubmitting(false);
          }
        }
      });
  };

  const onSave = () => {
    setIsErrorStateVisible(false);
    dispatch(
      updateCuratedOrderDraft({
        id: items?.id,
        ...prepPayload(),
      }),
    )
      .unwrap()
      .then(response => {
        if (response) {
          initialValues.current = getValues();
          setIsSaved(true);
          setIsDirty(false);

          if (isUnsavedModalVisible) {
            setIUnsavedModalVisible(false);

            if (pendingNavigationPath) {
              if (blocker.state === 'blocked') {
                blocker.proceed();
              } else {
                navigate(pendingNavigationPath);
              }
              setPendingNavigationPath(null);
            }
          }

          dispatch(fetchDealDetailsById({ id: dealId }));
          ToastService.success(ToastMessage({ title: 'Changes were saved!', body: '' }));
        }
      });
  };

  const onUnsavedModalClose = () => {
    setIUnsavedModalVisible(false);
  };

  // Handle discarding changes and navigation
  const handleDiscardAndNavigate = () => {
    setIsDirty(false);
    setIUnsavedModalVisible(false);

    if (blocker.state === 'blocked') {
      blocker.proceed();
    } else if (pendingNavigationPath) {
      navigate(pendingNavigationPath);
      setPendingNavigationPath(null);
    } else {
      navigate(ROUTES.DEALS_LIST);
    }
  };

  const getCollapsableSections = [
    { title: 'Deal Details', component: <DealDeatilsSection />, isExpandedView: true },
    { title: 'Expertise', component: <ExpertiseSection /> },
    {
      title: 'Additional Requirements',
      component: <AdditionalRequirementsSection />,
      isExpandedView: false,
    },
    { title: 'Specialist', component: <SpecialistSection items={items} />, isExpandedView: false },
    { title: 'Customer', component: <CustomerSection />, isExpandedView: false },
  ];

  return (
    <FormProvider {...methods}>
      <Box>
        <DealDetailsHeader
          onBackNavigate={handleBack}
          onSave={onSave}
          onCreateOrderClicked={onCreateOrderClicked}
          isButtonEnabled={isDirty}
          isSubmitting={isSubmitting}
        />
        {isErrorStateVisible && (
          <CreateDealErrorState
            title={'To create a new order, please fill out the following fields:'}
            handleClose={() => setIsErrorStateVisible(!isErrorStateVisible)}
            errorData={errorStateData}
          />
        )}
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Box width="100%" maxWidth="869px">
            {getCollapsableSections.map(({ title, component, isExpandedView }) => (
              <DealCollapsableSection
                key={title}
                sectionTitle={title}
                defaultExpand={isExpandedView}
              >
                {component}
              </DealCollapsableSection>
            ))}
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'24px'}
            ml={1}
            mt={'24px'}
            width={'100%'}
            maxWidth={'427px'}
          >
            {/* TODO: add this back when disable functionality is decided */}
            {/* {items.status !== 'draft' && <TalentSection />} */}
            <TalentSection />
            <SalesSection />
            <CustomerInformation
              DealDeatils={{
                companyName: items?.metadata?.companyName,
                customerName: items?.metadata?.customerName,
                phoneNumber: items?.metadata?.customerPhone,
                email: items?.metadata?.customerEmail,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} paddingBottom={5}>
        {/* {items.status !== 'draft' && ( */}
        <>
          <Box display="flex" mr={1} ml={1} width={{ xs: '43%', md: 'auto' }}>
            <Button
              disabled={
                (!isValid && Object.keys(errors).length > 0) ||
                !isDirty ||
                items?.status !== 'draft'
              }
              data-test="header-flow-button"
              variant="outlined"
              color="secondary"
              onClick={onSave}
              sx={{
                fontSize: 16,
                fontWeight: 500,
                width: '153px',
              }}
            >
              Save Changes
            </Button>
          </Box>
          <Box display="flex" ml={1} width={{ xs: '43%', md: 'auto' }}>
            <LoadingButton
              disabled={(!isValid && Object.keys(errors).length > 0) || items?.orderId}
              loading={isSubmitting}
              variant="contained"
              color="primary"
              type="submit"
              onClick={onCreateOrderClicked}
              data-test="header-flow-button"
              sx={{
                fontSize: 16,
                fontWeight: 500,
                width: '146px',
              }}
            >
              Create Order
            </LoadingButton>
          </Box>
        </>
        {/* )} */}
        <DealUnSavedChangesModal
          visible={isUnsavedModalVisible}
          close={onUnsavedModalClose}
          onSave={onSave}
          onDiscard={handleDiscardAndNavigate}
        />
      </Box>
    </FormProvider>
  );
};

export default DealDeatils;
