import { SvgIconProps } from '@mui/material';
import { ComponentType } from 'react'; // Import ComponentType for better typing
import MessageIcon from 'styles/icons/MessageIcon';
import { CalenderIcon } from 'styles/icons/OrderHistoryIcons/CalenderIcon';
import { ChartDownIcon } from 'styles/icons/OrderHistoryIcons/ChartDownIcon';
import { ChartUpIcon } from 'styles/icons/OrderHistoryIcons/ChartUpIcon';
import { CheckCircleIcon } from 'styles/icons/OrderHistoryIcons/CheckCircleIcon';
import { EditIcon } from 'styles/icons/OrderHistoryIcons/EditIcon';
import { ErrorIcon } from 'styles/icons/OrderHistoryIcons/ErrorIcon';
import { InvoiceIcon } from 'styles/icons/OrderHistoryIcons/InvoiceIcon';
import { MinusCircleIcon } from 'styles/icons/OrderHistoryIcons/MinusCircleIcon';
import { PaymentMoneyDollarIcon } from 'styles/icons/OrderHistoryIcons/PaymentMoneyDollarIcon';
import { VerifiedIcon } from 'styles/icons/OrderHistoryIcons/VerifiedIcon';

/**
 * Maps changesType to the corresponding icon component.
 * @param {string} changesType - The type of change from the API.
 * @returns {ComponentType<SvgIconProps>} - The corresponding icon component.
 */
export const getIconForChangesType = (changesType: string): ComponentType<SvgIconProps> => {
  switch (changesType) {
    case 'create':
      return CheckCircleIcon;
    case 'update_time_line':
      return ChartUpIcon;
    case 'change_status':
      return EditIcon;
    case 'accepts_order':
      return CheckCircleIcon;
    case 'reject_order':
      return MinusCircleIcon;
    case 'cancel_order':
      return ErrorIcon;
    case 'order_payment':
      return PaymentMoneyDollarIcon;
    case 'order_refund':
      return PaymentMoneyDollarIcon;
    case 'order_invoice':
      return InvoiceIcon;
    case 'order_not_paid':
      return ErrorIcon;
    case 'submit_early_complite':
      return CheckCircleIcon;
    case 'approve_early_complite':
      return VerifiedIcon;
    case 'decline_early_complite':
      return MinusCircleIcon;
    case 'leave_review':
      return MessageIcon;
    case 'approve_review':
      return CheckCircleIcon;
    case 'decline_review':
      return MinusCircleIcon;
    case 'create_extend_hours':
      return ChartUpIcon;
    case 'accept_extend_hours':
      return CheckCircleIcon;
    case 'decline_extend_hours':
      return MinusCircleIcon;
    case 'success_paid_extend_hours':
      return CheckCircleIcon;
    case 'create_weekly_billings':
      return CalenderIcon;
    case 'submit_weekly_billings':
      return ErrorIcon;
    case 'approve_weekly_billings':
      return CheckCircleIcon;
    case 'disput_weekly_billings':
      return MessageIcon;
    case 'complete_order_automatically':
      return VerifiedIcon;
    case 'complete_order':
      return VerifiedIcon;
    case 'on_hold_order':
      return ErrorIcon;
    case 'expired_order':
      return ErrorIcon;
    case 'ready_to_start_order':
      return CheckCircleIcon;
    case 'terminated_order':
      return ErrorIcon;
    case 'trial_terminated_order':
      return VerifiedIcon;
    case 'active_order':
      return CheckCircleIcon;
    case 'wait_payment_order':
      return ErrorIcon;
    default:
      return VerifiedIcon;
  }
};
