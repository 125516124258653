import React, { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  DialogActions,
  DialogContent,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ModalWrapper from 'components/Modal/Modal';
import BootstrapInput from 'components/CustomFields/BootstrapInput';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import requestNewProduct from 'store/query/productAndExperience/requestProduct';
import { IModalProps } from 'store/types/common';
import { IBasicUser } from 'store/types/user';

interface IRequestProductCustomerModal extends IModalProps {
  afterSubmit: () => void;
}

const RequestProductCustomerModal: FunctionComponent<IRequestProductCustomerModal> = props => {
  const { visible, close, afterSubmit } = props;

  const user = useAppSelector(getMeSelector) as IBasicUser;

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: '',
      notes: '',
      requestorsName: ((user?.firstName ?? '') + ' ' + (user?.lastName ?? '')).trim(),
      requestorsEmail: user?.email || '',
    },
    mode: 'onChange',
  });

  const onSubmit = (data: any) => {
    dispatch(requestNewProduct(data))
      .then(result => {
        if (requestNewProduct.fulfilled.match(result)) {
          afterSubmit();
        }
      })
      .finally(() => {
        close();
        reset();
      });
  };

  return (
    <ModalWrapper
      dialogProps={{
        open: visible,
        onClose: close,
      }}
      title={{ title: 'Request a Product or a General Role' }}
    >
      <DialogContent>
        <form id="title-order" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Grid container alignItems="center" spacing={2}>
              <FormLabel>Product or general role name</FormLabel>
              <Controller
                control={control}
                name="name"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.name}
                    helperText={errors.name?.message || 'Invalid value'}
                    placeholder="e.g. Jira, Hubspot, Web Developer"
                  />
                )}
                rules={{ required: 'This field is required' }}
              />
            </Grid>
          </FormGroup>

          <FormGroup>
            <Grid container alignItems="flex-start" spacing={2}>
              <FormLabel>Notes</FormLabel>
              <Controller
                control={control}
                name="notes"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.notes}
                    helperText={errors.notes?.message || 'Incorrect data'}
                    placeholder="Please provide a summary of the necessary expertise, highlighting specific details about the product (or its modules), the general role needed, and the scope of work."
                    multiline
                    rows={3}
                  />
                )}
                rules={{ required: 'This field is required' }}
              />
            </Grid>
          </FormGroup>

          <Controller
            name="requestorsName"
            control={control}
            render={({ field }) => <input {...field} type="hidden" />}
          />

          <FormGroup>
            <Grid container alignItems="flex-start" spacing={2}>
              <FormLabel>Your email</FormLabel>
              <Controller
                name="requestorsEmail"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.requestorsEmail}
                    helperText={errors.requestorsEmail?.message || 'Invalid value'}
                    placeholder="Your email"
                    disabled={!!user?.email}
                  />
                )}
                rules={{ required: 'This field is required' }}
              />
              <FormHelperText id="component-helper-text">
                We will email you when a product or general role becomes available on Workerbee.
              </FormHelperText>
            </Grid>
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isValid}
          color="secondary"
          form="title-order"
          fullWidth
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default RequestProductCustomerModal;
