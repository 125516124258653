import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';

import ROUTES from 'router/constants';

import { useAppSelector } from '../../../store/hooks';
import {
  getMeSelector,
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
  isLeadManagerSelector,
} from 'store/selectors/getUserSelector';

import { StyledRightSide, StyledListItem } from '../styled';

import AuthorizedMenu from './AuthorizedMenu';
import NotAuthorizedMenu from './NotAuthorizedMenu';
import { ERole } from 'models/consts';
import { Box } from '@mui/material';

interface IRightSideContainer {
  isAuth: boolean;
  isCreationPage?: boolean;
}

const RightSideContainer: FC<IRightSideContainer> = ({ isAuth, isCreationPage }) => {
  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const isLeadManager = useAppSelector(isLeadManagerSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);
  const me = useAppSelector(getMeSelector);

  return (
    <StyledRightSide isCreationPage={isCreationPage}>
      {isAuth && me.role.name !== ERole.ADMIN && (
        <>
          {isAgencyAdmin && (
            <StyledListItem
              className="header-menu-item"
              sx={{ display: { xs: 'none', lg: 'block' } }}
            >
              <ListItem
                data-test="products-n-experience-link"
                component={NavLink}
                to={ROUTES.PRODUCT_AND_EXPERIENCE}
              >
                Products & Experience
              </ListItem>
            </StyledListItem>
          )}
          {(isAgencyAdmin || isResourceManager) && (
            <StyledListItem
              className="header-menu-item"
              sx={{ display: { xs: 'none', lg: 'block' } }}
            >
              <ListItem data-test="team-roaster-link" component={NavLink} to={ROUTES.TEAM}>
                Team Roster
              </ListItem>
            </StyledListItem>
          )}
          {isLeadManager && (
            <>
              <StyledListItem className="header-menu-item">
                <ListItem
                  data-test="partner-rate-link"
                  component={NavLink}
                  to={ROUTES.PARTNER_RATES}
                >
                  Partner Rates
                </ListItem>
              </StyledListItem>
            </>
          )}
          {me.role.name !== ERole.INFLUENCER &&
            me.role.name !== ERole.SALES &&
            me.role.name !== ERole.TALENT && (
              <StyledListItem
                className="header-menu-item"
                sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}
              >
                <ListItem data-test="my-orders-link" component={NavLink} to={ROUTES.ORDER_LIST}>
                  {isLeadManager ? 'My Preorders' : 'My Orders'}
                </ListItem>
              </StyledListItem>
            )}

          {(me.role.name === ERole.SALES || me.role.name === ERole.TALENT) && (
            <Box display={'flex'}>
              <StyledListItem
                className="header-menu-item"
                sx={{ mr: 1, display: { xs: 'none', sm: 'none', lg: 'block' } }}
              >
                <ListItem
                  data-test="sales-home-link"
                  component={NavLink}
                  to={ROUTES.TALENT_SEARCH_LANDING}
                >
                  Directory
                </ListItem>
              </StyledListItem>
              <StyledListItem
                className="header-menu-item"
                sx={{ mr: 1, display: { xs: 'none', sm: 'none', lg: 'block' } }}
              >
                <ListItem data-test="sales-deals-link" component={NavLink} to={ROUTES.DEALS_LIST}>
                  Deals
                </ListItem>
              </StyledListItem>
            </Box>
          )}

          {!isLeadManager && ![ERole.INFLUENCER].includes(me.role.name) && (
            <StyledListItem
              className="header-menu-item"
              sx={{ mr: 1, display: { xs: 'none', sm: 'none', lg: 'block' } }}
            >
              <ListItem data-test="messages-link" component={NavLink} to={ROUTES.CHAT}>
                Messages
              </ListItem>
            </StyledListItem>
          )}

          {me.role.name === ERole.INFLUENCER && (
            <StyledListItem sx={{ mr: 1, display: { xs: 'none', sm: 'none', lg: 'block' } }}>
              <ListItem data-test="influencer-home-link" component={NavLink} to={ROUTES.HOME}>
                Home
              </ListItem>
            </StyledListItem>
          )}
        </>
      )}
      {isAuth && <AuthorizedMenu />}
      {!isAuth && isCreationPage && <NotAuthorizedMenu />}
    </StyledRightSide>
  );
};

export default RightSideContainer;
