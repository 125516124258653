import { styled, Box } from '@mui/material';

import { Cell } from '../styled';

export const TimelineCellStyled = styled(Cell)(({ theme }) =>
  theme.unstable_sx({
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const TimelineDates = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const DateSpan = styled('span')(({ theme }) =>
  theme.unstable_sx({
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0%',
  }),
);
