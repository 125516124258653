import React, { forwardRef, FunctionComponent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';

import { onTypeaheadItemChange } from 'utils/orderCreationHelpers';
import { filterIsDeprecated } from 'helpers/decorators';
import { useAppSelector } from 'store/hooks';

import {
  categoryProductsSelector,
  modulesSelector,
  workTypesSelector,
} from 'store/selectors/getCommonSelector';

import CustomAutocomplete from '../CustomAutocomplete';
import { StyledMasonry } from '../CustomAutocomplete/styled';

import useSafeAreaView from 'components/SafeAreaView';
import ProductAutocomplete from '../CustomAutocomplete/ProductAutocomplete';
import RequestProductModal from 'components/Modal/RequestProductModal';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { EProductFilters, ERole } from 'models/consts';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';
import RequestProductCustomerModal from 'components/Modal/RequestProductModal/RequestCustomer';
import { IProduct } from 'store/types/categoryProducts';
import { matchSorter } from 'match-sorter';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';

interface IProductsModulesWorkProps {
  disabledProductEdit?: boolean;
  isModal?: boolean;
  disableAllFields?: boolean;
}

const ProductsModulesWork: FunctionComponent<IProductsModulesWorkProps> = ({
  disabledProductEdit,
  isModal,
  disableAllFields,
}) => {
  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);
  const role = useAppSelector(getMeRoleSelector) as ERole;

  const { safeAreaPadding } = useSafeAreaView();

  const {
    control,
    formState: { errors },
    resetField,
    setValue,
    watch,
  } = useFormContext();

  const currentProduct = watch('product');
  const primaryModule = watch('primaryModule');
  const primaryWorkType = watch('primaryWorkType');

  const modulesListByProduct = filterIsDeprecated(modulesList, currentProduct?.id);
  const worksListByProduct = filterIsDeprecated(worksList, currentProduct?.id);

  const [isRequestModalVisible, setRequestModalVisible] = useState(false);
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [isProductReqSuccess, setIsProductReqSuccess] = useState<boolean>(false);

  const productFilters = (options: any, param: String, productType?: EProductFilters) => {
    const inputLower = (param || '').toLowerCase();

    const optionList =
      productType === EProductFilters.ALL
        ? options
        : options.filter((item: any) =>
            productType === EProductFilters.ROLE ? item?.isGeneralRoles : !item?.isGeneralRoles,
          );

    if (inputLower) {
      const filteredOptions = matchSorter(optionList as IProduct[], inputLower, {
        keys: ['name', 'categoryName', 'company.name', 'aliases'],
      });
      return (filteredOptions || []) as any[];
    }
    return optionList;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid xs={12} md={6} lg={isModal ? 6 : 4}>
          <InputLabel>Product</InputLabel>
          <Controller
            control={control}
            name="product"
            render={({ field }) => (
              <ProductAutocomplete
                autocompleteProps={{
                  groupBy: option => option.categoryName,
                  ListboxComponent: forwardRef((props: any, ref: any) => (
                    <StyledMasonry ref={ref} component="ul" spacing={2} {...props} columns={1} />
                  )),
                  multiple: undefined,
                }}
                inputProps={{
                  readOnly: true,
                  error: !!errors.product,
                  helperText: (errors.product as any)?.message || 'Incorrect data',
                }}
                field={field}
                handleChange={(event, fieldName) => {
                  onTypeaheadItemChange(event, fieldName, setValue);
                  resetField('modules', { defaultValue: [] });
                  resetField('works', { defaultValue: [] });
                }}
                options={categoriesWithProductsList || []}
                placeholder="Select a Product"
                disabled={disabledProductEdit || disableAllFields}
                mobilesx={{ marginTop: safeAreaPadding.top }}
                onRequestProduct={() => {
                  setRequestModalVisible(true);
                }}
                successProductRequest={isProductReqSuccess}
                setIsProductReqSuccess={setIsProductReqSuccess}
                customFilter={(options, inputValue, type) =>
                  productFilters(options, inputValue, type)
                }
              />
            )}
            rules={{ required: 'This field is required' }}
          />
        </Grid>
        <Grid xs={12} md={6} lg={isModal ? 6 : 4}>
          <InputLabel>Modules</InputLabel>
          <Controller
            control={control}
            name="modules"
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: true,
                }}
                inputProps={{
                  readOnly: true,
                  error: !!errors.modules,
                  helperText: (errors.modules as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                field={field}
                handleChange={(event, fieldName) =>
                  onTypeaheadItemChange(event, fieldName, setValue)
                }
                isMainTag
                options={modulesListByProduct || []}
                placeholder="Select a Module"
                primaryItem={primaryModule}
                onPrimaryItemChange={id => setValue('primaryModule', id)}
                disabled={disableAllFields}
              />
            )}
            rules={{ required: 'This field is required' }}
          />
        </Grid>
        <Grid xs={12} md={6} lg={isModal ? 6 : 4}>
          <InputLabel>Type of Work</InputLabel>
          <Controller
            control={control}
            name="works"
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: true,
                }}
                inputProps={{
                  readOnly: true,
                  error: !!errors.works,
                  helperText: (errors.works as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                field={field}
                isMainTag
                options={worksListByProduct || []}
                handleChange={(event, fieldName) =>
                  onTypeaheadItemChange(event, fieldName, setValue)
                }
                placeholder="Select a Type of Work"
                primaryItem={primaryWorkType}
                onPrimaryItemChange={id => setValue('primaryWorkType', id)}
                disabled={disableAllFields}
              />
            )}
            rules={{ required: 'This field is required' }}
          />
        </Grid>
      </Grid>
      {role === ERole.CONSULTANT ? (
        <RequestProductModal
          visible={isRequestModalVisible}
          close={() => setRequestModalVisible(false)}
          afterSubmit={() => {
            setConfirmationModalVisible(true);
            setIsProductReqSuccess(true);
            ToastService.success(
              ToastMessage({
                title: 'Request submitted successfully!',
                body: 'Thanks for reaching out. Our support team has received your request.',
              }),
            );
          }}
        />
      ) : (
        <RequestProductCustomerModal
          visible={isRequestModalVisible}
          close={() => setRequestModalVisible(false)}
          afterSubmit={() => {
            setConfirmationModalVisible(true);
            setIsProductReqSuccess(true);
            ToastService.success(
              ToastMessage({
                title: 'Request submitted successfully!',
                body: 'Thanks for reaching out. Our support team has received your request.',
              }),
            );
          }}
        />
      )}

      <ConfirmationModal
        visible={isConfirmationModalVisible}
        close={() => setConfirmationModalVisible(false)}
        title="Your product request has been sent to our team."
        subtitle="A copy of your request has been sent to your email."
        primaryButtonText="Request Another Product"
        onPrimaryButtonClick={() => {
          setConfirmationModalVisible(false);
          setRequestModalVisible(true);
        }}
      />
    </>
  );
};

export default ProductsModulesWork;
