import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PaymentMoneyDollarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M10.88 22.4793V19.9843C9.81959 19.7555 8.90992 19.2773 8.151 18.5496C7.39209 17.8218 6.85668 16.9174 6.5448 15.8362L8.85274 14.9005C9.01908 15.6491 9.44013 16.3196 10.1159 16.9122C10.7916 17.5048 11.4934 17.8011 12.2211 17.8011C13.032 17.8011 13.6973 17.5931 14.2172 17.1773C14.737 16.7614 14.9969 16.2208 14.9969 15.5555C14.9969 14.9317 14.763 14.4119 14.2951 13.9961C13.8273 13.5802 13.0008 13.1644 11.8156 12.7485C10.1938 12.1663 9.00869 11.527 8.26016 10.8304C7.51164 10.1339 7.13738 9.23463 7.13738 8.13264C7.13738 7.09303 7.47526 6.19896 8.151 5.45043C8.82675 4.70191 9.73642 4.21329 10.88 3.98458V1.52069H13.1256V3.98458C14.6226 4.23409 15.6258 4.80587 16.1352 5.69994C16.6447 6.59401 16.9098 7.06184 16.9306 7.10342L14.7474 8.00789C14.581 7.46729 14.2483 7.02025 13.7493 6.66678C13.2503 6.31331 12.6993 6.13658 12.0963 6.13658C11.3686 6.13658 10.7656 6.32371 10.2874 6.69797C9.80919 7.07223 9.57008 7.52966 9.57008 8.07026C9.57008 8.61086 9.82998 9.07349 10.3498 9.45815C10.8696 9.84281 11.8364 10.2742 13.2503 10.7525C14.7058 11.2307 15.7662 11.8545 16.4315 12.6238C17.0969 13.3931 17.4296 14.3703 17.4296 15.5555C17.4296 16.7407 17.0449 17.7387 16.2756 18.5496C15.5063 19.3605 14.4563 19.8699 13.1256 20.0778V22.4793H10.88Z"
        fill="#5D5D5E"
      />
    </SvgIcon>
  );
};
