import React from 'react';
import uniqueId from 'lodash/fp/uniqueId';

import DownloadIcon from 'styles/icons/DownloadIcon';

import { StyledImageBox, StyleLink, StyledPreviewImg, StyledPreviewPdf } from './styled';

interface ICertificatePreviewProps {
  src: string;
  name: string;
  isPdf?: boolean;
  isEditable?: boolean;
}

const CertificatePreview: React.FunctionComponent<ICertificatePreviewProps> = ({
  src,
  isPdf,
  name,
  isEditable,
}) => {
  const unique = uniqueId('avatar_');

  return (
    <StyledImageBox>
      {(isPdf && <StyledPreviewPdf unique={unique} src={src} title={name} alt={name} />) || (
        <StyledPreviewImg unique={unique} src={src} title={name} alt={name} />
      )}
      {!isEditable && (
        <StyleLink download={name} href={src} target="_blank">
          <DownloadIcon />
        </StyleLink>
      )}
    </StyledImageBox>
  );
};

export default CertificatePreview;
